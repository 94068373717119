import React, { useEffect, useRef } from 'react'

import { Flex, FlexItem, Icon, IconButton, TextField } from 'stardust'
import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

interface props {
  onChange: (value: string) => void
  commentValue?: string
  placeholder?: string
  disableSend?: boolean
  disableInput?: boolean
  error?: boolean
  autoFocus?: boolean
  loading?: boolean
}

const InlineV3Comment = ({
  onChange,
  commentValue,
  placeholder,
  disableSend,
  disableInput,
  error,
  autoFocus,
  loading,
}: props) => {
  const textAreaRef = useRef<null | HTMLInputElement | HTMLTextAreaElement>(null)

  const updateInputHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto' // Reset the height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px` // Set the height to scrollHeight
    }
  }

  useEffect(() => {
    updateInputHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentValue, loading, disableSend])

  return (
    <Flex
      borderRadius="16px"
      border={`1px solid ${colors.cosmicShade20}`}
      alignItems="flex-end"
      bg={disableInput ? colors.cosmicShade4 : colors.cosmicShade0}>
      <StyledTextField
        autoComplete="off"
        data-test="comment-field"
        textarea
        resizeable={false}
        invalid={error}
        rows={1}
        name="comment"
        value={loading ? '' : commentValue}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        ref={textAreaRef}
        autoFocus={autoFocus}
        disabled={disableInput}
      />
      <FlexItem pl={2} py="11px" pr="15px" height="46px">
        <StyledSendButton
          disabled={disableSend || disableInput}
          type="submit"
          data-test="comment-submit"
          icon={
            <Icon
              disabled={disableSend || disableInput}
              width={21}
              name="send"
              color={disableSend || disableInput ? colors.cosmicShade11 : undefined}
            />
          }
        />
      </FlexItem>
    </Flex>
  )
}

export default InlineV3Comment

const StyledTextField = styled(TextField)`
  width: 100%;

  .mdc-text-field__input {
    padding: 0px;
    max-height: 120px;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border: none;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border: none;
  }

  .mdc-text-field--textarea {
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 15px;
  }
`

const StyledSendButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 0 1px 0 2px;
  color: ${colors.cosmicShade9};
  &:before,
  &:after {
    background-color: transparent;
  }
`
