import { useRef, useEffect } from 'react'

export const useChatAutoScroll = (messageCount: number) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const lastMessageRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = containerRef.current
    const lastMessageElement = lastMessageRef.current

    if (!container || !lastMessageElement) return

    // Calculate if last message fits in viewport
    const containerHeight = container.clientHeight
    const containerScrollHeight = container.scrollHeight
    const lastMessageRect = lastMessageElement.getBoundingClientRect()

    if (lastMessageRect.height > containerHeight) {
      // Scroll to top of last message
      const spacing = 20
      const targetPosition = containerScrollHeight - lastMessageRect.height - spacing
      container.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      })
    } else {
      // Regular auto-scroll to bottom
      container.scrollTo({
        top: containerScrollHeight,
        behavior: 'smooth',
      })
    }
  }, [messageCount])

  return { containerRef, lastMessageRef }
}
