/*
Default styling to use for text within Slate editors
*/

export default `
  h1 {
    font-size: 2em;
    line-height: normal;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  h2 {
    font-size: 1.5em;
    line-height: normal;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }

  h3 {
    font-size: 1.17em;
    line-height: normal;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  h4 {
    font-size: 1em;
    line-height: normal;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
  }

  h5 {
    font-size: 0.83em;
    line-height: normal;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
  }

  h6 {
    font-size: 0.67em;
    line-height: normal;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
  }

  p {
    font-size: 1em;
    line-height: normal;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  code {
    background-color: #eee;
    font-family: monospace;
    line-height: normal;
    padding: 3px;
  }

  blockquote {
    border-left: 2px solid #ddd;
    color: #aaa;
    font-style: italic;
    line-height: normal;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
  }

  div {
    line-height: normal;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 32px;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 32px;
  }

  li {
    line-height: 24px;
  }

  li > span {
    display: block;
  }
`
