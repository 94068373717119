import React, { ReactNode } from 'react'

import { Box, Flex, Heading, Image } from 'stardust'

import empty from '~/assets/images/empty.svg'

interface Props {
  message: string
  redirect?(): ReactNode
}

const Error404 = ({ redirect, message }: Props) => {
  return (
    <Flex alignItems="center" height="100%" justifyContent="center" width={1}>
      <Flex alignItems="center" flexDirection={['column', 'row']} my={5}>
        <Image src={empty} m={3} height={['100px', '200px']} width={['100px', '200px']} alt="" />
        <Box>
          <Heading.h4 lineHeight={1} mb={2}>
            {message}
          </Heading.h4>
          {redirect && redirect()}
        </Box>
      </Flex>
    </Flex>
  )
}

Error404.displayname = 'Error404'

export default Error404
