import React, { useEffect, useRef, useState } from 'react'

import { Instance, Placement, createPopper } from '@popperjs/core'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import useClickedOutside from '~/hooks/useClickedOutside'
import { LAYERS } from '~/theme'

import Box from './Box'
import Card from './Card'
import Relative from './Relative'

const DropdownContainer = styled(Card)<{ show: boolean }>`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  position: absolute;
`

interface ChildProps {
  handleClose(event?: any): void
}

interface Props {
  placement?: Placement
  portal?: boolean
  render({ handleClose }: ChildProps): void
  renderButton({ handleClose }: ChildProps): void
  zIndex?: number
  show?: boolean
}

const Dropdown = ({ placement, portal = false, renderButton, render, zIndex }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const [show, setShow] = useState(false)

  const handleClose = (event?: any) => {
    event?.preventDefault()
    event?.stopPropagation()

    setShow((show) => !show)
  }

  useClickedOutside(() => setShow(false), dropdownRef, containerRef)

  useEffect(() => {
    let popper: Instance | undefined
    if (containerRef.current && dropdownRef.current) {
      popper = createPopper(containerRef.current, dropdownRef.current, {
        placement: placement || 'bottom-end',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom-end', 'bottom-start', 'left-start', 'right-start'],
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      })
    } else !show && popper?.destroy()
    return () => popper?.destroy()
  }, [placement, show])

  const dropdownContent = (
    <DropdownContainer
      ref={dropdownRef}
      bg="surfacePrimary"
      border="1px solid"
      borderColor="surfacePrimaryBorder"
      boxShadow="12dp"
      overflow="auto"
      show={show}
      zIndex={zIndex || LAYERS.Dropdown}>
      {render({ handleClose })}
    </DropdownContainer>
  )

  return (
    <Relative maxWidth="100%">
      <Box ref={containerRef} cursor="pointer">
        {renderButton({ handleClose })}
      </Box>

      {portal ? ReactDOM.createPortal(dropdownContent, document.getElementById('root')!) : dropdownContent}
    </Relative>
  )
}

Dropdown.displayName = 'Dropdown'

export default React.memo(Dropdown)
