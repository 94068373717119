import React, { useContext, useMemo } from 'react'

import { Text } from 'stardust'

import DropdownMenu from '~/components/DropdownMenu'
import { Id, ProfileItem } from '~/components/FilterDropdown'

import { LAYERS } from '~/theme'

import { LearningAnalysisContext } from '../Context'

interface Props {
  value: Nullable<ProfileItem<Id>>
  onChange(value: Nullable<ProfileItem<Id>>): void
}

const CategoryMenu = ({ value, onChange }: Props) => {
  const { categories } = useContext(LearningAnalysisContext)

  const primaryCategoryOptions = useMemo(() => {
    const options = categories.primary.map((category) => {
      const categoryName = category.shortName || category.name
      return {
        label: categoryName,
        onClick: () =>
          onChange({
            id: category?.id,
            name: categoryName,
            type: 'primaryCategory',
          }),
      }
    })

    return [{ label: 'All Areas', onClick: () => onChange(null) }, ...options]
  }, [categories, onChange])

  return (
    <DropdownMenu
      label={<Text.span fontSize={0}>{value?.name || 'All Areas'}</Text.span>}
      items={primaryCategoryOptions}
      placement={'right'}
      portal
      zIndex={LAYERS.SectionHeader}
      callFrom="learning-category"
    />
  )
}

CategoryMenu.displayName = 'CategoryMenu'

export default React.memo(CategoryMenu)
