import React from 'react'

import styled from 'styled-components'
import {
  BottomProps,
  DisplayProps,
  LeftProps,
  RightProps,
  TopProps,
  VerticalAlignProps,
  ZIndexProps,
  bottom,
  display,
  left,
  right,
  top,
  zIndex,
} from 'styled-system'

import Box from './Box'

export type RelativeProps = BottomProps &
  DisplayProps &
  LeftProps &
  RightProps &
  TopProps &
  ZIndexProps &
  VerticalAlignProps

const Relative = styled(Box)<RelativeProps>`
  position: relative;

  ${display};
  ${top};
  ${bottom};
  ${left};
  ${right};
  ${zIndex};
`

Relative.displayName = 'Relative'

export default React.memo(Relative)
