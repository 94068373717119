import React from 'react'

import { Box, Icon } from 'stardust'

import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background-color: ${colors.cosmicShade12};
  max-width: none;
  height: 100%;
  > div:first-child {
    height: auto;
  }
`
const NoDocumentsMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 42px;
  width: 240px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
`
const CenteredText = styled.span`
  text-align: center;
  margin-top: 5px;
`

type PostLinksNoSearchResultsProps = {
  displayText: string
}

const PostLinksNoSearchResults = ({ displayText }: PostLinksNoSearchResultsProps) => {
  return (
    <StyledBox>
      <Icon name="noSearchResults" width={250} />
      <NoDocumentsMessage>
        {displayText} <CenteredText>found.</CenteredText>
      </NoDocumentsMessage>
    </StyledBox>
  )
}

PostLinksNoSearchResults.displayName = 'PostLinksNoSearchResults'
export default React.memo(PostLinksNoSearchResults)
