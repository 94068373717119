import { useEffect, useRef, useState } from 'react'

/**
 * useHover
 *
 * Detect whether the mouse is hovering an element.
 *
 * @example
 * function App() {
 * const [hoverRef, isHovered] = useHover()
 * return <div ref={hoverRef}>{isHovered ? '😁' : '☹️'}</div>
 * }
 * */
function useHover() {
  const [value, setValue] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseLeave = () => setValue(false)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const node = ref.current

    if (node) {
      node.addEventListener('mouseover', handleMouseOver)
      node.addEventListener('mouseleave', handleMouseLeave)

      return () => {
        node.removeEventListener('mouseover', handleMouseOver)
        node.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [ref.current]) // Recall only if ref changes
  /* eslint-enable react-hooks/exhaustive-deps */

  return [ref, value] as const
}

export default useHover
