import React from 'react'

import styled from 'styled-components'

import Icon from './Icon'
import Text from './Text'

interface Props {
  src: string
  url?: string
}

const S = {
  Anchor: styled.a`
    align-items: center;
    border-radius: 40px;
    border: 2px solid ${(props) => props.theme.colors.fileEmbedBtnColor};
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 8px;
    padding: 8px;
    text-decoration: none;
  `,
  Text: styled(Text)`
    color: ${(props) => props.theme.colors.fileEmbedTxtColor};
  `,
}

const File = ({ src, url }: Props) => {
  return (
    <S.Anchor href={url} target={url ? '_blank' : undefined}>
      <Icon name="file" color="fileEmbedTxtColor" mr={2} width={20} />
      <S.Text textAlign="center">{src}</S.Text>
    </S.Anchor>
  )
}

File.displayName = 'File'

export default React.memo(File)
