export const HEADER_HEIGHT = '64px'

export const ROOT_SIDEBAR_WIDTH = '232px'

export const TOOLBAR_HEIGHT = '48px'
export const DOCUMENT_HEADER_HEIGHT = '112px'

// Accounts for extra pixel added by borders
export const DOCUMENT_CONTENT_HEIGHT = `calc(100vh - ${DOCUMENT_HEADER_HEIGHT} - 1px)`
export const ARTICLE_EDITOR_CONTENT_HEIGHT = `calc(100vh - ${DOCUMENT_HEADER_HEIGHT} - ${TOOLBAR_HEIGHT} - 2px)`

export const TAGGING_SIDEBAR_WIDTH = '100px'

export const LAYERS = {
  RaisedElement: 20,
  SectionHeader: 30,
  PageHeader: 40,

  Header: 50,
  Sidebar: 60,

  Fullscreen: 1000,
  Popover: 1010,
  Modal: 1020,
  Dropdown: 1030,
  Tooltip: 1040,
}

export const BREAKPOINTS = {
  xs: 480,
  sm: 768,
  md: 1024,
  lg: 1440,
}
