import React, { useEffect, useRef, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Box, Flex, Text, Icon, ConfirmModal } from 'stardust'
import styled from 'styled-components'

import { ARCHIVE_OBSERVATION_REFLECTION } from '~/components/Editors/components/Menu/components/Reflections/mutations'
import { useScreenDetector } from '~/hooks/useScreenDetector'
import { BREAKPOINTS, LAYERS } from '~/theme'
import colors from '~/ui-components/tokens/colors'

import CommentsDateAndTime from '../../Comments/CommentsDateAndTime'

import ReflectionPopup from './ReflectionPopup'

interface Props {
  postId?: Nullable<number>
  reflections?: Playground.Reflection[]
  handleAddReply: (reflectionId: number, newReply: Playground.Comment) => void
  handleDeleteReflection: (reflectionId: number) => void
  handleDeleteReply: (reflectionId: number, replyId: number) => void
  isPostPreview?: boolean
}

const S = {
  ReflectionBox: styled(Box)`
    border-bottom: 1px solid ${colors.cosmicShade14};
    &:last-child {
      border-bottom: none;
    }
  `,
  Content: styled(Text)`
    margin-top: 8px;
    align-self: stretch;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
  `,
  Author: styled(Text)`
    @media (max-width: ${BREAKPOINTS.sm}px) {
      width: 100%;
      margin-bottom: 4px;
    }
  `,
}

const ReflectionList = ({
  postId,
  reflections,
  handleAddReply,
  handleDeleteReflection,
  handleDeleteReply,
  isPostPreview,
}: Props) => {
  const { isDesktop } = useScreenDetector()
  const [deleteObservationReflection] = useMutation(ARCHIVE_OBSERVATION_REFLECTION)
  const anchorRefs = useRef<HTMLDivElement[]>([])
  const [selectedReflection, setSelectedReflection] = useState<
    { reflection: Playground.Reflection; index: number } | undefined
  >(undefined)
  const [deleteReflectionId, setDeleteReflectionId] = useState<Nullable<number>>(null)

  const showDeleteModal = (id: number) => setDeleteReflectionId(id)

  const onConfirmReflectionDelete = (id: number) => {
    deleteObservationReflection({
      variables: {
        reflectionId: id,
      },
    }).then(() => {
      setDeleteReflectionId(null)

      if (selectedReflection?.reflection.id) {
        if (id === selectedReflection?.reflection.id) {
          setSelectedReflection(undefined)
          handleDeleteReflection(id)
        } else handleDeleteReply(selectedReflection?.reflection.id, id)
      }
    })
  }

  useEffect(() => {
    setSelectedReflection((prev) =>
      prev
        ? {
            ...prev,
            reflection:
              reflections?.find((reflection) => reflection.id === prev.reflection.id) || prev.reflection,
          }
        : undefined
    )
  }, [reflections])

  return (
    <>
      <Box pt={2}>
        {reflections?.map((reflection, index) => {
          const textBlocks = reflection.content.split(/[\n\r]+/g)
          const totalReplies = reflection?.replies.length
          return (
            <S.ReflectionBox
              key={reflection.id}
              p={3}
              ref={(element: HTMLDivElement) => (anchorRefs.current[index] = element)}
              onClick={() => setSelectedReflection({ reflection, index })}
              cursor="pointer"
              bg={
                selectedReflection?.reflection.id === reflection.id && !isDesktop
                  ? colors.cosmicShade19
                  : undefined
              }>
              <Box>
                <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
                  <S.Author fontSize="16px" fontWeight={700} lineHeight="24px">
                    {reflection.author.name}
                  </S.Author>
                  <Text
                    fontSize="16px"
                    fontWeight={500}
                    lineHeight="24px"
                    color={colors.cosmicShade11}
                    data-test="reflection-date">
                    <CommentsDateAndTime updatedAt={reflection.insertedAt} />
                  </Text>
                </Flex>
                {textBlocks.map((text, index) => (
                  <S.Content key={index}>{text}</S.Content>
                ))}
                {totalReplies ? (
                  <Flex mt={2} alignItems="center">
                    <Icon name="comments" fill="textPrimaryMedium" />
                    <Text.span fontSize="14px" ml={1} color="textPrimaryMedium">
                      {totalReplies}
                    </Text.span>
                  </Flex>
                ) : null}
              </Box>
            </S.ReflectionBox>
          )
        })}
      </Box>

      {selectedReflection ? (
        <ReflectionPopup
          postId={postId}
          reflection={selectedReflection.reflection}
          anchorRef={anchorRefs.current[selectedReflection.index]}
          closePopup={() => deleteReflectionId == null && setSelectedReflection(undefined)}
          showDeleteModal={isPostPreview ? undefined : showDeleteModal}
          handleAddReply={isPostPreview ? undefined : handleAddReply}
        />
      ) : null}

      <ConfirmModal
        headerText={
          deleteReflectionId === selectedReflection?.reflection.id ? 'Delete Reflection' : 'Delete Comment'
        }
        isOpen={deleteReflectionId != null}
        onCancel={() => setDeleteReflectionId(null)}
        onClose={() => setDeleteReflectionId(null)}
        onConfirm={() => {
          deleteReflectionId && onConfirmReflectionDelete(deleteReflectionId)
        }}
        cancelText="Cancel"
        confirmText="Delete"
        zIndex={LAYERS.Modal + Number(!isDesktop)}>
        <Text fontSize="16px" lineHeight="24px">
          {deleteReflectionId === selectedReflection?.reflection.id ? (
            <>
              Are you sure you want to delete this Reflection?
              <br />
              The entire conversation will be deleted.
            </>
          ) : (
            'Are you sure you want to delete this reply?'
          )}
        </Text>
      </ConfirmModal>
    </>
  )
}

ReflectionList.displayName = 'ReflectionList'

export default React.memo(ReflectionList)
