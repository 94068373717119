import React, { ReactNode, forwardRef } from 'react'

import { TextField, TextFieldHelperTextProps } from '@rmwc/textfield'
import styled from 'styled-components'
import { fontSize, height, lineHeight, width } from 'styled-system'

import Box, { BoxProps } from './Box'

const Container = styled(Box)`
  .mdc-text-field__input {
    ${fontSize};
    ${lineHeight};
    padding-top: 16px;
  }

  .mdc-text-field--textarea .mdc-text-field__input {
    margin: 0;
  }

  .mdc-text-field .mdc-text-field__input:focus::placeholder {
    color: ${(props) => props.theme.colors.textPrimaryMedium};
  }

  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: ${(props) => props.theme.colors.primary};
  }

  .mdc-text-field--focused .mdc-text-field__input:required ~ .mdc-notched-outline .mdc-floating-label:after {
    color: ${(props) => props.theme.colors.primary};
  }
`

// any type is used because typescript error
const StyledTextField = styled(TextField)<any>`
  ${width};
  ${height};
`

export interface TextFieldProps extends BoxProps {
  autoComplete?: string
  characterCount?: boolean
  disabled?: boolean
  fontSize?: number | number[]
  fullwidth?: boolean
  height?: number | string
  helpText?: ReactNode | TextFieldHelperTextProps
  icon?: ReactNode
  invalid?: boolean
  label?: string
  lineHeight?: number
  max?: string
  maxLength?: number
  min?: string
  name?: string
  outlined?: boolean
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  resizeable?: boolean
  rows?: number
  textarea?: boolean
  trailingIcon?: ReactNode
  type?: string
  value?: string
  validationText?: string
  width?: number | string
  borderWidth?: number | string
  onBlur?(): void
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void
  onClick?(): void
  autoFocus?: boolean
}

const ThemedTextField = forwardRef(
  (
    {
      autoComplete,
      characterCount,
      disabled,
      fullwidth,
      height,
      helpText,
      icon,
      invalid,
      label,
      max,
      maxLength,
      min,
      name,
      outlined = true,
      placeholder,
      readOnly,
      required,
      resizeable,
      rows,
      textarea,
      trailingIcon,
      type,
      value,
      validationText,
      width = '100%',
      onBlur,
      onChange,
      onClick,
      autoFocus,
      ...boxProps
    }: TextFieldProps,
    ref?: React.Ref<any>
  ) => {
    let helpObj = undefined
    if (helpText) helpObj = { persistent: true, validationMsg: false, children: helpText }
    if (invalid && validationText)
      helpObj = { persistent: false, validationMsg: true, children: validationText }

    return (
      <Container onClick={onClick} width={width} {...boxProps}>
        <StyledTextField
          autoComplete={autoComplete}
          characterCount={characterCount}
          disabled={disabled}
          fullwidth={fullwidth}
          rows={rows}
          helpText={helpObj}
          icon={icon}
          inputRef={ref ? ref : undefined}
          invalid={invalid}
          label={label}
          max={max}
          maxLength={maxLength}
          min={min}
          name={name}
          outlined={outlined}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
          resizeable={resizeable}
          textarea={textarea}
          trailingIcon={trailingIcon}
          type={type}
          value={value}
          width={width}
          onBlur={onBlur}
          onChange={onChange}
          autoFocus={autoFocus}
        />
      </Container>
    )
  }
)

ThemedTextField.displayName = 'TextField'

export default React.memo(ThemedTextField)
