import React, { useContext, useState } from 'react'

import distanceInWords from 'date-fns/distance_in_words'
import * as R from 'ramda'
import { Absolute, Avatar, Box, Flex, Icon, Relative, Text } from 'stardust'
import styled from 'styled-components'

import { UserContext } from '~/contexts/User'
import { BREAKPOINTS } from '~/theme'
import colors from '~/ui-components/tokens/colors'
import { initials } from '~/utils'

const CommentText = styled(Text)`
  word-break: break-word;

  p {
    margin: 0;
    margin-bottom: 1em;
  }

  p:last-child {
    margin-bottom: 0;
  }
`
const DeleteButton = styled(Box)`
  transition: opacity 0.5s;
`
const S = {
  Box: styled(Box)`
    @media print {
      div {
        page-break-inside: avoid;
      }
    }
  `,
  Author: styled(Text)`
    @media (max-width: ${BREAKPOINTS.md}px) {
      width: 100%;
    }
  `,
  Bullet: styled(Text)`
    @media (max-width: ${BREAKPOINTS.md}px) {
      display: none;
    }
  `,
  Icon: styled(Icon)<{ backgroundColor: string; hoverColor: string }>`
    border-radius: 50%;
    padding: 10px;
    background-color: ${(props) => props.backgroundColor};
    margin-left: auto;
    &:hover {
      background-color: ${(props) => props.hoverColor};
      transition: background-color 0.2s;
    }
  `,
}

const postedAt = R.ifElse(R.isNil, R.always(null), (d) => `${distanceInWords(d, new Date())} ago`)

interface Props {
  comment: Playground.Comment
  onDeleteComment?: (id: number) => void
  xxsmall?: boolean
  xsmall?: boolean
  small?: boolean
  large?: boolean
  onClose?: () => void
}

const Comment = ({ comment, onDeleteComment, large, small, xsmall, xxsmall, onClose, ...props }: Props) => {
  const [deleteButton, setDeleteButton] = useState(false)
  const author = comment?.author
  const background = author?.type === 'parent' ? 'hullOrange0' : 'nebulaBlue0'
  const border = author?.type === 'parent' ? 'hullOrange' : 'nebulaBlue'
  const context = author?.type === 'parent' ? 'Parent' : 'Educator'
  const textBlocks = comment?.content.split(/[\n\r]+/g)

  const user = useContext(UserContext)
  const userRef = user.state?.reference

  const isUserAuthor = userRef === author?.userRef

  return (
    <S.Box {...props}>
      <Flex alignItems="center" justifyContent="flex-start" mb={2}>
        <Avatar
          alt={R.prop('name', author)}
          context={context}
          large={large}
          small={small}
          xsmall={xsmall}
          xxsmall={xxsmall}
          src={R.prop('image', author)}
          text={initials(R.propOr('', 'name', author))}
        />
        <Flex alignItems="center" ml={2} flexWrap="wrap">
          <S.Author bold fontSize="14px" lineHeight="21px">
            {R.prop('name', author)}
          </S.Author>
          <S.Bullet bold fontSize={1} mx={1}>
            &bull;
          </S.Bullet>
          <Text color="textPrimaryMedium" fontSize={0}>
            {postedAt(comment.insertedAt)}
          </Text>
        </Flex>
        {onClose && (
          <S.Icon
            width={40}
            height={40}
            onClick={onClose}
            name="close"
            backgroundColor={colors.cosmicShade4}
            hoverColor={colors.cosmicShade6}
          />
        )}
      </Flex>

      <Relative
        bg={background}
        border="1px solid"
        borderColor={border}
        borderRadius="16px"
        onMouseEnter={() => setDeleteButton(true)}
        onMouseLeave={() => setDeleteButton(false)}
        onTouchStart={() => setDeleteButton(true)}>
        {isUserAuthor && onDeleteComment && (
          <Absolute top={-12} right={1}>
            <DeleteButton
              bg={colors.superGiantRed12}
              borderRadius={3}
              boxShadow="2dp"
              cursor="pointer"
              opacity={deleteButton ? 1 : 0}
              onClick={() => {
                onDeleteComment(comment.id)
                setDeleteButton(false)
              }}>
              <Icon name="close" fill="white" />
            </DeleteButton>
          </Absolute>
        )}
        <CommentText p={3}>
          {textBlocks.map((text, index) => (
            <Text key={index} lineHeight="24px" fontSize="16px" m={0}>
              {text}
            </Text>
          ))}
        </CommentText>
      </Relative>
    </S.Box>
  )
}

Comment.displayName = 'Comment'

export default Comment
