import React, { forwardRef, ReactNode } from 'react'

import styled from 'styled-components'

import { LAYERS } from '~/theme'

const Container = styled.div<{ background?: string }>`
  background: ${(props) => props.background || 'white'};
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: ${LAYERS.Fullscreen};
  overflow-y: auto;
`

interface Props {
  background?: string
  children: ReactNode
}

const FullScreen = forwardRef<HTMLDivElement, Props>(({ background, children }, ref) => (
  <Container background={background} ref={ref}>
    {children}
  </Container>
))

FullScreen.displayName = 'FullScreen'

export default FullScreen
