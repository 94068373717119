import React from 'react'

import { Flex, Heading, Image, Text } from 'stardust'

import emptyImage from '~/assets/images/world-ribbon.png'

interface Props {
  heading: string
  subheading?: string
}

const Empty = ({ heading, subheading }: Props) => (
  <Flex alignItems="center" justifyContent="center" mt={5}>
    <Flex alignItems="center" flexDirection="column" maxWidth={280}>
      <Heading.h5 bold lineHeight={1} mb={3} mt={0}>
        {heading}
      </Heading.h5>
      <Image src={emptyImage} mb={3} width={120} alt="" />
      {subheading && (
        <Flex color="black" justifyContent="center">
          <Text.span lineHeight={1.75} textAlign="center">
            {subheading}
          </Text.span>
        </Flex>
      )}
    </Flex>
  </Flex>
)

Empty.displayName = 'Empty'

export default React.memo(Empty)
