const palette = {
  pulsarPurple0: '#F1E6FD',
  pulsarPurple1: '#DAC2FB',
  pulsarPurple2: '#C199F9',
  pulsarPurple3: '#A76CF8',
  pulsarPurple4: '#9146F6',
  pulsarPurple5: '#7A11F2',
  pulsarPurple6: '#6E08EC',
  pulsarPurple7: '#5D00E3',
  pulsarPurple8: '#4A00DE',
  pulsarPurple9: '#2000D9',
  pulsarPurple10: '#4E3A7E',
  pulsarPurple11: '#e8e3f2',
  pulsarPurple12: '#A687F2',
  pulsarPurple13: '#F3F1FF',
  pulsarPurple14: '#3D2D62',
  pulsarPurple15: '#60479A',
  pulsarPurple16: '#8068BA',
  pulsarPurple17: '#B9ABD8',
  pulsarPurple18: '#A18FCC',
}

export default {
  ...palette,
  pulsarPurple: palette.pulsarPurple5,
}
