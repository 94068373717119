import React, { useMemo, useState } from 'react'

import {
  Absolute,
  Avatar,
  Box,
  Card,
  Dropdown,
  Flex,
  Icon,
  SecondaryButton,
  Text,
  Truncate,
  hoverMixin,
} from 'stardust'
import styled from 'styled-components'

import * as profiles from '~/components/Profiles/core'
import { getFkeyFromObject, sortObjectsBy } from '~/utils'

const MenuItem = styled(Box)`
  ${hoverMixin};
`

const OverflowFlex = styled(Flex)`
  overflow-y: auto;
`

const StyledSearchField = styled.input<{ theme: Theme }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.surfacePrimaryBorder};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  padding: ${({ theme }) => theme.space[3]};
  width: 100%;
`

type ProfileType = 'child' | 'educator' | 'room'
type UnmigratedProfileType = 'group' | 'primaryCategory'

export type Fkey = { fkey: string; type: ProfileType }
export type Id = { id: number; type: UnmigratedProfileType }
export type FkeyOrId = Fkey | Id

export type ProfileItem<T> = {
  image?: string
  name: string
} & T

export interface ProfileGroup<T extends FkeyOrId> {
  type: T['type']
  label: string
  items: ProfileItem<T>[]
}

interface FilterDropdownProps<T extends FkeyOrId> {
  buttonDefaultText?: string
  filters: ProfileGroup<T>[]
  portal?: boolean
  selection: Nullable<ProfileItem<T>>
  onSelect(selection: Nullable<ProfileItem<T>>): void
  zIndex?: number
}

interface SubmenuProps<T extends FkeyOrId> {
  filter: ProfileGroup<T>
  onSelect(selection: ProfileItem<T>): void
}

interface SubmenuItemProps<T extends FkeyOrId> {
  image?: string
  label: string
  onSelect(selection: ProfileItem<T>): void
}

function SubmenuItem<T extends FkeyOrId>({ image, label, onSelect }: SubmenuItemProps<T>) {
  return (
    <MenuItem
      cursor="pointer"
      px={2}
      width={1}
      onClick={() => onSelect({ image, name: label } as ProfileItem<T>)}>
      <Box borderBottom="1px solid" borderColor="surfacePrimaryBorder" width={1}>
        <Flex alignItems="center">
          <Avatar alt="" src={image} text={profiles.initials(label)} xxsmall />
          <Truncate ml={2} style={{ maxWidth: '100%' }} py={3}>
            <Text.span fontSize={1} title={label}>
              {label}
            </Text.span>
          </Truncate>
        </Flex>
      </Box>
    </MenuItem>
  )
}

function Submenu<T extends FkeyOrId>({ filter, onSelect }: SubmenuProps<T>) {
  const [searchText, setSearchText] = useState<string>('')
  const searchInputRef = React.useRef<HTMLInputElement>(null)

  if (null !== searchInputRef.current) {
    searchInputRef.current.focus()
  }

  const filteredItems = useMemo(() => {
    const lowerSearchText = searchText.toLowerCase()
    const filteredItems = filter?.items.filter(
      (item) => item.name.toLowerCase().indexOf(lowerSearchText) >= 0
    )
    const sortedFilteredItems = sortObjectsBy(filteredItems, 'name')
    return filter ? sortedFilteredItems : []
  }, [filter, searchText])

  return (
    <Flex flexDirection="column" flexGrow={1} width={0}>
      <Box position="relative" width={1}>
        <StyledSearchField
          autoFocus
          ref={searchInputRef}
          name="search"
          placeholder="Search"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
        />
        <Absolute right={16} top={14}>
          <Icon name="search" />
        </Absolute>
      </Box>
      <OverflowFlex flexDirection="column" flexGrow={1}>
        {filteredItems.length === 0 && (
          <Flex alignItems="center" height={200} justifyContent="center" width={1}>
            No matching items found
          </Flex>
        )}
        {filteredItems.map((item) => (
          <SubmenuItem
            key={getFkeyFromObject(item)}
            label={item.name}
            image={item.image}
            onSelect={() => onSelect({ ...item, type: filter.type })}
          />
        ))}
      </OverflowFlex>
    </Flex>
  )
}

const FilterDropdown = ({
  buttonDefaultText,
  filters,
  portal,
  selection,
  onSelect,
  zIndex,
}: FilterDropdownProps<FkeyOrId>) => {
  const [selectedMenuOption, setSelectedMenuOption] = useState<Nullable<ProfileGroup<FkeyOrId>>>(null)
  const buttonNoSelectionText = buttonDefaultText || 'All Children'
  const buttonText = selection ? selection.name : buttonNoSelectionText

  return (
    <Dropdown
      portal={portal}
      zIndex={zIndex}
      placement={'bottom-start'}
      renderButton={({ handleClose }) => (
        <Flex width="100%" alignItems="center" onClick={handleClose}>
          <Truncate style={{ maxWidth: '100%', title: buttonText }}>
            <Text.span title={buttonText} fontSize={0}>
              {buttonText}
            </Text.span>
          </Truncate>
          <Icon ml={1} name="chevronDown" />
        </Flex>
      )}
      render={({ handleClose }) => {
        const onClear = () => {
          setSelectedMenuOption(null)
          onSelect(null)
          handleClose()
        }

        const onItemSelect = (itemSelection: ProfileItem<FkeyOrId>) => {
          onSelect(itemSelection)
          handleClose()
        }

        const sortedFiltersByLabel = sortObjectsBy(filters, 'label')

        return (
          <Card>
            <Flex height="320px" width="500px">
              <Flex
                borderColor="surfacePrimaryBorder"
                borderRight="1px solid"
                flexDirection="column"
                justifyContent="space-between"
                width="180px">
                <Flex flexDirection="column">
                  {sortedFiltersByLabel.map((filter: ProfileGroup<FkeyOrId>) => (
                    <MenuItem
                      key={filter.label}
                      bg={selectedMenuOption?.type === filter.type ? 'hoverBackground' : 'transparent'}
                      borderBottom="1px solid"
                      borderColor="surfacePrimaryBorder"
                      cursor="pointer"
                      p={3}
                      onClick={() => setSelectedMenuOption(filter)}>
                      <Text.span fontSize={1}>{filter.label}</Text.span>
                    </MenuItem>
                  ))}
                </Flex>
                <SecondaryButton onClick={onClear}>Clear selection</SecondaryButton>
              </Flex>
              {selectedMenuOption ? <Submenu filter={selectedMenuOption} onSelect={onItemSelect} /> : null}
            </Flex>
          </Card>
        )
      }}
    />
  )
}

FilterDropdown.displayName = 'FilterDropdown'

export default React.memo(FilterDropdown)
