import React from 'react'

import * as R from 'ramda'

import styled from 'styled-components'
import { height, width } from 'styled-system'

import Box from './Box'
import Flex from './Flex'
import Heading from './Heading'

import colors from './tokens/colors'
import { large, small, xsmall, xxsmall } from './utils/avatarSize'

const AvatarBase = styled.div`
  ${height};
  ${width};
  ${large};
  ${small};
  ${xsmall};
  ${xxsmall};
  vertical-align: middle;
`

AvatarBase.defaultProps = {
  height: '80px',
  width: '80px',
}

AvatarBase.text = styled(AvatarBase)`
  background-color: ${(props) => props.backgroundColor};
`

const TextAvatar = (props) => {
  const defaultText = 'a'
  const { large, small, xsmall, xxsmall, text } = props
  const initials = text || defaultText
  const { bg, textColor } = getAuthorColors(initials[0])

  return (
    <AvatarBase.text large={large} small={small} xsmall={xsmall} xxsmall={xxsmall} backgroundColor={bg}>
      <Flex justifyContent="center" alignItems="center" height="100%">
        <Box>
          {R.cond([
            [
              R.prop('large'),
              R.always(
                <Heading.h2 bold={false} my={0} upper color={textColor}>
                  {initials}
                </Heading.h2>
              ),
            ],
            [
              R.prop('small'),
              R.always(
                <Heading.h5 my={0} upper color={textColor}>
                  {initials}
                </Heading.h5>
              ),
            ],
            [
              R.prop('xsmall'),
              R.always(
                <Heading.h6 my={0} upper color={textColor}>
                  {initials}
                </Heading.h6>
              ),
            ],
            [
              R.prop('xxsmall'),
              R.always(
                <Heading.h6 fontSize={0} my={0} upper color={textColor}>
                  {initials}
                </Heading.h6>
              ),
            ],
            [
              R.T,
              R.always(
                <Heading.h4 my={0} upper color={textColor}>
                  {initials}
                </Heading.h4>
              ),
            ],
          ])(props)}
        </Box>
      </Flex>
    </AvatarBase.text>
  )
}

TextAvatar.displayName = 'TextAvatar'

export default TextAvatar

const getAuthorColors = (initial) => {
  const charColors = {
    a: { bg: colors.nebulaBlue9, textColor: '#FFFFFF' },
    b: { bg: colors.nebulaBlue7, textColor: '#FFFFFF' },
    c: { bg: colors.nebulaBlue5, textColor: '#FFFFFF' },
    d: { bg: colors.nebulaBlue3, textColor: '#252525' },
    e: { bg: colors.nebulaBlue2, textColor: '#252525' },
    f: { bg: colors.superGiantRed10, textColor: '#FFFFFF' },
    g: { bg: colors.hullOrange7, textColor: '#FFFFFF' },
    h: { bg: colors.hullOrange4, textColor: '#252525' },
    i: { bg: colors.hullOrange3, textColor: '#252525' },
    j: { bg: colors.hullOrange1, textColor: '#252525' },
    k: { bg: colors.pulsarPurple14, textColor: '#FFFFFF' },
    l: { bg: colors.pulsarPurple15, textColor: '#FFFFFF' },
    m: { bg: colors.pulsarPurple16, textColor: '#FFFFFF' },
    n: { bg: colors.pulsarPurple12, textColor: '#FFFFFF' },
    o: { bg: colors.pulsarPurple17, textColor: '#252525' },
    p: { bg: colors.cosmicShade10, textColor: '#FFFFFF' },
    q: { bg: colors.cosmicShade11, textColor: '#FFFFFF' },
    r: { bg: colors.cosmicShade20, textColor: '#252525' },
    s: { bg: colors.cosmicShade16, textColor: '#252525' },
    t: { bg: colors.cosmicShade14, textColor: '#252525' },
    u: { bg: colors.nebulaBlue9, textColor: '#FFFFFF' },
    v: { bg: colors.nebulaBlue7, textColor: '#FFFFFF' },
    w: { bg: colors.nebulaBlue5, textColor: '#FFFFFF' },
    x: { bg: colors.nebulaBlue3, textColor: '#252525' },
    y: { bg: colors.nebulaBlue2, textColor: '#252525' },
    z: { bg: colors.superGiantRed10, textColor: '#FFFFFF' },
  }

  const lowerCaseInitial = initial.toLowerCase()

  if (!Object.prototype.hasOwnProperty.call(charColors, lowerCaseInitial)) {
    return charColors.a
  }
  return charColors[lowerCaseInitial]
}
