import React, { useCallback, useContext, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import RAscend from 'ramda/src/ascend'
import REquals from 'ramda/src/equals'
import RHead from 'ramda/src/head'
import RJoin from 'ramda/src/join'
import RMap from 'ramda/src/map'
import RPipe from 'ramda/src/pipe'
import RProp from 'ramda/src/prop'
import RProps from 'ramda/src/props'
import RSortWith from 'ramda/src/sortWith'
import RSplit from 'ramda/src/split'
import RTrim from 'ramda/src/trim'
import {
  Avatar,
  Box,
  Card,
  Flex,
  Heading,
  Icon,
  Interactive,
  SearchField,
  SecondaryButton,
  Text,
  hoverMixin,
} from 'stardust'
import styled from 'styled-components'

import { ServiceContext, useSelectedServiceFkey } from '~/contexts/Service'
import { filterBy } from '~/utils'

import { LearningTemplateTypes } from './enums'
import { COPY_PROVIDER_LAYOUT_TO_SERVICES } from './mutations'
import { GET_LAYOUTS } from './queries'

interface LayoutServiceSelectorProps {
  layoutId: Nullable<number>
  type: keyof typeof LearningTemplateTypes
  onCopySuccess: () => void
}

const filterByServiceNameOrFkey = filterBy(RPipe(RProps(['name', 'fkey']) as any, RJoin(' ')))
const initial = RPipe(RSplit('-'), RMap(RPipe(RTrim as any, RHead)), RJoin(''))
const initials = RPipe(RSplit(' '), RMap(initial), RJoin(''))
const InteractiveHover = styled(Interactive)<{ selected: boolean }>`
  ${hoverMixin};
  background-color: ${(props: any) => (props.selected ? props.theme.colors.primaryLight : 'initial')};
`

const LayoutServiceSelector = ({ layoutId, type, onCopySuccess }: LayoutServiceSelectorProps) => {
  const [copyLayouts] = useMutation(COPY_PROVIDER_LAYOUT_TO_SERVICES)
  const {
    state: { services: storedServices },
  } = useContext(ServiceContext)
  const serviceFkey = useSelectedServiceFkey()
  const [query, setQuery] = useState('')
  const [selectedServices, setSelectedServices] = useState<string[]>([])
  const [loadingMutation, setLoadingMutation] = useState<boolean>(false)

  const onSelect = (serviceFkey: string) => {
    selectedServices.find((fkey) => fkey == serviceFkey)
      ? setSelectedServices(selectedServices.filter((fkey) => fkey != serviceFkey))
      : setSelectedServices([...selectedServices, serviceFkey])
  }

  const onSelectAll = (services: Playground.SimpleService[]) => {
    const allServices: string[] = []
    services.forEach((service) => allServices.push(service.fkey!))
    REquals(selectedServices, allServices) ? setSelectedServices([]) : setSelectedServices(allServices)
  }

  const onQueryChange = (event: any) => {
    const value = event.target.value

    setQuery(value)
  }

  const onCopyLayouts = useCallback(async () => {
    setLoadingMutation(true)
    try {
      await copyLayouts({
        refetchQueries: [{ query: GET_LAYOUTS, variables: { serviceFkey, type } }],
        awaitRefetchQueries: true,
        variables: {
          templateId: layoutId,
          serviceFkeys: selectedServices,
        },
      })

      onCopySuccess()
    } catch (error) {
      setLoadingMutation(false)
      console.log('error', error)
    }
  }, [layoutId, serviceFkey, selectedServices, type, copyLayouts, onCopySuccess])

  const services = useMemo(() => {
    const filteredServices = filterByServiceNameOrFkey(query, storedServices)
    const sorter = RAscend(RProp('name'))

    return RSortWith([sorter])(filteredServices) as Playground.SimpleService[]
  }, [query, storedServices])

  return (
    <Card maxWidth="1080px" minWidth="500px">
      <Heading.h4 bold={false} lineHeight={1} mb={0} mt={0} p={3} pb={0}>
        Choose Services
      </Heading.h4>

      <Box borderBottom="1px solid" borderColor="surfacePrimaryBorder" p={3}>
        <SearchField label={'Filter services'} name="query" value={query} onChange={onQueryChange} />
      </Box>

      {services.map((service) => (
        <InteractiveHover
          key={service.fkey}
          selected={!!selectedServices.find((fkey) => fkey == service.fkey)}
          onClick={() => onSelect(service.fkey!)}>
          <Flex width={1} p={2} px={3} alignItems="center">
            <Avatar small src={null} text={initials(service.name)} />
            <Box pl={3}>
              <Text.span>{service.name}</Text.span>
            </Box>
          </Flex>
        </InteractiveHover>
      ))}

      <Flex
        px={3}
        py={2}
        borderTop="1px solid"
        borderColor="surfacePrimaryBorder"
        justifyContent="space-between"
        alignItems="center">
        <Box>
          <SecondaryButton onClick={() => onSelectAll(storedServices)}>Select All</SecondaryButton>
        </Box>
        <Box>
          <SecondaryButton
            onClick={onCopyLayouts}
            disabled={selectedServices.length < 1 || loadingMutation}
            icon={
              selectedServices.length < 1 || loadingMutation ? (
                <Icon name="copy" width={18} />
              ) : (
                <Icon name="copy" width={18} />
              )
            }>
            Copy Layouts
          </SecondaryButton>
        </Box>
      </Flex>
    </Card>
  )
}

LayoutServiceSelector.displayName = 'LayoutServiceSelector'

export default LayoutServiceSelector
