import React from 'react'

import { Flex, MainButton, SecondaryButton } from 'stardust'

interface ActionButtonsProps {
  onInsert: () => void
  onTryAgain: () => void
  disabled: boolean
}

const ActionButtons = ({ onInsert, onTryAgain, disabled }: ActionButtonsProps) => (
  <Flex justifyContent="flex-end" alignItems="center">
    <SecondaryButton
      outline
      height="48px"
      width="auto"
      px="24px"
      fontSize="16px"
      onClick={onTryAgain}
      ml={2}
      textTransform="inherit"
      data-test="try-again-button"
      disabled={disabled}>
      Try again
    </SecondaryButton>
    <MainButton
      height="48px"
      width="auto"
      px="24px"
      fontSize="16px"
      onClick={onInsert}
      ml={2}
      data-test="insert-button"
      disabled={disabled}>
      Insert
    </MainButton>
  </Flex>
)

ActionButtons.displayName = 'ActionButtons'
export default React.memo(ActionButtons)
