import React, { ReactNode } from 'react'

import { Box, Flex, FlexItem, Icon, MainButton, OutlineButton, Text } from 'stardust'
import styled from 'styled-components'

import { BREAKPOINTS, LAYERS } from '~/theme'

import { BoxProps } from './Box'
import colors from './tokens/colors'

type ChildrenFunc = ({ onClose }: { onClose: () => void }) => ReactNode

interface Props {
  children: ReactNode | ChildrenFunc
  isOpen?: boolean
  cancelText?: string
  confirmText?: string
  headerText?: string
  fullViewPort?: boolean
  onCancel?(): void
  onClose?(): void
  onConfirm?(): void
  zIndex?: number
}

interface HeaderProps {
  heading?: string
  onClose(): void
}

const Container = styled(
  ({
    isOpen,
    fullViewPort,
    zIndex,
    ...props
  }: {
    isOpen?: boolean
    fullViewPort?: boolean
    zIndex?: number
  } & BoxProps) => <Box {...props} />
)`
  align-items: self-start;
  background-color: rgba(0, 0, 0, 0.65);
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  height: ${(props) => (props.fullViewPort ? '100vh' : '100%')};
  justify-content: center;
  padding-top: 10vh;
  position: ${(props) => (props.fullViewPort ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  transition: top 0s linear 0.3s;
  width: ${(props) => (props.fullViewPort ? '100vw' : '100%')};
  z-index: ${(props) => props.zIndex || LAYERS.Modal};
  @media (max-width: ${BREAKPOINTS.md}px) {
    padding-top: 0;
  }
`

const Modal = styled(Box)`
  position: relative;
  box-shadow: ${(props) => props.theme.shadows['1dp']};
  max-width: calc(100vw - 32px);
  transition: opacity 0.3s;
  @media (max-width: ${BREAKPOINTS.md}px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Actions = styled(Box)`
  text-align: right;
`

const Header = ({ heading, onClose }: HeaderProps) => (
  <Flex flexDirection={'row'} justifyContent={'flex-end'} alignItems="center" mb={2}>
    {heading && (
      <FlexItem flex={1}>
        <Text fontSize="24px" fontWeight={600} lineHeight="28px">
          {heading}
        </Text>
      </FlexItem>
    )}
    <FlexItem>
      <Icon
        width={40}
        p="10px"
        name="close"
        fill="textPrimaryMedium"
        onClick={onClose}
        bg={colors.cosmicShade4}
        borderRadius="50%"
      />
    </FlexItem>
  </Flex>
)

const ConfirmModal = ({
  children,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  headerText,
  isOpen,
  fullViewPort = true,
  onCancel,
  onClose = () => {},
  onConfirm,
  zIndex,
}: Props) => (
  <Container isOpen={isOpen} onClick={onClose} fullViewPort={fullViewPort} zIndex={zIndex}>
    <Modal
      onClick={(event: any) => event.stopPropagation()}
      bg="white"
      p="24px"
      borderRadius={16}
      width="536px"
      opacity={isOpen ? 1 : 0}
      zIndex={106}>
      {onClose && <Header heading={headerText} onClose={() => onClose()} />}
      {children}
      <Actions mt={4}>
        <OutlineButton width="100%" onClick={onCancel} mb={2} height="48px">
          <Text fontSize="16px" lineHeight="24px" color="inherit">
            {cancelText}
          </Text>
        </OutlineButton>
        <MainButton width="100%" onClick={onConfirm} height="48px">
          <Text fontSize="16px" lineHeight="24px" color="inherit">
            {confirmText}
          </Text>
        </MainButton>
      </Actions>
    </Modal>
  </Container>
)

ConfirmModal.displayName = 'ConfirmModal'

export default React.memo(ConfirmModal)
