import React, { useCallback, useState } from 'react'

import { Box, Flex, SecondaryButton, Text } from 'stardust'

import { FieldProps } from '../../types'

import PostReflectionsModal from './PostReflectionsModal'

interface ReflectionsProps extends FieldProps {
  reflections?: Playground.Reflection[]
}

const Reflections = ({ reflections, onChange }: ReflectionsProps) => {
  const [isReflectionsModalOpen, setIsReflectionsModalOpen] = useState(false)

  const onCloseReflectionsModal = useCallback(() => setIsReflectionsModalOpen(false), [])

  return (
    <>
      <Box border="1px solid" borderRadius={1} bg="white" borderColor="cosmicShade6" m={2} py={2} pr={2}>
        <Flex alignItems="center" justifyContent="space-between">
          <Box mt={2}>
            <Text ml="12px" mr="12px" fontWeight={700} fontSize="14px" bold mb={2}>
              Reflections
            </Text>
          </Box>
          <SecondaryButton width="120px" onClick={() => setIsReflectionsModalOpen(true)}>
            View
          </SecondaryButton>
        </Flex>
      </Box>
      {isReflectionsModalOpen && (
        <PostReflectionsModal
          reflections={reflections}
          onCloseReflectionsModal={onCloseReflectionsModal}
          isReadOnly={true}
          onChange={onChange}
        />
      )}
    </>
  )
}

Reflections.displayName = 'Reflections'

export default React.memo(Reflections)
