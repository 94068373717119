import React, { forwardRef } from 'react'

import { Box, Flex, StencilLine, Text } from 'stardust'
import styled from 'styled-components'

import { useChatAutoScroll } from '~/pages/Observations/useChatAutoScroll'
import colors from '~/ui-components/tokens/colors'

interface ConversationProps {
  messages: Playground.AssistantConversationMessage[]
  isFetching: boolean
  maxHeight: string
  minHeight: string
}

const ChatFrame = styled(Flex)`
  padding: 6px 22px;
  border-radius: 16px;
  border: 2px solid ${colors.cosmicShade14};
  background-color: ${colors.cosmicShade19};
  overflow-y: auto;
`

const ChatBubble = styled(Text)<{ borderColor: string; textReveal?: boolean; alignSelf: string }>`
  width: 723px;
  max-width: 80%;
  border-radius: 16px;
  border: ${(props) => `2px solid ${props.borderColor}`};
  background-color: ${colors.cosmicShade0};
  padding: 10px;
  margin: 5px 0;
  white-space: pre-line;
  align-self: ${(props) => props.alignSelf};
  ${(props) =>
    props.textReveal &&
    ` 
      opacity: 0; /* Start invisible */
      mask-image: linear-gradient(to top, transparent 0%, black 100%); /* Initial mask */
      transform: translateY(8px); /* Start slightly below */
      animation: revealText 1.5s ease forwards; /* Apply the animation */

      @keyframes revealText {
          0% {
              opacity: 0; /* Invisible at the start */
              mask-image: linear-gradient(to top, transparent 0%, black 100%); /* Full mask */
              transform: translateY(8px); /* Start slightly below */
          }
          50% {
              mask-image: linear-gradient(to top, transparent 0%, black 0%); /* No mask */
          }
          100% {
              opacity: 1; /* Fully visible at the end */
              mask-image: linear-gradient(to top, transparent 0%, black 0%); /* No mask */
              transform: translateY(0px); /* End at the top */
          }
      }
  `}
`
const Conversation = ({ messages, isFetching, maxHeight, minHeight }: ConversationProps) => {
  const { containerRef, lastMessageRef } = useChatAutoScroll(messages.length)

  return (
    <ChatFrame ref={containerRef} flexDirection="column" maxHeight={maxHeight} minHeight={minHeight}>
      {messages.map((message, index) => (
        <Chat
          key={message.id}
          ref={index === messages.length - 1 ? lastMessageRef : undefined}
          message={message}
        />
      ))}
      {isFetching ? <LoadingAIBubble /> : null}
    </ChatFrame>
  )
}

Conversation.displayName = 'Conversation'
export default Conversation

const Chat = forwardRef<HTMLDivElement, { message: Playground.AssistantConversationMessage }>(
  ({ message }, ref) => {
    const isUser = message.sender === 'USER'
    const borderColor = isUser ? colors.solYellow7 : colors.pulsarPurple18
    const alignSelf = isUser ? 'flex-end' : 'flex-start'

    return (
      <ChatBubble
        fontSize="16px"
        lineHeight="24px"
        textReveal={message.withTextReveal}
        borderColor={borderColor}
        alignSelf={alignSelf}
        data-test={`chat_bubble_${message.sender}`}
        ref={ref}>
        <strong>{message.content.boldText}</strong>
        {message.content.regularText}
      </ChatBubble>
    )
  }
)

const LoadingAIBubble = () => (
  <Box alignSelf="flex-start" my={3} width={723} maxWidth="calc(100% - 40px)">
    <StencilLine width="100%" mb={2} withShimmer height="12px" />
    <StencilLine width="100%" mb={2} withShimmer height="12px" />
    <StencilLine width="50%" mb={2} withShimmer height="12px" />
    <StencilLine width="25%" withShimmer height="12px" />
  </Box>
)
