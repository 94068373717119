import React, { useRef } from 'react'

import FullScreen from '~/components/Layouts/FullScreen'

import TaggedDocumentsView from './TaggedDocumentsView'

interface Props {
  serviceFkey: string
}

const TaggedDocumentsWebview = ({ serviceFkey }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  return (
    <FullScreen background="inherit" ref={scrollRef}>
      <TaggedDocumentsView serviceFkey={serviceFkey} scrollRef={scrollRef} />
    </FullScreen>
  )
}

TaggedDocumentsWebview.displayName = 'TaggedDocumentsWebview'

export default TaggedDocumentsWebview
