import gql from 'graphql-tag'

import {
  observationContributors,
  observationInfo,
  observationLearningContentAssets,
  observationLearningOutcomes,
  observationLearningRecords,
  observationMedia,
  observationRooms,
  observationTaggedChildrenWithInfo,
} from '~/modules/observations'

import {
  postComments,
  postContributors,
  PostDocuments,
  postInfo,
  postLearningContentAssets,
  postLearningOutcomes,
  postLearningRecords,
  postMedia,
  postRooms,
  postTaggedChildrenWithInfo,
} from '~/modules/posts'

export const CREATE_OBSERVATION = gql`
  mutation createObservation(
    $childFkeys: [String]
    $observationDate: Date
    $description: String
    $futurePlanning: String
    $learningOutcomeIds: [Int]
    $learningRecords: [LearningRecordInput]
    $mediaIds: [Int]
    $planningDate: Date
    $roomFkeys: [String]!
    $serviceFkey: String!
    $observationTime: String
    $timezone: String
    $title: String!
    $learningContentAssetIds: [Int]
  ) {
    createObservation(
      observation: {
        childFkeys: $childFkeys
        date: $observationDate
        description: $description
        futurePlanning: $futurePlanning
        learningOutcomeIds: $learningOutcomeIds
        mediaIds: $mediaIds
        planningDate: $planningDate
        roomFkeys: $roomFkeys
        serviceFkey: $serviceFkey
        timezone: $timezone
        time: $observationTime
        title: $title
        learningRecords: $learningRecords
        learningContentAssetIds: $learningContentAssetIds
      }
    ) {
      id
      serviceFkey
      ...ObservationContributors
      ...ObservationInfo
      ...ObservationLearningOutcomes
      ...ObservationLearningRecords
      ...ObservationMedia
      ...ObservationRooms
      ...ObservationTaggedChildrenWithInfo
      ...ObservationLearningContentAssets
    }
  }
  ${observationContributors}
  ${observationInfo}
  ${observationLearningOutcomes}
  ${observationLearningRecords}
  ${observationMedia}
  ${observationRooms}
  ${observationTaggedChildrenWithInfo}
  ${observationLearningContentAssets}
`

export const CREATE_PUBLISHED_POST = gql`
  mutation createPost(
    $childFkeys: [String]
    $postDate: Date
    $description: String
    $richTextDescription: String
    $futurePlanning: String
    $learningOutcomeIds: [Int]
    $learningRecords: [LearningRecordInput]
    $mediaIds: [Int]
    $planningDate: Date
    $roomFkeys: [String]!
    $serviceFkey: String!
    $postTime: String
    $timezone: String
    $title: String!
    $learningContentAssetIds: [Int]
    $documentIds: [String]
  ) {
    createPost(
      post: {
        childFkeys: $childFkeys
        date: $postDate
        description: $description
        richTextDescription: $richTextDescription
        futurePlanning: $futurePlanning
        learningOutcomeIds: $learningOutcomeIds
        mediaIds: $mediaIds
        planningDate: $planningDate
        roomFkeys: $roomFkeys
        serviceFkey: $serviceFkey
        status: PUBLISHED
        timezone: $timezone
        time: $postTime
        title: $title
        learningRecords: $learningRecords
        learningContentAssetIds: $learningContentAssetIds
        documentIds: $documentIds
      }
    ) {
      id
      status
      serviceFkey
    }
  }
`

export const CREATE_DRAFT_POST = gql`
  mutation createPost(
    $childFkeys: [String]
    $postDate: Date
    $description: String
    $richTextDescription: String
    $futurePlanning: String
    $learningOutcomeIds: [Int]
    $learningRecords: [LearningRecordInput]
    $mediaIds: [Int]
    $planningDate: Date
    $roomFkeys: [String]
    $serviceFkey: String!
    $postTime: String
    $timezone: String
    $title: String!
    $learningContentAssetIds: [Int]
    $documentIds: [String]
  ) {
    createPost(
      post: {
        childFkeys: $childFkeys
        date: $postDate
        description: $description
        richTextDescription: $richTextDescription
        futurePlanning: $futurePlanning
        learningOutcomeIds: $learningOutcomeIds
        mediaIds: $mediaIds
        planningDate: $planningDate
        roomFkeys: $roomFkeys
        serviceFkey: $serviceFkey
        status: DRAFT
        timezone: $timezone
        time: $postTime
        title: $title
        learningRecords: $learningRecords
        learningContentAssetIds: $learningContentAssetIds
        documentIds: $documentIds
      }
    ) {
      id
      status
      serviceFkey
      updatedAt
    }
  }
`

export const CREATE_DRAFT_POST_COPY = gql`
  mutation createDraftPost($id: Int!, $serviceFkey: String!) {
    createDraftPost(draftPost: { observationId: $id, serviceFkey: $serviceFkey }) {
      id
      status
      updatedAt
    }
  }
`

export const ARCHIVE_POST = gql`
  mutation archivePost($id: ID) {
    archivePost(id: $id) {
      id
      updatedAt
      status
    }
  }
`

export const DELETE_POST = gql`
  mutation deletePost($id: ID) {
    deletePost(id: $id) {
      id
      status
      updatedAt
    }
  }
`
export const DELETE_UNSHARED_CHANGES_POST = gql`
  mutation deleteDraftPost($id: Int) {
    deleteDraftPost(draftPost: { observationId: $id }) {
      id
      status
      updatedAt
    }
  }
`

export const RESTORE_POST = gql`
  mutation restorePost($id: ID) {
    restorePost(id: $id) {
      id
      title
      updatedAt
      status
    }
  }
`

export const DUPLICATE_POST = gql`
  mutation createDuplicatePost($id: ID) {
    createDuplicatePost(id: $id) {
      id
      title
      status
      updatedAt
    }
  }
`

export const PUBLISH_POST = gql`
  mutation publishPost($id: ID, $allowComments: Boolean) {
    publishPost(id: $id, allowComments: $allowComments) {
      id
      title
      status
      updatedAt
    }
  }
`

export const PUBLISH_DRAFT_POST = gql`
  mutation publishDraftPost($observationId: Int!, $serviceFkey: String!) {
    publishDraftPost(draftPost: { observationId: $observationId, serviceFkey: $serviceFkey }) {
      id
      status
      updatedAt
    }
  }
`

export const UPDATE_POST_BOOKMARK = gql`
  mutation updateBookmarkPost($id: ID) {
    updateBookmarkPost(id: $id) {
      id
      bookmarked
      status
      updatedAt
    }
  }
`

export const UPDATE_OBSERVATION_BOOKMARK = gql`
  mutation updateObservationBookmark($id: Int!, $bookmarked: Boolean) {
    updateObservation(observation: { id: $id, bookmarked: $bookmarked }) {
      id
      bookmarked
    }
  }
`

export const UPDATE_OBSERVATION = gql`
  mutation updateObservation(
    $childFkeys: [String]
    $observationDate: Date
    $description: String
    $futurePlanning: String
    $id: Int!
    $learningOutcomeIds: [Int]
    $learningRecords: [LearningRecordInput]
    $mediaIds: [Int]
    $planningDate: Date
    $roomFkeys: [String]!
    $title: String
    $timezone: String
    $observationTime: String
    $learningContentAssetIds: [Int]
  ) {
    updateObservation(
      observation: {
        childFkeys: $childFkeys
        date: $observationDate
        description: $description
        futurePlanning: $futurePlanning
        id: $id
        learningOutcomeIds: $learningOutcomeIds
        learningRecords: $learningRecords
        mediaIds: $mediaIds
        planningDate: $planningDate
        roomFkeys: $roomFkeys
        title: $title
        timezone: $timezone
        time: $observationTime
        learningContentAssetIds: $learningContentAssetIds
      }
    ) {
      id
      ...ObservationContributors
      ...ObservationInfo
      ...ObservationLearningOutcomes
      ...ObservationLearningRecords
      ...ObservationMedia
      ...ObservationRooms
      ...ObservationTaggedChildrenWithInfo
      ...ObservationLearningContentAssets
    }
  }
  ${observationContributors}
  ${observationInfo}
  ${observationLearningOutcomes}
  ${observationLearningRecords}
  ${observationMedia}
  ${observationRooms}
  ${observationTaggedChildrenWithInfo}
  ${observationLearningContentAssets}
`
export const CREATE_POST_LOCK = gql`
  mutation createPostLock($id: Int!) {
    createPostLock(postId: $id) {
      lockedBy
      success
    }
  }
`

export const TAKEOVER_POST_LOCK = gql`
  mutation takeoverPostLock($id: Int!) {
    takeoverPostLock(postId: $id) {
      lockedBy
      success
    }
  }
`

export const RELEASE_POST_LOCK = gql`
  mutation releasePostLock($id: Int!) {
    releasePostLock(postId: $id) {
      unlockedBy
      success
    }
  }
`

export const UPDATE_POST_AS_PUBLISHED = gql`
  mutation updatePostAsPublished(
    $childFkeys: [String]
    $postDate: Date
    $description: String
    $richTextDescription: String
    $futurePlanning: String
    $id: Int!
    $learningOutcomeIds: [Int]
    $learningRecords: [LearningRecordInput]
    $mediaIds: [Int]
    $planningDate: Date
    $roomFkeys: [String]!
    $title: String
    $timezone: String
    $postTime: String
    $learningContentAssetIds: [Int]
    $documentIds: [String]
    $updatedAt: UtcDatetime
  ) {
    updatePost(
      post: {
        childFkeys: $childFkeys
        date: $postDate
        description: $description
        richTextDescription: $richTextDescription
        futurePlanning: $futurePlanning
        id: $id
        learningOutcomeIds: $learningOutcomeIds
        learningRecords: $learningRecords
        mediaIds: $mediaIds
        planningDate: $planningDate
        roomFkeys: $roomFkeys
        title: $title
        status: PUBLISHED
        timezone: $timezone
        time: $postTime
        learningContentAssetIds: $learningContentAssetIds
        documentIds: $documentIds
        updatedAt: $updatedAt
      }
    ) {
      id
      ...PostComments
      ...PostContributors
      ...PostDocuments
      ...PostInfo
      ...PostLearningOutcomes
      ...PostLearningRecords
      ...PostMedia
      ...PostRooms
      ...PostTaggedChildrenWithInfo
      ...PostLearningContentAssets
    }
  }
  ${postComments}
  ${postContributors}
  ${PostDocuments}
  ${postInfo}
  ${postLearningOutcomes}
  ${postLearningRecords}
  ${postMedia}
  ${postRooms}
  ${postTaggedChildrenWithInfo}
  ${postLearningContentAssets}
`
export const UPDATE_POST_AS_DRAFT = gql`
  mutation updatePostAsDraft(
    $childFkeys: [String]
    $postDate: Date
    $description: String
    $richTextDescription: String
    $futurePlanning: String
    $id: Int!
    $learningOutcomeIds: [Int]
    $learningRecords: [LearningRecordInput]
    $mediaIds: [Int]
    $planningDate: Date
    $roomFkeys: [String]
    $title: String
    $timezone: String
    $postTime: String
    $learningContentAssetIds: [Int]
    $documentIds: [String]
    $updatedAt: UtcDatetime
  ) {
    updatePost(
      post: {
        childFkeys: $childFkeys
        date: $postDate
        description: $description
        richTextDescription: $richTextDescription
        futurePlanning: $futurePlanning
        id: $id
        learningOutcomeIds: $learningOutcomeIds
        learningRecords: $learningRecords
        mediaIds: $mediaIds
        planningDate: $planningDate
        roomFkeys: $roomFkeys
        title: $title
        status: DRAFT
        timezone: $timezone
        time: $postTime
        learningContentAssetIds: $learningContentAssetIds
        documentIds: $documentIds
        updatedAt: $updatedAt
      }
    ) {
      id
      ...PostComments
      ...PostContributors
      ...PostDocuments
      ...PostInfo
      ...PostLearningOutcomes
      ...PostLearningRecords
      ...PostMedia
      ...PostRooms
      ...PostTaggedChildrenWithInfo
      ...PostLearningContentAssets
    }
  }
  ${postComments}
  ${postContributors}
  ${PostDocuments}
  ${postInfo}
  ${postLearningOutcomes}
  ${postLearningRecords}
  ${postMedia}
  ${postRooms}
  ${postTaggedChildrenWithInfo}
  ${postLearningContentAssets}
`

export const UPDATE_UNSHARED_CHANGES_POST = gql`
  mutation updatePostAsUnsharedChanges(
    $serviceFkey: String!
    $childFkeys: [String]
    $postDate: Date
    $description: String
    $richTextDescription: String
    $futurePlanning: String
    $id: Int!
    $learningOutcomeIds: [Int]
    $mediaIds: [Int]
    $planningDate: Date
    $roomFkeys: [String]!
    $title: String
    $timezone: String
    $postTime: String
    $documentIds: [String]
    $updatedAt: UtcDatetime
  ) {
    updateDraftPost(
      draftPost: {
        serviceFkey: $serviceFkey
        childFkeys: $childFkeys
        date: $postDate
        description: $description
        richTextDescription: $richTextDescription
        futurePlanning: $futurePlanning
        observationId: $id
        learningOutcomeIds: $learningOutcomeIds
        mediaIds: $mediaIds
        planningDate: $planningDate
        roomFkeys: $roomFkeys
        title: $title
        status: UNSHARED_CHANGES
        timezone: $timezone
        time: $postTime
        linkedDocumentIds: $documentIds
        updatedAt: $updatedAt
      }
    ) {
      id
      status
      title
    }
  }
`
export const UPDATE_POST_LIKE = gql`
  mutation updatePostLike($id: Int) {
    updatePostLike(post_id: $id) {
      entityId
      entityType
      type
      userDisplayName
      userType
    }
  }
`

export const UPDATE_VIEW_POST = gql`
  mutation viewPost($id: Int) {
    viewPost(post_id: $id) {
      entityId
      entityType
      type
      userDisplayName
      userType
    }
  }
`

export const UPLOAD_MEDIA = gql`
  mutation uploadMedia($file: Upload!, $serviceFkey: String!, $metadata: Json) {
    uploadMedia(media: $file, serviceFkey: $serviceFkey, type: "full", await: true, metadata: $metadata) {
      url
      id
      coverUrl
      thumbUrl
      metadata
    }
  }
`

export const AI_POST_CONTENT = gql`
  mutation aiPostContent(
    $action: Action!
    $content: String!
    $service: String!
    $tone: String
    $story: StoryInput
  ) {
    generateAiContent(action: $action, content: $content, service: $service, story: $story, tone: $tone) {
      body
    }
  }
`
