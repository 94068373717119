import React, { ReactNode } from 'react'

import Flex from './Flex'
import useSpace from './hooks/useSpace'
import { AlignX, SpaceDescriptor } from './theme/types'

import { filterReactChildren, isNotNull } from './utils'

const alignMapping = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
}

const invert = (space: string | string[]) => (Array.isArray(space) ? space.map((x) => `-${x}`) : `-${space}`)

export interface StackProps {
  align: AlignX
  children: ReactNode
  space: SpaceDescriptor | SpaceDescriptor[]
  invertSpace?: boolean
}

export const Stack = ({ align, children, invertSpace, space }: StackProps) => {
  const spaceValues = useSpace(space)
  const childSpaceValues = invertSpace && spaceValues ? invert(spaceValues) : spaceValues

  const nonNullChildren = filterReactChildren(children, isNotNull)
  const childCount = React.Children.count(nonNullChildren)

  return (
    <Flex alignItems={alignMapping[align]} flexDirection="column" width={1}>
      {React.Children.map(nonNullChildren, (child: ReactNode, index: number) => {
        return index < childCount - 1 ? (
          <Flex key={index} justifyContent={alignMapping[align]} mb={childSpaceValues} width={1}>
            {child}
          </Flex>
        ) : (
          <Flex key={index} justifyContent={alignMapping[align]} width={1}>
            {child}
          </Flex>
        )
      })}
    </Flex>
  )
}

Stack.defaultProps = {
  align: 'left',
  space: 'none',
  invertSpace: false,
}

Stack.displayName = 'Stack'

export default React.memo(Stack)
