import React, { useRef, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Box, Fab, Flex, Icon, Popover, Text } from 'stardust'

import styled from 'styled-components'

import CommentInput from '~/components/CommentInput'
import { ADD_OBSERVATION_COMMENT } from '~/components/Editors/components/Menu/components/Reflections/mutations'
import { useScreenDetector } from '~/hooks/useScreenDetector'
import colors from '~/ui-components/tokens/colors'

const FixedWrapper = styled.div`
  position: fixed;
  z-index: 1000;
`
interface Props {
  postId: Nullable<number>
  handleAddNewReflection: (newReflection: any) => void
}

const NewReflection = ({ postId, handleAddNewReflection }: Props) => {
  const { isMobile, isTablet, isDesktop } = useScreenDetector()
  const anchorRef = useRef(null)
  const [createReflection] = useMutation(ADD_OBSERVATION_COMMENT)
  const [showCommentModal, setShowCommentModal] = useState(false)

  const addReflection = async (content: string) => {
    const response = await createReflection({
      refetchQueries: ['Reflections'],
      awaitRefetchQueries: true,
      variables: {
        documentId: postId,
        content: content,
      },
    })

    if (response.data?.createObservationReflection) {
      handleAddNewReflection(response.data.createObservationReflection)
    }

    setShowCommentModal(false)
    return response
  }

  return (
    <>
      <Flex
        alignItems="center"
        cursor="pointer"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          setShowCommentModal(true)

          // Scroll the input field to the top of the screen
          if (isDesktop) return
          const target = e.target as HTMLElement // Type assertion
          const rect = target.getBoundingClientRect()
          const offset = -50 // Adjust this value as needed
          window.scrollBy({
            top: rect.top + offset,
            behavior: 'smooth',
          })
        }}
        ref={anchorRef}
        width={isMobile ? '100%' : 'fit-content'}
        pr={2}>
        <Fab bg="primary">
          <Icon color="white" name="addAlt" width={24} />
        </Fab>
        <Text fontWeight={500} fontSize="16px" ml={2} lineHeight="24px" color={colors.nebulaBlue8}>
          New Reflection
        </Text>
      </Flex>
      <FixedWrapper>
        <Popover
          anchor={anchorRef}
          placement={isDesktop ? 'right' : isTablet ? 'bottom-end' : 'bottom'}
          show={showCommentModal}
          onToggle={setShowCommentModal}>
          <Box width="576px" maxWidth="calc(100vw - 40px)" p={3} minHeight="88px">
            {showCommentModal ? (
              <CommentInput
                addComment={addReflection}
                buttonText="Send"
                placeholder="Write a reflection..."
                inlineV2
                autoFocus={true}
              />
            ) : null}
          </Box>
        </Popover>
      </FixedWrapper>
    </>
  )
}

NewReflection.displayName = 'NewReflection'

export default React.memo(NewReflection)
