import React from 'react'

import { Box, Flex, Text } from 'stardust'

interface Props {
  renderIcon: () => React.ReactNode
  renderTail?: () => React.ReactNode
  renderText: () => React.ReactNode
  small?: boolean
  max?: boolean
  dataTest?: string | null
}

const ListItem = ({
  renderIcon,
  renderTail,
  renderText,
  small = false,
  max = false,
  dataTest = null,
}: Props) => (
  <Flex alignItems="center" width={1} py={small ? 0 : 2} data-test={dataTest}>
    <Box px={small ? 0 : 3}>{renderIcon()}</Box>
    <Box
      borderBottom={max ? 1 : undefined}
      borderColor={max ? 'surfacePrimaryBorder' : undefined}
      width={max ? 1 : undefined}>
      <Flex justifyContent="flex-start" alignItems="center" width={1}>
        <Box
          py={small ? 0 : 3}
          borderBottom={max ? undefined : 1}
          borderColor={max ? undefined : 'surfacePrimaryBorder'}
          width={max ? 1 : 150}>
          {renderText()}
        </Box>
        {renderTail && <Text as="span">{renderTail()}</Text>}
      </Flex>
    </Box>
  </Flex>
)

ListItem.displayName = 'ListItem'
export default ListItem
