import React, { useState } from 'react'

import { Flex, Image, Text } from 'stardust'
import styled from 'styled-components'

import closeX from '~/assets/images/close-banner.svg'
import raisedHand from '~/assets/images/raised-hand.svg'
import { LAYERS } from '~/theme'

const ModalBox = styled(Flex)`
  position: fixed;
  bottom: 10;
`

const FirstUseBanner = () => {
  const [visible, setVisible] = useState(
    JSON.parse(localStorage.getItem('showCohortTrackingBanner') || 'true')
  )
  const onClose = () => {
    localStorage.setItem('showCohortTrackingBanner', JSON.stringify(false))
    setVisible(false)
  }

  return (
    visible && (
      <ModalBox justifyContent="center" m={3} zIndex={LAYERS.Modal}>
        <Flex alignItems="center" bg="black" borderRadius={1} boxShadow="4dp" maxWidth="65%" p={3}>
          <Image src={raisedHand} height="32px" width="32px" alt="" />
          <Flex flexGrow={1} px={3}>
            <Text color="white" fontSize={1} lineHeight={1.4} data-test="first-use-banner">
              Track the learning of an entire room, group, or individual children and educators. You can also
              change the time period to better understand how they are progressing.
            </Text>
          </Flex>
          <Flex cursor="pointer" minWidth="24px" onClick={onClose} data-test="close-first-use-banner">
            <Image src={closeX} alt="Close the modal" />
          </Flex>
        </Flex>
      </ModalBox>
    )
  )
}

FirstUseBanner.displayName = 'FirstUseBanner'

export default React.memo(FirstUseBanner)
