import { useCallback, useContext, useMemo, useState } from 'react'

import { useFlag, useUnleashClient } from '@unleash/proxy-client-react'

import { useSelectedServiceProviderFkey } from '~/contexts/Service'
import { UserContext } from '~/contexts/User'
import { persistLocally } from '~/utils'

interface FeedbackConfig {
  bodyText: string
  handleAccept: () => void
  handleDecline: () => void
  triggerFeedbackRequestModal: (callback?: () => void) => void
}

interface FeedbackConfigs {
  [key: string]: FeedbackConfig
}

export const useFeedbackRequest = () => {
  const unleashClient = useUnleashClient()
  unleashClient.updateContext({
    properties: {
      ...unleashClient.getContext().properties,
      'feature-provider-scope': useSelectedServiceProviderFkey(),
    },
  })
  const userRatingFeedback = useFlag('user_rating_feedback')
  const aiRegistration = useFlag('ai_registration')
  const item = useMemo(() => {
    if (userRatingFeedback) return 'POST_RATING'
    if (aiRegistration) return 'AI_INTEREST'
    return null
  }, [userRatingFeedback, aiRegistration])
  const [isFeedbackRequestModal, setIsFeedbackRequestModal] = useState(false)
  const user = useContext(UserContext)
  const userFkey = user.state?.fkey

  const closeFeedbackRequestModal = () => setIsFeedbackRequestModal(false)

  const triggerModal = useCallback(
    ({ triggerInterval, retrieve, persist }) =>
      (callback?: () => void) => {
        if (!userFkey) return
        const newItemCount = Number(retrieve(userFkey)) + 1
        persist(userFkey, newItemCount.toString())
        if (newItemCount % triggerInterval === 0) setIsFeedbackRequestModal(true)
        else callback?.()
      },
    [userFkey]
  )

  const { bodyText, handleAccept, handleDecline, triggerFeedbackRequestModal } = useMemo(() => {
    const persistKey = `${item}_FEEDBACK_REQUEST`
    const defaultConfig: FeedbackConfig = {
      bodyText: '',
      handleAccept: closeFeedbackRequestModal,
      handleDecline: closeFeedbackRequestModal,
      triggerFeedbackRequestModal: closeFeedbackRequestModal,
    }

    if (!item) return defaultConfig

    const configMap: FeedbackConfigs = {
      POST_RATING: {
        bodyText: 'Would you like to rate your experience with Posts?',
        handleAccept: () => {
          window.open('https://forms.office.com/e/tYTNKnNCtr', '_blank')
          closeFeedbackRequestModal()
        },
        handleDecline: closeFeedbackRequestModal,
        triggerFeedbackRequestModal: triggerModal({
          triggerInterval: 10,
          ...persistLocally(persistKey),
        }),
      },
      AI_INTEREST: {
        bodyText:
          'Be among the first to explore our AI and shape its future!\nRequires approval from your centre’s authorised person.\nI agree',
        handleAccept: () => {
          closeFeedbackRequestModal()
        },
        handleDecline: () => {
          closeFeedbackRequestModal()
        },
        triggerFeedbackRequestModal: triggerModal({
          triggerInterval: 1,
          ...persistLocally(persistKey),
        }),
      },
    }

    return configMap[item] || defaultConfig
  }, [item, triggerModal])

  return {
    closeFeedbackRequestModal,
    isFeedbackRequestModal,
    triggerFeedbackRequestModal,
    feedbackBodyText: bodyText,
    handleFeedbackAccept: handleAccept,
    handleFeedbackDecline: handleDecline,
  }
}
