import React, { ReactNode } from 'react'

import { Box, Flex, Heading, Image, Text } from 'stardust'

import emptyImage from '~/assets/images/empty.svg'

interface Props {
  children?: ReactNode
  heading: string
  message: string
  path?: string
}

const ErrorPage = ({ children, heading, message }: Props) => {
  return (
    <Flex alignItems="center" flexDirection="column" height="100vh" justifyContent="center" width="100vw">
      <Flex alignItems="center" flexDirection={['column', 'row']} justifyContent="center" width={1}>
        <Image alt="" height={[150, 200]} mb={4} src={emptyImage} width={[150, 200]} />

        <Box maxWidth="650px" px={[3, 4]} width={1}>
          <Heading.h2 fontSize={[4, 6]} lineHeight={1} mt={0} mb={3} textAlign={['center', 'left']}>
            {heading}
          </Heading.h2>

          <Text.p fontSize={[2, 3]} lineHeight={1.4} m={0} textAlign={['center', 'left']}>
            {message}
          </Text.p>
        </Box>
      </Flex>

      {children && <Box mt={4}>{children}</Box>}
    </Flex>
  )
}

ErrorPage.displayName = 'ErrorPage'

export default ErrorPage
