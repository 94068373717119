import React from 'react'

import { plugin as pluginPath } from '@appsignal/plugin-path-decorator'
import { plugin as pluginWindow } from '@appsignal/plugin-window-events'

import { isDev } from '~/config'

import { getLocalService } from '~/contexts/Service'
import { getLocalStrategyRef } from '~/contexts/Strategy'
import * as Analytics from '~/modules/analytics'
import appsignal from '~/modules/appsignal'
import coralogix from '~/modules/coralogix'

export const initGoogleAnalytics = () => {
  Analytics.initialize()
  Analytics.initializeGoogleTagManager()
}

export const initWhyDidYouRender = () => {
  if (isDev) {
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React)
  }
}

export const initAppsignal = () => {
  if (!isDev) {
    // causes appsignal to intercept and report on any unhandled errors
    appsignal.use(pluginWindow({ onerror: true, onunhandledrejection: true }))
    // adds url path to errors
    appsignal.use(pluginPath())
  }
}

export const initCoralogix = () => {
  if (!isDev) {
    coralogix()
  }
}

export const sendAppSignalError = (error: Error) => {
  const serviceFkey = getLocalService() || ''
  const userRef = getLocalStrategyRef() || ''

  appsignal.sendError(error, (span) => {
    span.setTags({ service_fkey: serviceFkey, user_reference: userRef })
  })
}

export default () => {
  initGoogleAnalytics()
  initAppsignal()
  initWhyDidYouRender()
  initCoralogix()
}
