import React from 'react'

const DeleteAlt = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 2.75C8.50736 2.75 7.5 3.75736 7.5 5V5.75H5.25H2.25C1.83579 5.75 1.5 6.08579 1.5 6.5C1.5 6.91421 1.83579 7.25 2.25 7.25H4.5V20C4.5 21.2426 5.50736 22.25 6.75 22.25H17.25C18.4926 22.25 19.5 21.2426 19.5 20V7.25H21.75C22.1642 7.25 22.5 6.91421 22.5 6.5C22.5 6.08579 22.1642 5.75 21.75 5.75H18.75H16.5V5C16.5 3.75736 15.4926 2.75 14.25 2.75H9.75ZM15 5.75V5C15 4.58579 14.6642 4.25 14.25 4.25H9.75C9.33579 4.25 9 4.58579 9 5V5.75H15ZM8.25 7.25H6V20C6 20.4142 6.33579 20.75 6.75 20.75H17.25C17.6642 20.75 18 20.4142 18 20V7.25H15.75H8.25ZM9.75 10.25C10.1642 10.25 10.5 10.5858 10.5 11V17C10.5 17.4142 10.1642 17.75 9.75 17.75C9.33579 17.75 9 17.4142 9 17V11C9 10.5858 9.33579 10.25 9.75 10.25ZM15 11C15 10.5858 14.6642 10.25 14.25 10.25C13.8358 10.25 13.5 10.5858 13.5 11V17C13.5 17.4142 13.8358 17.75 14.25 17.75C14.6642 17.75 15 17.4142 15 17V11Z"
      fill="black"
    />
  </svg>
)

export default DeleteAlt
