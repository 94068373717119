import React from 'react'

import * as R from 'ramda'
import { Box } from 'stardust'
import styled from 'styled-components'

import Placeholder from '~/components/Layouts/Placeholder'
import MediaSelector from '~/components/Media/MediaSelector'
import Modal from '~/components/Modal'

import DisplayMedia from './DisplayMedia'

const DropZone = styled(Box)`
  color: white;
`

export default class MediaDropZone extends React.Component {
  static displayName = 'MediaDropZone'

  state = {
    mediaType: null,
    openChooser: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      mediaType: this.props.mediaType,
      openChooser: props.openChooser,
    }
  }

  closeModal = () => {
    this.setState({
      ...this.state,
      openChooser: false,
    })

    if (this.props.onClose) {
      this.props.onClose(this.props.media)
    }
  }

  handleMediaSelect = (media) => {
    this.props.onMediaSelection(media)
    this.setState({
      ...this.state,
      mediaType: null,
      openChooser: false,
    })
  }

  onDrop = (e) => {
    e.stopPropagation()

    const droppedData = e.dataTransfer.getData('data')
    if (!droppedData) {
      return
    }

    const {
      data: { type },
    } = JSON.parse(droppedData)

    if (!['image', 'file', 'video'].includes(type)) {
      return
    }

    this.setState({
      ...this.state,
      mediaType: type,
      openChooser: true,
    })
  }

  render = () => (
    <DropZone onDragOver={(e) => e.preventDefault()} onDrop={this.onDrop} height="100%" width="auto">
      {R.ifElse(
        R.isNil,
        R.always(<Placeholder />),
        R.always(<DisplayMedia {...this.props.media} />)
      )(this.props.media)}

      <Modal open={this.state.openChooser} onClose={this.closeModal}>
        <Box width="75vw">
          <MediaSelector selectedType={this.state.mediaType} onMediaSelect={this.handleMediaSelect} />
        </Box>
      </Modal>
    </DropZone>
  )
}
