import React from 'react'

import { Box, Flex, Icon, Text } from 'stardust'
import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

interface Props {
  onCancelClick: () => void
}

const S = {
  Icon: styled(Icon)<{ backgroundColor: string; hoverColor: string }>`
    border-radius: 50%;
    padding: 10px;
    background-color: ${(props) => props.backgroundColor};
    &:hover {
      background-color: ${(props) => props.hoverColor};
      transition: background-color 0.2s;
    }
  `,
  Top: styled(Box)`
    background-color: ${colors.cosmicShade0};
    z-index: 100;
    position: sticky;
    top: 0;
    overflow-y: 0;
  `,
}

const TaggedDocumentsHeaderMobile = ({ onCancelClick }: Props) => {
  return (
    <>
      <S.Top pt={3} pb={1}>
        <Flex justifyContent="space-between" bg="white" width="100vw">
          <Flex alignItems="center">
            <Icon ml={2} width={24} onClick={onCancelClick} name="chevronLeft" />
            <S.Icon
              name="links"
              width={40}
              border-radius="50%"
              backgroundColor={colors.gaiaGreen10}
              hoverColor={colors.gaiaGreen9}
            />
            <Text ml={2} fontSize={3} fontWeight={600}>
              Link to Related Learning
            </Text>
          </Flex>
        </Flex>
      </S.Top>

      <Box mx={24} mt={2}>
        <Text fontSize={2} lineHeight="24px">
          Include links to related Learning Documentation.
        </Text>
      </Box>
    </>
  )
}

TaggedDocumentsHeaderMobile.displayName = 'TaggedDocumentsHeaderMobile'

export default TaggedDocumentsHeaderMobile
