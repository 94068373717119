import React, { ReactNode } from 'react'

import * as R from 'ramda'

import { Icon, Image, Video } from 'stardust'

import { isWebView } from '~/config'

import HlsVideo from '~/ui-components/HlsVideo'

interface MediaPlayerProps {
  coverUrl?: string
  height?: string | string[]
  mimeType: string
  title?: string
  url: string
}

const MediaPlayer = ({ height, mimeType, url, coverUrl, title = '' }: MediaPlayerProps) => {
  return (
    <>
      {R.pipe<string, string, string[], ReactNode>(
        R.defaultTo(''),
        R.split('/'),
        R.cond<any, ReactNode>([
          [
            R.contains('video'),
            R.always(
              <Video
                autoPlay={false}
                maxHeight={height}
                maxWidth="100%"
                preload="metadata"
                src={url}
                controlsList={isWebView ? 'nodownload' : ''}
              />
            ),
          ],
          [R.contains('vnd.apple.mpegurl'), R.always(<HlsVideo coverUrl={coverUrl} src={url} />)],
          [R.contains('image'), R.always(<Image alt={title} maxHeight={height} src={url} />)],
          [R.T, R.always(<Icon name="imagePlaceholder" width={100} fill="cosmicShade2" />)],
        ])
      )(mimeType)}
    </>
  )
}

MediaPlayer.displayName = 'MediaPlayer'

export default React.memo(MediaPlayer)
