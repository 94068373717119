import React, { useState } from 'react'

import { Box, Flex, Icon, MainButton, SecondaryButton, Text } from 'stardust'

import styled from 'styled-components'

import Modal from '~/components/Modal'

import StatusPill from '~/components/StatusPill'

import colors from '~/ui-components/tokens/colors'

import Save from './Save'

const S = {
  MainButton: styled(MainButton)`
    height: 48px;
    padding: 12px 24px 12px 24px;
  `,
  SecondaryButton: styled(SecondaryButton)`
    height: 48px;
    padding: 12px 24px 12px 24px;
  `,
  Icon: styled(Icon)`
    border-radius: 50%;
    padding: 10px;
    background-color: ${colors.cosmicShade4};
    &:hover {
      background-color: ${colors.cosmicShade6};
      transition: background-color 0.2s;
    }
  `,
}

interface Props {
  status?: Playground.Status
  isPreviewOpen?: boolean | undefined
  rooms?: Playground.ObservationRoom[]
  title?: string | undefined
  TaggedChildren?: Playground.SimpleChild[]
  isEditing: boolean
  onSubmit: (statusToSave: string, setLoadingFalse: () => void) => void
  onSaveClick: () => void
  handleCloseModal: () => void
  setIsPreviewOpen: (isOpen: boolean) => void
}

const ModalHeader = ({
  title,
  rooms,
  status,
  TaggedChildren,
  isEditing,
  isPreviewOpen,
  onSubmit,
  onSaveClick,
  handleCloseModal,
  setIsPreviewOpen,
}: Props) => {
  const [confirmClose, setConfirmClose] = useState(false)
  const [saveClickState, setSaveClickState] = useState(false)
  const onClose = () => setConfirmClose(!confirmClose)

  const handleSaveClick = () => {
    if (rooms && rooms.length > 0 && title) {
      setSaveClickState(true)
    } else {
      setSaveClickState(false)
    }
    onSaveClick()
  }

  return (
    <Flex justifyContent="space-between" ml={2} mb={2} mt={1}>
      <Flex justifyContent="left" alignItems="center">
        {isPreviewOpen ? null : (
          <S.Icon width={40} onClick={isEditing ? () => handleCloseModal() : onClose} name="close" />
        )}
        <Text ml={isPreviewOpen ? 4 : 3} fontSize={4} fontWeight={500}>
          {isPreviewOpen || isEditing ? (isEditing ? 'Edit Post' : 'Preview post') : 'Create Post'}
        </Text>
        <Flex ml={3}>
          <StatusPill status={status} fontSize={1} />
        </Flex>
      </Flex>

      <Modal open={confirmClose} dontRenderCloseButton onClose={onClose}>
        <Flex p={3} width="610px" flexDirection="column">
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize={3} fontWeight="bold">
              Discard Your changes?
            </Text>
            <S.Icon width={40} onClick={onClose} name="close" />
          </Flex>
          <Flex mt={2}>
            <Text fontSize={2} mr={2}>
              You will lose any updates or changes you made to the Post if you continue. This action can not
              be undone.
            </Text>
          </Flex>
          <Box mt={4}>
            <S.MainButton outline onClick={() => handleCloseModal()}>
              Discard changes
            </S.MainButton>
          </Box>
          <Box mt={2}>
            <S.SecondaryButton outline onClick={onClose}>
              Cancel
            </S.SecondaryButton>
          </Box>
        </Flex>
      </Modal>

      <Flex>
        <Box mx={1}>
          <S.SecondaryButton outline onClick={isEditing ? () => handleCloseModal() : onClose}>
            Cancel
          </S.SecondaryButton>
        </Box>
        <Box mx={1}>
          <S.SecondaryButton outline onClick={() => setIsPreviewOpen(!isPreviewOpen)}>
            {isPreviewOpen ? 'Continue editing' : 'Preview'}
          </S.SecondaryButton>
        </Box>
        <Box ml={1}>
          <S.MainButton onClick={handleSaveClick}>Save</S.MainButton>
          <Save
            isEditing={isEditing}
            TaggedChildren={TaggedChildren}
            onSubmit={onSubmit}
            open={saveClickState}
            setIsPreviewOpen={setIsPreviewOpen}
            onClose={() => setSaveClickState(!saveClickState)}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
ModalHeader.displayName = 'ModalHeader'

export default React.memo(ModalHeader)
