import React, { useEffect, useRef, useState } from 'react'

import { Box, Flex } from 'stardust'

import Modal from '~/components/Modal'
import { useSelectedServiceFkey } from '~/contexts/Service'
import TaggedDocumentsView from '~/pages/Root/components/TaggedDocumentsView'

import { FieldProps } from '../../types'

import LinksSelectedDocumentPreview from './Links/LinksSelectedDocumentPreview'

interface Props extends FieldProps {
  documentIds: string[]
  taggedDocuments: Playground.Document[]
  onCloseLinksModal: () => void
}

const PostLinksModal = ({ taggedDocuments, documentIds, onChange, onCloseLinksModal }: Props) => {
  const [selectedDocument, setSelectedDocument] = useState<Nullable<Playground.SimpleDocument>>(null)
  const [showMorePreviewCard, setShowMorePreviewCard] = useState(false)
  const [searchString, setSearchString] = useState<Nullable<string>>(null)
  const scrollRef = useRef<HTMLDivElement>(null)

  const serviceFkey = useSelectedServiceFkey()

  useEffect(() => {
    if (searchString == '') {
      setSearchString(null)
    }
  }, [searchString])

  const handleCancel = () => {
    setSelectedDocument(null)
    onCloseLinksModal()
  }

  return (
    <Box>
      <Modal open onClose={() => handleCancel()} dontRenderCloseButton ref={scrollRef}>
        <Flex bg="surfacePrimary" borderRadius="16px" height="max-content" minWidth="0">
          <TaggedDocumentsView
            serviceFkey={serviceFkey}
            onChange={onChange}
            onCloseLinksModal={onCloseLinksModal}
            taggedDocuments={taggedDocuments}
            documentIds={documentIds}
            setSelectedDocument={setSelectedDocument}
            scrollRef={scrollRef}
          />
          <LinksSelectedDocumentPreview
            selectedDocument={selectedDocument}
            showMorePreviewCard={showMorePreviewCard}
            setShowMorePreviewCard={setShowMorePreviewCard}
          />
        </Flex>
      </Modal>
    </Box>
  )
}

PostLinksModal.displayName = 'PostLinksModal'
export default React.memo(PostLinksModal)
