import React from 'react'

const Info = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 1.4768e-06 11 9.45698e-07C4.92487 4.14593e-07 3.4001e-06 4.92487 2.869e-06 11C2.33789e-06 17.0751 4.92487 22 11 22ZM20.625 11C20.625 16.3157 16.3157 20.625 11 20.625C5.68426 20.625 1.375 16.3157 1.375 11C1.375 5.68426 5.68426 1.375 11 1.375C16.3157 1.375 20.625 5.68426 20.625 11ZM11 17.875C10.6203 17.875 10.3125 17.5672 10.3125 17.1875L10.3125 9.625C10.3125 9.2453 10.6203 8.9375 11 8.9375C11.3797 8.9375 11.6875 9.2453 11.6875 9.625L11.6875 17.1875C11.6875 17.5672 11.3797 17.875 11 17.875ZM12.0313 6.53125C12.0313 7.10079 11.5695 7.5625 11 7.5625C10.4305 7.5625 9.96875 7.10079 9.96875 6.53125C9.96875 5.96171 10.4305 5.5 11 5.5C11.5695 5.5 12.0313 5.96171 12.0313 6.53125Z"
      fill="#4E3A7E"
    />
  </svg>
)

export default Info
