import React, { useCallback, useState } from 'react'

import DayPicker from 'react-day-picker'
import styled from 'styled-components'
import 'react-day-picker/lib/style.css'

import { isTouchDevice } from '~/utils'

import Inline from './Inline'
import Menu from './Menu'
import Stack from './Stack'
import Text from './Text'

const resolveColor = (theme: Theme, color: string) => {
  return theme.colors[color]
}

const CalandarContainer = styled.div<{ theme: Theme }>`
  .DayPicker-wrapper:focus {
    outline: 0px;
  }

  .DayPicker-Day {
    color: ${({ theme }) => resolveColor(theme, theme.components.calendar.textColor)};
    border-radius: 0;
  }

  .DayPicker-Day {
    border: 1px solid transparent;
  }

  .DayPicker-Day--outside {
    color: ${({ theme }) => resolveColor(theme, theme.components.calendar.outsideTextColor)};
  }

  .DayPicker-Day--today {
    color: ${({ theme }) => resolveColor(theme, theme.components.calendar.todayTextColor)} !important;
    font-weight: 700;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
      .DayPicker-Day--outside
    ):hover {
    background: ${({ theme }) => resolveColor(theme, theme.components.calendar.hoverColor)};
    text-shadow: 0px 0px 0px ${({ theme }) => resolveColor(theme, theme.components.calendar.hoverShadowColor)};
  }

  .DayPicker-Day--hover {
    background: ${({ theme }) => resolveColor(theme, theme.components.calendar.hoverColor)};
    text-shadow: 0px 0px 0px ${({ theme }) => resolveColor(theme, theme.components.calendar.hoverShadowColor)};
  }

  .DayPicker-Day--outside:hover {
    background: ${({ theme }) => resolveColor(theme, theme.components.calendar.hoverColor)};
    text-shadow: 0px 0px 0px ${({ theme }) => resolveColor(theme, theme.components.calendar.hoverShadowColor)};
  }

  .DayPicker-Day--selected:hover {
    text-shadow: 0px 0px 0px
      ${({ theme }) => resolveColor(theme, theme.components.calendar.selectedHoverShadowColor)};
  }

  .DayPicker-Day--selected.DayPicker-Day--hover {
    text-shadow: 0px 0px 0px
      ${({ theme }) => resolveColor(theme, theme.components.calendar.selectedHoverShadowColor)};
  }

  .DayPicker-Day--today:hover {
    text-shadow: 0px 0px 1px
      ${({ theme }) => resolveColor(theme, theme.components.calendar.todayHoverShadowColor)};
  }

  .DayPicker-Day--today.DayPicker-Day--hover {
    text-shadow: 0px 0px 1px
      ${({ theme }) => resolveColor(theme, theme.components.calendar.todayHoverShadowColor)};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background: ${({ theme }) => theme.components.calendar.selectedColor};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
    background: ${({ theme }) => theme.components.calendar.selectedColor};
    color: ${({ theme }) => resolveColor(theme, theme.components.calendar.selectedTextColor)};
  }

  .DayPicker-Day--from {
    background: ${({ theme }) => theme.components.calendar.rangeCapTextColor} !important;
  }

  .DayPicker-Day--to {
    background: ${({ theme }) => theme.components.calendar.rangeCapTextColor} !important;
  }

  .DayPicker-Day:focus {
    outline: 0px;
    border: 1px solid ${({ theme }) => theme.components.calendar.focusBorderColor};
  }

  .DayPicker-Caption > div {
    color: ${({ theme }) => theme.colors[theme.components.calendar.textColor]};
    display: flex;
  }

  .DayPicker-Caption > select {
    background: transparent;
    color: ${({ theme }) => resolveColor(theme, theme.components.calendar.textColor)};
    font-weight: 500;
    font-size: 0.875em;
    font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  }

  .DayPicker-Caption-Month-Option,
  .DayPicker-Caption-Year-Option {
    color: ${({ theme }) => resolveColor(theme, theme.components.calendar.textColor)};
    font-weight: 500;
    font-size: 0.875em;
    font-family: 'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  }

  .DayPicker-Weekday > abbr {
    color: ${({ theme }) => resolveColor(theme, theme.components.calendar.textColor)};
  }

  .DayPicker-Month {
    border-collapse: unset;
  }

  .DayPicker-NavButton {
    outline: 0;
    z-index: 1 !important;
  }

  .DayPicker-NavButton:focus {
    border: 1px solid ${({ theme }) => theme.components.calendar.focusBorderColor};
  }

  .mdc-menu {
    max-height: 256px !important;
  }

  .mdc-menu-surface--anchor:hover {
    cursor: pointer !important;
  }

  .DayPicker-Caption > div > div:last-child {
    position: absolute !important;
    left: 120px !important;
  }
`

export interface CalendarProps {
  selected?: Date | { from: Date; to: Date }
  hovered?: Date | { from: Date; to: Date }
  startYear?: number
  endYear?: number
  initialDisplayMonth?: Date
  firstDayOfWeek?: number
  defaultSelectedToCurrentDate?: boolean
  onSelectedChange?(day: Date): void
  onHoverChange?(day: Nullable<Date>): void
}

export const Calendar = ({
  selected,
  hovered,
  startYear,
  endYear,
  initialDisplayMonth,
  firstDayOfWeek = 0,
  defaultSelectedToCurrentDate = true,
  onSelectedChange,
  onHoverChange,
}: CalendarProps) => {
  const getInitialDate = (initialSelectedDate?: Date | { from: Date; to: Date }) => {
    if (!initialSelectedDate) {
      if (defaultSelectedToCurrentDate) return new Date()
      return undefined
    }

    if (initialSelectedDate instanceof Date) {
      return initialSelectedDate
    } else {
      return initialSelectedDate.from || initialSelectedDate.to || new Date()
    }
  }

  const initialDate = getInitialDate(selected)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(initialDate)
  const [month, setMonth] = useState<Date>(initialDisplayMonth || initialDate || new Date())

  const today = new Date()
  const fromYear = startYear || (initialDate && initialDate.getFullYear() - 10) || today.getFullYear() - 10
  const toYear = endYear || (initialDate && initialDate.getFullYear() + 5) || today.getFullYear() + 5
  const fromDate = new Date(fromYear, 0)
  const toDate = new Date(toYear, 11)

  const modifiers = () => {
    const selectedModifiers =
      !selected || selected instanceof Date
        ? {}
        : {
            selected: selected,
            from: selected.from,
            to: selected.to,
          }

    const hoveredModifiers = !hovered || hovered instanceof Date ? {} : { hover: hovered }

    return { ...hoveredModifiers, ...selectedModifiers }
  }

  const onDayClick = useCallback(
    (date: Date) => {
      onSelectedChange && onSelectedChange(date)
      setSelectedDate(date)
      setMonth(date)
    },
    [onSelectedChange]
  )

  const onDayEnter = useCallback(
    (date: Date) => {
      onHoverChange && onHoverChange(date)
    },
    [onHoverChange]
  )

  const onYearChange = useCallback(
    (year: any) => {
      setMonth(new Date(year, month.getMonth()))
    },
    [month]
  )

  const onDayLeave = useCallback(() => {
    onHoverChange && onHoverChange(null)
  }, [onHoverChange])

  function YearMonthForm({ date, localeUtils }: any) {
    const months = localeUtils.getMonths()

    const years: number[] = []
    const arr: undefined[] = [...Array(toDate.getFullYear() - fromDate.getFullYear() + 1)]
    arr.forEach((v: undefined, i: number) => {
      years.push(toDate.getFullYear() - i)
    })

    return (
      <form className="DayPicker-Caption">
        <Inline alignY="center" space="small">
          <Menu
            onSelect={(i) => {
              setMonth(new Date(date.getFullYear(), i))
            }}
            items={months.map((month: any) => month)}>
            <Text>{date.toLocaleString('default', { month: 'long' })}</Text>
          </Menu>
          <Menu
            onSelect={(i) => {
              onYearChange(years[i])
            }}
            items={years.map((year: number) => year.toString())}>
            <Text>{date.getFullYear()}</Text>
          </Menu>
        </Inline>
      </form>
    )
  }

  return (
    <CalandarContainer>
      <Stack>
        <DayPicker
          className="DayPicker"
          enableOutsideDaysClick={false}
          selectedDays={selected || selectedDate}
          modifiers={modifiers()}
          onDayClick={onDayClick}
          onDayMouseEnter={(date) => (!isTouchDevice() ? onDayEnter(date) : null)}
          onDayMouseLeave={() => (!isTouchDevice() ? onDayLeave() : null)}
          showOutsideDays={true}
          fixedWeeks={true}
          month={month}
          fromMonth={fromDate}
          toMonth={toDate}
          firstDayOfWeek={firstDayOfWeek}
          captionElement={({ date, localeUtils }) => (
            <YearMonthForm date={date} startYear={startYear} endYear={endYear} localeUtils={localeUtils} />
          )}
        />
      </Stack>
    </CalandarContainer>
  )
}

Calendar.displayName = 'Calendar'

export default React.memo(Calendar)
