import React from 'react'

import Flex from './Flex'
import Heading from './Heading'
import Image from './Image'

interface Props {
  heading: string
  image: string
  verticallyCenter?: boolean
}

const Empty = ({ heading, image, verticallyCenter }: Props) => (
  <Flex alignItems="center" flexDirection="column" justifyContent={verticallyCenter ? 'center' : undefined}>
    <Image alt="" src={image} />
    <Heading.h3 caps>No {heading}</Heading.h3>
  </Flex>
)

Empty.displayName = 'Empty'

export default React.memo(Empty)
