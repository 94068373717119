import React from 'react'

import { Box, Flex, Text, Checkbox } from 'stardust'

import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

interface Props {
  outcomes: Playground.LearningFrameworkOutcome[]
  showOutcomes: boolean
  clickedOutcomes: Playground.LearningFrameworkOutcome[]
  onOutcomeClick: (outcome: Playground.LearningFrameworkOutcome) => void
}

const DisplayOutcomes = styled(Box)<{ borderColor: string; backgroundColor: string; hoverColor: string }>`
  border: 2px solid;
  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 16px;
  padding: 10px;
  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`

const Outcomes = ({ outcomes, showOutcomes, onOutcomeClick, clickedOutcomes }: Props) => {
  const borderColor = (outcome: Playground.LearningFrameworkOutcome) => {
    return clickedOutcomes.some((clickedOutcome) => clickedOutcome.id === outcome.id)
      ? colors.nebulaBlue4
      : 'transparent'
  }
  const backgroundColor = (outcome: Playground.LearningFrameworkOutcome) => {
    return clickedOutcomes.some((clickedOutcome) => clickedOutcome.id === outcome.id)
      ? colors.nebulaBlue0
      : 'transparent'
  }
  const hoverColor = (outcome: Playground.LearningFrameworkOutcome) => {
    return clickedOutcomes.some((clickedOutcome) => clickedOutcome.id === outcome.id)
      ? colors.nebulaBlue0
      : colors.cosmicShade12
  }

  if (!showOutcomes) {
    return null
  }

  return (
    <Box mb={3} pl={3} pt={3} pr={3}>
      {outcomes.map((outcome) => (
        <DisplayOutcomes
          key={outcome.id}
          pl={3}
          pt={3}
          pb={outcomes.length > 1 ? 3 : 0}
          mb={outcomes.length > 1 ? 1 : 0}
          onClick={() => onOutcomeClick(outcome)}
          borderColor={borderColor(outcome)}
          backgroundColor={backgroundColor(outcome)}
          hoverColor={hoverColor(outcome)}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            pr={3}
            pl={2}
            data-test="create-post-learning-outcomes-list-item">
            <Text fontSize="14px" mr={4}>
              {outcome.name}
            </Text>
            <Checkbox
              checked={clickedOutcomes.some((clickedOutcome) => clickedOutcome.id === outcome.id)}
              onChange={() => onOutcomeClick(outcome)}
            />
          </Flex>
        </DisplayOutcomes>
      ))}
    </Box>
  )
}
Outcomes.displayName = 'Outcomes'
export default React.memo(Outcomes)
