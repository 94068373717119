import React from 'react'

import { Box, Icon, Text, Flex, MainButton, SecondaryButton, Image } from 'stardust'
import styled from 'styled-components'

import PlaygroundSupport from '~/assets/images/playground-support.svg'
import Modal from '~/components/Modal'
import colors from '~/ui-components/tokens/colors'

const S = {
  Icon: styled(Icon)`
    padding: 10px 10px 10px 10px;
    border-radius: 50%;
    background-color: ${colors.cosmicShade4};
    &:hover {
      background-color: ${colors.cosmicShade14};
      transition: background-color 0.2s;
    }
  `,
}

interface FeedbackRequestProps {
  isOpen: boolean
  bodyText: string
  onClose: () => void
  handleAccept: () => void
  handleDecline: () => void
}

const FeedbackRequest = ({
  isOpen,
  bodyText,
  onClose,
  handleAccept,
  handleDecline,
}: FeedbackRequestProps) => {
  return (
    <Modal dontRenderCloseButton open={isOpen} onClose={onClose} borderRadius="16px">
      <Box width="368px" maxWidth="calc(100vw - 32px)" p="20px 24px 24px 24px">
        <Flex justifyContent="end">
          <S.Icon name="close" width={40} onClick={onClose} />
        </Flex>
        <Image src={PlaygroundSupport} m="auto" height="141px" alt="" />
        <Text fontSize={3} fontWeight={600} textAlign="center" mt="20px">
          {bodyText}
        </Text>
        <MainButton mt="24px" height="48px" onClick={handleAccept}>
          Yes
        </MainButton>
        <SecondaryButton outline mt={2} height="48px" onClick={handleDecline}>
          No
        </SecondaryButton>
      </Box>
    </Modal>
  )
}
FeedbackRequest.displayName = 'FeedbackRequest'
export default FeedbackRequest
