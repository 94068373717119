import React from 'react'

import { Flex, Image, Relative } from 'stardust'
import * as borderRadius from 'stardust/utils/borderRadius'
import styled from 'styled-components'

import momentIcon from '~/assets/images/moments.svg'
import planIcon from '~/assets/images/plans.svg'
import observationIcon from '~/assets/images/postsAlt.svg'
import storyIcon from '~/assets/images/stories.svg'
import tableIcon from '~/assets/images/tables.svg'

const FlexRadius = styled(Flex)<{ brTopLeft: number; brTopRight: number }>`
  ${borderRadius.topLeft}
  ${borderRadius.topRight}
`

interface PlaceholderStyle {
  color: string
  image: string
}

const styles: Record<string, PlaceholderStyle> = {
  learning_plan: { color: 'plan', image: planIcon },
  learning_story: { color: 'story', image: storyIcon },
  learning_table: { color: 'table', image: tableIcon },
  moment: { color: 'moment', image: momentIcon },
  observation: { color: 'observation', image: observationIcon },
}

interface Props {
  type: Playground.DocumentType
  height?: string
}

const DocumentCardPlaceholder = ({ height, type }: Props) => {
  const style = styles[type]

  if (!style) {
    console.warn(`DocumentCardPlaceholder: unknown type ${type}`)
  }

  return (
    <Relative>
      <FlexRadius bg={`${style.color}Light`} brTopLeft={16} brTopRight={16} height={height || '116px'}>
        <Flex alignItems="center" height="100%" justifyContent="center" width="100%">
          <Image src={style.image} alt="" />
        </Flex>
      </FlexRadius>
    </Relative>
  )
}

DocumentCardPlaceholder.displayName = 'DocumentCardPlaceholder'

export default React.memo(DocumentCardPlaceholder)
