import React, { useState, useRef } from 'react'

import { Box, Flex, Text, Divider, Icon } from 'stardust'

import styled from 'styled-components'

import useClickedOutside from '~/hooks/useClickedOutside'

import colors from '~/ui-components/tokens/colors'

interface Props {
  frameworks: Playground.LearningFramework[]
  handleSelectFramework: (outcome: Playground.LearningFramework | null) => void
  selectedFramework?: Playground.LearningFramework | null
}

const SelectorButton = styled(Flex)`
  border: 1px solid;
  border-color: grey;
  background-color: white;
  border-radius: 16px;
  height: 48px;
  cursor: pointer;
`

const Options = styled(Flex)`
  z-index: 100;
  border: 1px solid;
  border-color: grey;
  border-radius: 12px;
  background-color: white;
  max-height: 240px;
  overflow-y: auto;
  width: 40%;
  position: absolute;
`

const FrameworkSelector = ({ frameworks, handleSelectFramework, selectedFramework }: Props) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [openSelector, setOpenSelector] = useState(false)

  useClickedOutside(() => setOpenSelector(false), dropdownRef)

  return (
    <Box mb={4}>
      <SelectorButton
        width="40%"
        p={2}
        alignItems="center"
        justifyContent="space-between"
        onClick={() => {
          setOpenSelector(!openSelector)
        }}>
        <Text fontSize={2} fontWeight={400} ml={2}>
          {selectedFramework ? selectedFramework.fullName : 'All Frameworks'}
        </Text>
        <Icon name={openSelector ? 'arrowUp' : 'arrowDown'} width={12} mr={3} />
      </SelectorButton>
      {openSelector && (
        <Options flexDirection="column" mt={1} ref={dropdownRef}>
          <Box
            onClick={() => {
              handleSelectFramework(null)
              setOpenSelector(false)
            }}>
            <Text p={3}>All Frameworks</Text>
          </Box>
          <Divider borderColor={colors.cosmicShade4} />
          {frameworks.map((framework: Playground.LearningFramework) => {
            return (
              <Box
                key={framework.id}
                onClick={() => {
                  handleSelectFramework(framework)
                  setOpenSelector(false)
                }}>
                <Text p={3}>{framework.fullName}</Text>
                <Divider borderColor={colors.cosmicShade4} />
              </Box>
            )
          })}
        </Options>
      )}
    </Box>
  )
}

FrameworkSelector.displayName = 'FrameworkSelector'
export default React.memo(FrameworkSelector)
