import { useReducer } from 'react'

import { uniqBy } from 'lodash'
import * as R from 'ramda'

import { getFkeyFromObject } from '~/utils'

import { useAutosave } from '../localStorage'

import { ChildRanking, PostState, RankingChangeProps, UpdateProps } from '../types'

const MAX_LEARNING_CONTENT_ASSETS_PER_OBSERVATION = 3

enum PostStateType {
  SELECT_LEARNING_OUTCOME = 'SELECT_LEARNING_OUTCOME',
  REMOVE_LEARNING_OUTCOME = 'REMOVE_LEARNING_OUTCOME',
  SELECT_CHILD = 'SELECT_CHILD',
  REMOVE_CHILD = 'REMOVE_CHILD',
  BULK_SELECT_CHILDREN = 'BULK_SELECT_CHILDREN',
  BULK_REMOVE_CHILDREN = 'BULK_REMOVE_CHILDREN',
  UPDATE = 'UPDATE',
  UPDATE_UPLOADED_MEDIA = 'UPDATE_UPLOADED_MEDIA',
  REMOVE_UPLOADED_MEDIA = 'REMOVE_UPLOADED_MEDIA',
  REMOVE_MEDIA = 'REMOVE_MEDIA',
  UPDATE_CHILD_RANKING = 'UPDATE_CHILD_RANKING',
  SELECT_LEARNING_CONTENT = 'SELECT_LEARNING_CONTENT',
  REMOVE_LEARNING_CONTENT = 'REMOVE_LEARNING_CONTENT',
}

enum PostStateToggleType {
  TOGGLE_CHILD = 'TOGGLE_CHILD',
  TOGGLE_LEARNING_OUTCOME = 'TOGGLE_LEARNING_OUTCOME',
  TOGGLE_LEARNING_CONTENT = 'TOGGLE_LEARNING_CONTENT',
}

export interface PostContextProps {
  state: PostState
  handlers: {
    bulkSelectChildren(children: Playground.SimpleChild[]): void
    bulkRemoveChildren(fkeys: string[]): void
    onChange({ name, value }: UpdateProps): void
    uploadedMedia(uploadedMedia: Playground.MediaItem): void
    removeUploadedMedia({ id }: { id: number }): void
    removeMedia({ id }: { id: number }): void
    rankingChange({ childRankingId, type, value }: RankingChangeProps): void
    toggleLearningOutcome(learningOutcome: Playground.LearningFrameworkOutcome): void
    toggleChild(child: Playground.SimpleChild): void
    toggleLearningContent(learningContent: Playground.LearningContentAsset): void
  }
}

export const initialContextState: PostContextProps = {
  state: {
    allowComments: true,
    // assistantConversationMessages: [],
    availableRooms: [],
    children: [],
    learningOutcomes: [],
    media: [],
    rooms: [],
    uploadedMedia: [],
    learningContentAssets: [],
    canAddLearningContentAssets: true,
    documentIds: [],
    documents: [],
  },
  handlers: {
    bulkSelectChildren: () => {},
    bulkRemoveChildren: () => {},
    onChange: () => {},
    uploadedMedia: () => {},
    removeUploadedMedia: () => {},
    removeMedia: () => {},
    rankingChange: () => {},
    toggleLearningOutcome: () => {},
    toggleChild: () => {},
    toggleLearningContent: () => {},
  },
}
type Action =
  | {
      type: PostStateType.SELECT_LEARNING_OUTCOME
      learningOutcome: Playground.LearningFrameworkOutcome
    }
  | {
      type: PostStateType.REMOVE_LEARNING_OUTCOME
      learningOutcome: Playground.LearningFrameworkOutcome
    }
  | { type: PostStateType.SELECT_CHILD; child: Playground.SimpleChild }
  | { type: PostStateType.REMOVE_CHILD; fkey: string }
  | { type: PostStateType.BULK_SELECT_CHILDREN; children: Playground.SimpleChild[] }
  | { type: PostStateType.BULK_REMOVE_CHILDREN; fkeys: string[] }
  | { type: PostStateType.UPDATE; name: string; value: string }
  | { type: PostStateType.UPDATE_UPLOADED_MEDIA; uploadedMedia: Playground.MediaItem }
  | { type: PostStateType.REMOVE_UPLOADED_MEDIA; id: number }
  | { type: PostStateType.REMOVE_MEDIA; id: number }
  | {
      type: PostStateType.UPDATE_CHILD_RANKING
      childRankingId: string
      key: string
      value: ChildRanking
    }
  | {
      type: PostStateType.SELECT_LEARNING_CONTENT
      learningContentAsset: Playground.LearningContentAsset
    }
  | {
      type: PostStateType.REMOVE_LEARNING_CONTENT
      learningContentAsset: Playground.LearningContentAsset
    }

type ToggleAction =
  | { type: PostStateToggleType.TOGGLE_CHILD; child: Playground.SimpleChild }
  | {
      type: PostStateToggleType.TOGGLE_LEARNING_OUTCOME
      learningOutcome: Playground.LearningFrameworkOutcome
    }
  | {
      type: PostStateToggleType.TOGGLE_LEARNING_CONTENT
      learningContentAsset: Playground.LearningContentAsset
    }
  | Action

const remove = <T>(prop: string, id: string | number, collection: T) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return R.reject((item: any) => R.propEq(prop, id, item), collection) as T
}

const reducer = (state: PostState, action: Action): PostState => {
  switch (action.type) {
    case PostStateType.SELECT_LEARNING_OUTCOME: {
      const { learningOutcomes } = state

      return { ...state, learningOutcomes: [...learningOutcomes, action.learningOutcome] }
    }

    case PostStateType.REMOVE_LEARNING_OUTCOME: {
      const { learningOutcomes } = state

      return {
        ...state,
        learningOutcomes: remove<Playground.LearningFrameworkOutcome[]>(
          'id',
          action.learningOutcome.id,
          learningOutcomes
        ),
      }
    }

    case PostStateType.SELECT_CHILD: {
      const { children } = state
      return { ...state, children: [...children, action.child] }
    }

    case PostStateType.REMOVE_CHILD: {
      const { children } = state
      return {
        ...state,
        children: remove<Playground.SimpleChild[]>('fkey', action.fkey, children),
      }
    }

    case PostStateType.BULK_SELECT_CHILDREN: {
      const { children } = state
      return { ...state, children: uniqBy([...children, ...action.children], 'fkey') }
    }

    case PostStateType.BULK_REMOVE_CHILDREN: {
      const { children } = state
      return {
        ...state,
        children: children.filter((child) => !action.fkeys.includes(getFkeyFromObject(child))),
      }
    }

    case PostStateType.UPDATE: {
      const updateLens = R.lensProp(action.name)

      return R.set(updateLens, action.value, state)
    }

    case PostStateType.UPDATE_UPLOADED_MEDIA: {
      const { uploadedMedia } = state

      return { ...state, uploadedMedia: [...uploadedMedia, action.uploadedMedia] }
    }

    case PostStateType.REMOVE_UPLOADED_MEDIA: {
      const { uploadedMedia } = state

      return { ...state, uploadedMedia: remove('id', action.id, uploadedMedia) }
    }

    case PostStateType.REMOVE_MEDIA: {
      const { media } = state

      return { ...state, media: remove('id', action.id, media) }
    }

    case PostStateType.UPDATE_CHILD_RANKING: {
      const { childRankings = {} } = state

      const currentRanking = childRankings[action.childRankingId] || {}
      const updatedRankings = Object.assign(currentRanking, { [action.key]: action.value })

      return {
        ...state,
        childRankings: { ...childRankings, [action.childRankingId]: updatedRankings },
      }
    }
    case PostStateType.SELECT_LEARNING_CONTENT: {
      const { learningContentAssets } = state

      if (!state.canAddLearningContentAssets) {
        return state
      }

      return {
        ...state,
        learningContentAssets: [...learningContentAssets, action.learningContentAsset],
      }
    }

    case PostStateType.REMOVE_LEARNING_CONTENT: {
      const { learningContentAssets } = state

      return {
        ...state,
        learningContentAssets: remove<Playground.LearningContentAsset[]>(
          'id',
          action.learningContentAsset.id,
          learningContentAssets
        ),
      }
    }

    default: {
      return state
    }
  }
}

// Calculated fields based on state
//  canAddLearningContentAssets: boolean to determine if new assets can be added
const calculateDerivedState = (state: PostState): PostState => {
  const canAddLearningContentAssets =
    state.learningContentAssets.length < MAX_LEARNING_CONTENT_ASSETS_PER_OBSERVATION
  return { ...state, canAddLearningContentAssets }
}

const derivedStateReducer = (state: PostState, action: Action): PostState =>
  calculateDerivedState(reducer(state, action))

const toggleReducer = (state: PostState, action: ToggleAction): PostState => {
  switch (action.type) {
    case PostStateToggleType.TOGGLE_CHILD: {
      if (state.children.findIndex((c) => getFkeyFromObject(c) === getFkeyFromObject(action.child)) > -1) {
        return derivedStateReducer(state, {
          type: PostStateType.REMOVE_CHILD,
          fkey: getFkeyFromObject(action.child),
        })
      }
      return derivedStateReducer(state, { type: PostStateType.SELECT_CHILD, child: action.child })
    }
    case PostStateToggleType.TOGGLE_LEARNING_OUTCOME: {
      const type =
        state.learningOutcomes.findIndex((lo) => lo.id === action.learningOutcome.id) > -1
          ? PostStateType.REMOVE_LEARNING_OUTCOME
          : PostStateType.SELECT_LEARNING_OUTCOME
      return derivedStateReducer(state, { type, learningOutcome: action.learningOutcome })
    }
    case PostStateToggleType.TOGGLE_LEARNING_CONTENT: {
      const type =
        state.learningContentAssets.findIndex((lo) => lo.id === action.learningContentAsset.id) > -1
          ? PostStateType.REMOVE_LEARNING_CONTENT
          : PostStateType.SELECT_LEARNING_CONTENT
      return derivedStateReducer(state, { type, learningContentAsset: action.learningContentAsset })
    }
    default: {
      return derivedStateReducer(state, action)
    }
  }
}

export const usePostEditor = (initState: PostState): PostContextProps => {
  const [state, dispatch] = useReducer(toggleReducer, initState, calculateDerivedState)
  useAutosave<PostState>('obs', state.id, state)

  const toggleLearningOutcome = (learningOutcome: Playground.LearningFrameworkOutcome) => {
    dispatch({ type: PostStateToggleType.TOGGLE_LEARNING_OUTCOME, learningOutcome })
  }

  const toggleChild = (child: Playground.SimpleChild) => {
    dispatch({ type: PostStateToggleType.TOGGLE_CHILD, child })
  }

  const toggleLearningContent = (learningContentAsset: Playground.LearningContentAsset) => {
    dispatch({ type: PostStateToggleType.TOGGLE_LEARNING_CONTENT, learningContentAsset })
  }

  const onChange = ({ name, value }: UpdateProps) => {
    return dispatch({ name, value, type: PostStateType.UPDATE })
  }

  const uploadedMedia = (uploadedMedia: Playground.MediaItem) => {
    return dispatch({ uploadedMedia, type: PostStateType.UPDATE_UPLOADED_MEDIA })
  }

  const removeUploadedMedia = ({ id }: { id: number }) => {
    return dispatch({ id, type: PostStateType.REMOVE_UPLOADED_MEDIA })
  }

  const removeMedia = ({ id }: { id: number }) => {
    return dispatch({ id, type: PostStateType.REMOVE_MEDIA })
  }

  const bulkSelectChildren = (children: Playground.SimpleChild[]) => {
    dispatch({ type: PostStateType.BULK_SELECT_CHILDREN, children })
  }

  const bulkRemoveChildren = (fkeys: string[]) => {
    dispatch({ type: PostStateType.BULK_REMOVE_CHILDREN, fkeys })
  }

  const rankingChange = ({
    childRankingId,
    type,
    value,
  }: {
    childRankingId: string
    type: string
    value: ChildRanking
  }) => {
    return dispatch({
      childRankingId,
      key: type,
      value,
      type: PostStateType.UPDATE_CHILD_RANKING,
    })
  }

  return {
    state,
    handlers: {
      bulkSelectChildren,
      bulkRemoveChildren,
      onChange,
      uploadedMedia,
      removeUploadedMedia,
      removeMedia,
      rankingChange,
      toggleLearningOutcome,
      toggleChild,
      toggleLearningContent,
    },
  }
}
