import React from 'react'

import styled from 'styled-components'
import {
  BorderProps,
  BorderRadiusProps,
  ColorProps,
  HeightProps,
  SpaceProps,
  WidthProps,
  border,
  borderRadius,
  color,
  space,
  width,
} from 'styled-system'

type Props = BorderProps &
  BorderRadiusProps &
  ColorProps &
  SpaceProps &
  WidthProps &
  HeightProps & { isColorful?: boolean; withShimmer?: boolean }

const StencilLine = styled.div<Props>`
  height: ${(props) => props.height || '8px'};
  background-color: rgb(225, 225, 225);

  /* Enhanced gradient animation */
  background-image: linear-gradient(
    90deg,
    ${(props) =>
      props.isColorful
        ? '#86b4ff, #c7aae9, #e99ca4, #e99ca4, #c7aae9, #86b4ff'
        : '#e1e1e1e1 40%, #fafafa 50%, #e1e1e1e1 60%'}
  );
  background-size: 400% 100%;
  animation: ${(props) => (props.withShimmer ? 'shimmer 1.6s infinite linear' : 'none')};

  @keyframes shimmer {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }

  ${color};
  ${space};
  ${width};
  ${border};
  ${borderRadius};
`

StencilLine.defaultProps = {
  borderRadius: '32px',
}

StencilLine.displayName = 'StencilLine'

export default React.memo(StencilLine)
