import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { createPopper, Modifier, Placement } from '@popperjs/core'
import ReactDOM from 'react-dom'
import { Absolute, Box } from 'stardust'
import styled from 'styled-components'

import useClickedOutside from '~/hooks/useClickedOutside'
import { BREAKPOINTS, LAYERS } from '~/theme'

const StyledPolygon = styled.polygon`
  fill: ${(props) => props.theme.colors.surfacePrimary};
`

const PointSouth = () => (
  <svg width="32" height="16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <StyledPolygon points="0,0, 32,0 16,16" />
  </svg>
)

const PointWest = () => (
  <svg width="16" height="32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <StyledPolygon points="0,16, 16,0 16,32" />
  </svg>
)

const PointEast = () => (
  <svg width="16" height="32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <StyledPolygon points="0,0, 16,16 0,32" />
  </svg>
)

const PointNorth = () => (
  <svg width="32" height="16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <StyledPolygon points="32,16 16,0 0,16" />
  </svg>
)

const Pointer = ({ placement }: { placement: string }) => {
  if (placement.startsWith('top')) return <PointSouth />
  if (placement.startsWith('right')) return <PointWest />
  if (placement.startsWith('left')) return <PointEast />
  return <PointNorth />
}

const pointerOffset = ({ placement, showArrow }: { placement: string; showArrow: boolean }) => {
  if (!showArrow) return undefined
  if (placement.startsWith('left')) return 'margin-right: 16px'
  if (placement.startsWith('right')) return 'margin-left: 16px'
  if (placement.startsWith('top')) return 'margin-bottom: 16px'
  return 'margin-top: 16px'
}

const Container = styled(Box)<{ show: boolean }>`
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
  opacity: ${(props) => (props.show ? 1 : 0)};
  position: fixed;
  pointer-events: ${(props) => (props.show ? 'auto' : 'none')};
  transition: opacity 0.2s;
  &[data-popper-reference-hidden] {
    @media (min-width: ${BREAKPOINTS.md}px) {
      visibility: hidden;
    }
  }
`

const Content = styled(Box)<{ placement: string; showArrow: boolean }>`
  position: relative;
  ${pointerOffset};
`

interface Props {
  anchor: React.RefObject<HTMLDivElement>
  children: ReactNode
  placement: Placement
  portal?: boolean
  show: boolean
  showArrow?: boolean
  onToggle(show: boolean): void
  targetCenter?: boolean
}

const Popover = ({
  anchor,
  children,
  placement: initialPlacement,
  portal = false,
  show,
  showArrow = true,
  onToggle,
  targetCenter,
}: Props) => {
  const arrowRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const [placement, setPlacement] = useState(initialPlacement)

  const onFirstUpdate = useCallback((popperData) => {
    setPlacement(popperData.placement)
    return popperData
  }, [])

  useClickedOutside(() => show && onToggle(false), anchor, containerRef)

  useEffect(() => {
    if (anchor.current && containerRef.current) {
      const horizontalAdjustment = window.innerWidth / 2 < containerRef.current.clientWidth ? 200 : 0
      const verticalOffset = 0
      const horizontalOffset = targetCenter ? -anchor.current.clientWidth / 2 - horizontalAdjustment : 0
      const modifiers: Array<Partial<Modifier<any, any>>> = [
        {
          name: 'offset',
          options: {
            offset: [verticalOffset, horizontalOffset],
          },
        },
      ]

      if (showArrow && arrowRef.current) {
        modifiers.push({
          name: 'arrow',
          options: {
            element: arrowRef.current,
          },
        })
      }

      const popper = createPopper(anchor.current, containerRef.current, {
        placement,
        modifiers,
        onFirstUpdate,
      })

      return () => popper.destroy()
    }
  }, [anchor, placement, showArrow, onFirstUpdate, targetCenter])

  const popoverContent = (
    <Container ref={containerRef} show={show} zIndex={LAYERS.Popover}>
      <Absolute
        ref={arrowRef}
        left={placement.startsWith('right') ? 0 : undefined}
        right={placement.startsWith('left') ? 0 : undefined}>
        {showArrow && <Pointer placement={placement} />}
      </Absolute>
      <Content
        bg="surfacePrimary"
        borderRadius={1}
        placement={placement}
        showArrow={showArrow}
        zIndex={LAYERS.Popover}>
        {children}
      </Content>
    </Container>
  )

  return portal ? ReactDOM.createPortal(popoverContent, document.getElementById('root')!) : popoverContent
}

Popover.displayName = 'Popover'

export default React.memo(Popover)
