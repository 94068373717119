import React from 'react'

import { Box, Flex, Image, Link, Text, themeMixin } from 'stardust'
import styled from 'styled-components'

import homeIcon from '~/assets/images/home.svg'
import playgroundIcon from '~/assets/images/playground-logo.svg'
import { isHome } from '~/config'
import { HEADER_HEIGHT } from '~/theme'

const ThemedFlex = styled(Flex)`
  ${themeMixin};
`

const Logo = () => (
  <ThemedFlex
    screenOnly
    flex="0 1 62px"
    minHeight={HEADER_HEIGHT}
    px="20px"
    alignItems="center"
    justifyContent="flex-start"
    borderBottom={1}
    color="textPrimary">
    <Link to="/" data-test="root-link" data-tour="greeting">
      <Flex alignItems="center" justifyContent="start">
        <Box width="24px" mx={2}>
          <Image src={isHome ? homeIcon : playgroundIcon} alt="" />
        </Box>
        <Box>
          <Text.span bold lower fontSize={3} color="textPrimary" lineHeight="25px">
            {isHome ? 'home.' : 'playground.'}
          </Text.span>
        </Box>
      </Flex>
    </Link>
  </ThemedFlex>
)

Logo.displayName = 'Logo'

export default Logo
