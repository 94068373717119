import React, { ReactNode, forwardRef } from 'react'

import ReactModal from 'react-modal'
import { Box, Flex } from 'stardust'
import styled from 'styled-components'

import ModalButton from '~/components/ModalButton'
import { LAYERS } from '~/theme'

const customStyles = (props: { [x: string]: any }) => ({
  content: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: 'none',
    bottom: 'auto',
    left: '50%',
    marginRight: '-50%',
    overflow: 'unset',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: props.width,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    zIndex: props.zIndex || LAYERS.Modal,
  },
})

const ModalBody = styled(Box)<{ borderRadius: string }>`
  border-radius: ${({ borderRadius }) => borderRadius};
  box-sizing: border-box;
  max-height: calc(100vh - 40px);
  min-width: 200px;
  overflow: auto;
`

ReactModal.setAppElement('#modal-root')

export interface ModalProps {
  backgroundColor?: string
  children: ReactNode
  open: boolean
  dontRenderCloseButton?: boolean
  renderHeader?(): ReactNode
  onClose(): void
  borderRadius?: string
  zIndex?: number
}

const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      open,
      onClose,
      renderHeader,
      dontRenderCloseButton,
      children,
      backgroundColor = 'surfacePrimary',
      borderRadius = '8px',
      ...props
    },
    ref
  ) => (
    <ReactModal
      bodyOpenClassName="ReactModal__Body--open"
      isOpen={open}
      onRequestClose={onClose}
      style={customStyles(props)}>
      <Flex
        alignItems="center"
        width={1}
        mb={!dontRenderCloseButton || renderHeader ? 2 : 0}
        data-test="modal-header">
        {dontRenderCloseButton ? null : <ModalButton icon="removeCircle" text="Close" onClick={onClose} />}
        {renderHeader && (
          <Flex justifyContent="flex-end" alignItems="center" width={1}>
            {renderHeader()}
          </Flex>
        )}
      </Flex>
      <ModalBody ref={ref} data-test="modal-body" bg={backgroundColor} borderRadius={borderRadius}>
        {children}
      </ModalBody>
    </ReactModal>
  )
)

Modal.displayName = 'Modal'

export default React.memo(Modal)
