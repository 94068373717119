import React from 'react'

import { Flex } from 'stardust'

import Carousel from '~/components/Carousel'
import MediaPlayer from '~/components/MediaPlayer'

interface ModalMediaProps {
  height?: string | string[]
  mediaItems: Playground.MediaItem[]
  title?: string
}

const MediaRenderer = ({ height = 'auto', mediaItems, title }: ModalMediaProps) => {
  return mediaItems.length > 1 ? (
    <Carousel>
      {mediaItems.map((mediaItem) => (
        <Flex key={mediaItem.id} alignItems="center" height="100%" justifyContent="center" width="100%">
          <MediaPlayer {...mediaItem} height={height} title={title} />
        </Flex>
      ))}
    </Carousel>
  ) : (
    <Flex alignItems="center" height="100%" justifyContent="center" width="100%">
      <MediaPlayer {...mediaItems[0]} height={height} title={title} />
    </Flex>
  )
}

MediaRenderer.displayName = 'MediaRenderer'

export default React.memo(MediaRenderer)
