import React, { useCallback, useEffect, useRef, useState } from 'react'

import { FetchResult, useMutation } from '@apollo/client'

import { Box, Text } from 'stardust'
import styled from 'styled-components'

import CommentInput from '~/components/CommentInput'
import Modal from '~/components/Modal'
import { useSelectedServiceName } from '~/contexts/Service'
import { AI_POST_CONTENT } from '~/pages/Observations/mutations'
import colors from '~/ui-components/tokens/colors'
import { generateUuid } from '~/utils'
import simulateFetch from '~/utils/simulateFetch'

import ActionButtons from './ActionButtons'
import Conversation from './Conversation'
import Header from './Header'

interface Props {
  postTitle: string
  postDescription: string
  assistAction: Playground.AssistActions
  closeAssistModal: () => void
}

interface Question {
  id: number
  question: string
  answer: string
  isCurrent: boolean
  jsonKey: string
  isOptional: boolean
}

export enum AssistActions {
  GENERATE = 'GENERATE',
  TRANSLATE = 'TRANSLATE',
  SPELLING = 'SPELLING',
  TRIM = 'TRIM',
  TONE = 'TONE',
  EXPAND = 'EXPAND',
}

const MODAL_HEADER = '40px'
const MODAL_FOOTER = '117px'
const MODAL_INTARNAL_SPACE = '80px'
const MODAL_MAX_HEIGHT = '700px'
const MODAL_OUTER_SPACE = '40px'
const MODAL_CONVERSATION_MAX_HEIGHT = `calc(${MODAL_MAX_HEIGHT} - ${MODAL_HEADER} - ${MODAL_FOOTER} - ${MODAL_INTARNAL_SPACE})`
const MODAL_CONVERSATION_MIN_HEIGHT = (viewportHeight: string) =>
  `calc(${viewportHeight} - ${MODAL_HEADER} - ${MODAL_FOOTER} - ${MODAL_INTARNAL_SPACE} - ${MODAL_OUTER_SPACE})`
const VIEWPORT_HEIGHT = '100vh'
const VIEWPORT_HEIGHT_BREAKPOINT = '730px'
const MODAL_HEIGHT = (viewportHeight: string) =>
  `min(${MODAL_CONVERSATION_MAX_HEIGHT}, ${MODAL_CONVERSATION_MIN_HEIGHT(viewportHeight)})`

const OuterBox = styled(Box)`
  width: 1290px;
  max-width: min(-40px + 100vw, -80px + 200vh);
  padding: 20px;
  border-radius: 16px;
`

const FloatingCommentInputBox = styled(Box)`
  position: absolute;
  bottom: 0;
  width: 100%;
`

const AssistModal = ({ postTitle, postDescription, assistAction, closeAssistModal }: Props) => {
  const firstRenderRef = useRef(true)
  const serviceName = useSelectedServiceName()
  const [isFetching, setIsFetching] = useState(false)
  const [messages, setMessages] = useState<Playground.AssistantConversationMessage[]>([])
  const [disableActions, setDisableActions] = useState({
    inputBox: false,
    actionButtons: true,
  })
  const questionnaire = useRef<Question[]>([])
  const [aiPostContent] = useMutation(AI_POST_CONTENT)

  const createMessageObject = useCallback(
    ({
      boldText,
      regularText,
      sender,
      withTextReveal,
    }: {
      boldText: Playground.AssistantConversationMessage['content']['boldText']
      regularText: Playground.AssistantConversationMessage['content']['regularText']
      sender: Playground.AssistantConversationMessage['sender']
      withTextReveal: Playground.AssistantConversationMessage['withTextReveal']
    }) => {
      const id = generateUuid()
      const timestamp = new Date().toISOString()
      setMessages((previousMessages) => [
        ...previousMessages,
        { id, sender, content: { boldText, regularText }, timestamp, withTextReveal },
      ])
    },
    []
  )

  const getAIResponse = useCallback(async (): Promise<FetchResult> => {
    setIsFetching(true)
    setDisableActions((prev) => ({ ...prev, inputBox: true }))
    try {
      const result = await aiPostContent({
        variables: {
          action: assistAction,
          service: serviceName,
          content: postDescription,
          story:
            assistAction === AssistActions.GENERATE
              ? questionnaire.current.reduce((acc, q) => ({ ...acc, [q.jsonKey]: q.answer }), {})
              : {},
          tone: assistAction === AssistActions.TONE ? questionnaire.current[0].answer : '',
        },
      })

      setIsFetching(false)
      let boldText = ''
      switch (assistAction) {
        case AssistActions.SPELLING:
          boldText = 'Improved: '
          break
        case AssistActions.EXPAND:
          boldText = 'Expanded: '
          break
        case AssistActions.TRIM:
          boldText = 'Trimmed: '
          break
        case AssistActions.TRANSLATE:
          boldText = 'English: '
          break
        case AssistActions.GENERATE:
          boldText = 'Learning story: '
          break
        case AssistActions.TONE:
          boldText = 'Changed tone: '
          break
        default:
          break
      }
      createMessageObject({
        boldText,
        regularText: (
          <span
            dangerouslySetInnerHTML={{
              __html: result.data?.generateAiContent.body?.replace(/\\n/g, '<br />'),
            }}
          />
        ),
        sender: 'AI',
        withTextReveal: true,
      })
      return result
    } catch (error) {
      setIsFetching(false)
      console.error('Error in getAIResponse', error)
      createMessageObject({
        boldText: '',
        regularText: 'Sorry, I am unable to process your request at the moment. Please try again.',
        sender: 'AI',
        withTextReveal: true,
      })
      return Promise.resolve({ data: { content: '' } }) // this is sent to input box
    } finally {
      setDisableActions((prev) => ({ ...prev, actionButtons: false }))
    }
  }, [aiPostContent, assistAction, createMessageObject, postDescription, questionnaire, serviceName])

  const handleAnswer = useCallback(
    async (answer: string) => {
      const nextQuestionIndex = questionnaire.current.findIndex((item) => item.isCurrent) + 1
      questionnaire.current = questionnaire.current.map((item, index) => {
        if (item.isCurrent) return { ...item, answer, isCurrent: false }
        if (index === nextQuestionIndex) return { ...item, isCurrent: true }
        return item
      })
      // If there is a next question, ask it. Otherwise, get AI response
      if (questionnaire.current.length > nextQuestionIndex) {
        setIsFetching(true)
        const result = await simulateFetch(Math.random())
        createMessageObject({
          boldText: '',
          regularText: questionnaire.current[nextQuestionIndex].question,
          sender: 'AI',
          withTextReveal: true,
        })
        setIsFetching(false)
        return result
      } else {
        return await getAIResponse()
      }
    },
    [createMessageObject, getAIResponse, questionnaire]
  )

  const handleUserInput = useCallback(
    async (value: string): Promise<FetchResult> => {
      createMessageObject({ boldText: '', regularText: value, sender: 'USER', withTextReveal: false })
      return await handleAnswer(value)
    },
    [createMessageObject, handleAnswer]
  )

  const onInsert = () => {}

  const initialMessage = useCallback(() => {
    const { sender, regularText, boldText, initialQuestionnaire } = createInitialMessageData(
      postDescription,
      assistAction
    )
    createMessageObject({ boldText, regularText, sender, withTextReveal: false })

    if (initialQuestionnaire.length) {
      questionnaire.current = initialQuestionnaire
      createMessageObject({
        boldText: questionnaire.current[0].question,
        regularText: '',
        sender: 'AI',
        withTextReveal: true,
      })
    } else {
      getAIResponse()
    }
  }, [postDescription, assistAction, createMessageObject, getAIResponse])

  // Initialize the conversation
  useEffect(() => {
    if (!firstRenderRef.current) return
    firstRenderRef.current = false
    initialMessage()

    return () => {
      firstRenderRef.current = true
    }
  }, [initialMessage])

  return (
    <Modal open onClose={closeAssistModal} dontRenderCloseButton>
      <OuterBox bg="surfacePrimary" borderRadius="16px" height="max-content" minWidth="0">
        <Header closeAssistModal={closeAssistModal} />
        <Box mt="12px">
          <Conversation
            messages={messages}
            isFetching={isFetching}
            minHeight={MODAL_HEIGHT(VIEWPORT_HEIGHT)}
            maxHeight={`max(${MODAL_HEIGHT(VIEWPORT_HEIGHT_BREAKPOINT)}, ${MODAL_HEIGHT(VIEWPORT_HEIGHT)})`}
          />
        </Box>
        <Text fontSize="14px" lineHeight="21px" mt={1} ml={2} color={colors.cosmicShade11}>
          AI responses can be inaccurate
        </Text>
        <Box mt="12px" position="relative" height="48px" width="100%">
          <FloatingCommentInputBox>
            <CommentInput
              placeholder="Enter text here"
              addComment={handleUserInput}
              autoFocus
              inlineV3
              disableInput={disableActions.inputBox}
            />
          </FloatingCommentInputBox>
        </Box>
        <Box mt="12px">
          <ActionButtons
            onTryAgain={getAIResponse}
            onInsert={onInsert}
            disabled={disableActions.actionButtons}
          />
        </Box>
      </OuterBox>
    </Modal>
  )
}

AssistModal.displayName = 'AssistModal'
export default React.memo(AssistModal)

const createInitialMessageData = (postDescription: string, assistAction: Playground.AssistActions) => {
  let sender: Playground.AssistantConversationMessage['sender'] = 'USER'
  let boldText: Playground.AssistantConversationMessage['content']['boldText'] = ''
  let regularText: Playground.AssistantConversationMessage['content']['regularText'] = ''
  let initialQuestionnaire: Question[] = []

  switch (assistAction) {
    case AssistActions.SPELLING:
      boldText = 'Imrove Spelling and Grammar: '
      regularText = `“${postDescription}”`
      break
    case AssistActions.GENERATE:
      boldText =
        'Welcome to your Learning Assistant. I am here to help you organise your observations into Posts which you can save as documentation. What sort of Post would you like to create? I can help you with jottings, learning stories, diary entry, and reflections.'
      sender = 'AI'
      initialQuestionnaire = generateLearning
      break
    case AssistActions.EXPAND:
      boldText = 'Expand upon: '
      regularText = `“${postDescription}”`
      break
    case AssistActions.TRIM:
      boldText = 'Trim Content: '
      regularText = `“${postDescription}”`
      break
    case AssistActions.TONE:
      boldText = 'Change Tone: '
      regularText = `“${postDescription}”`
      initialQuestionnaire = changeTone
      break
    case AssistActions.TRANSLATE:
      boldText = 'TRANSLATE: '
      regularText = `“${postDescription}”`
      break
    default:
      regularText = 'Unknown assist option. Please select a valid option to get started.'
      sender = 'AI'
      break
  }

  return {
    sender,
    regularText,
    boldText,
    initialQuestionnaire,
  }
}

const generateLearning: Question[] = [
  {
    id: 1,
    question:
      'Story - Set the scene; when and where; what led up to these events? What did the child(ren) do during this experience, and what stood out to you as an educator?',
    answer: '',
    isCurrent: true,
    jsonKey: 'scene',
    isOptional: false,
  },
  {
    id: 2,
    question:
      'Child’s experience - How did the child(ren) respond to the experience, and what learning or development did you notice?',
    answer: '',
    isCurrent: false,
    jsonKey: 'childExperience',
    isOptional: false,
  },
  {
    id: 3,
    question:
      "Educational intention - How did you support and extend the child(ren)'s learning during this experience?",
    answer: '',
    isCurrent: false,
    jsonKey: 'educationalIntention',
    isOptional: false,
  },
  {
    id: 4,
    question:
      'Future learning - How might this experience influence future learning opportunities for the child(ren)?',
    answer: '',
    isCurrent: false,
    jsonKey: 'futureLearning',
    isOptional: false,
  },
]

const changeTone: Question[] = [
  {
    id: 1,
    question: 'How would you like to change the tone? e.g. more professional, playful, exciting...',
    answer: '',
    isCurrent: true,
    jsonKey: 'tone',
    isOptional: false,
  },
]
