import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { Box, Flex } from 'stardust'
import styled from 'styled-components'

import { LAYERS } from '~/theme'

import { CELL_MIN_HEIGHT, CELL_MIN_WIDTH } from '../constants'
import { TableColumn, TablePointer, TableRow } from '../types'

interface CellProps {
  active: boolean
  height: number | string
  theme: any
  width: number | string
}

const Cell = styled.div.attrs<CellProps>((props) => ({
  style: {
    border: `1px solid ${props.active ? props.theme.colors.primary : props.theme.colors.cosmicShade6}`,
    maxWidth: props.width,
    minHeight: props.height,
    minWidth: props.width,
    width: props.width,
  },
}))<CellProps>`
  display: flex;
  position: relative;
`

const InsertAction = styled(Box)`
  border: dashed 1px black;
  cursor: pointer;
  opacity: 0.6;
  position: absolute;

  &:before {
    content: '+';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`

const AddRowAction = styled(InsertAction)`
  bottom: -75px;
  left: 0;
  height: 75px;
  right: 0;
`
const AddColumnAction = styled(InsertAction)`
  bottom: 0;
  right: -75px;
  top: 0;
  width: 75px;
`

interface ResizeProps {
  delta: Nullable<number>
}

const getResizeStyle = (props: ResizeProps, translate: string) => {
  return props.delta
    ? {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        transform: `${translate}(${props.delta}px)`,
      }
    : {}
}

const HeightResizeHandle = styled.div.attrs<ResizeProps>((props) => ({
  style: getResizeStyle(props, 'translateY'),
}))<ResizeProps>`
  bottom: 0;
  cursor: ns-resize;
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: ${LAYERS.Tooltip};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

const WidthResizeHandle = styled.div.attrs<ResizeProps>((props) => ({
  style: getResizeStyle(props, 'translateX'),
}))<ResizeProps>`
  bottom: 0;
  cursor: ew-resize;
  position: absolute;
  right: 0;
  top: 0;
  width: 6px;
  z-index: ${LAYERS.Tooltip};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

const getRowHeight = (row: TableRow) => (row.height ? row.height : row.computedHeight)

export interface CellRendererProps {
  active: boolean
  column: TableColumn
  row: TableRow
}

interface Point {
  x: number
  y: number
}

interface Props {
  columns: TableColumn[]
  pointer?: Nullable<TablePointer>
  rows: TableRow[]
  cellRenderer({ active, column, row }: CellRendererProps): ReactNode
  onColumnResize?(columnId: string, width: number): void
  onInsertColumn?(): void
  onInsertRow?(): void
  onRowHeightChange?(rowId: string, height: number): void
  onRowResize?(rowId: string, height: number): void
}

interface ResizerState {
  column: Nullable<TableColumn>
  origin: Point
  row: Nullable<TableRow>
  transform: Point
}

const Table = ({
  columns,
  rows,
  pointer,
  cellRenderer,
  onColumnResize,
  onInsertColumn,
  onInsertRow,
  onRowHeightChange,
  onRowResize,
}: Props) => {
  const resizeFinishRef = useRef<any>(null)
  const resizeMoveRef = useRef<any>(null)

  const [resizer, setResizer] = useState<ResizerState>({
    column: null,
    row: null,
    origin: { x: 0, y: 0 },
    transform: { x: 0, y: 0 },
  })

  const resizeDeltaX = resizer.transform.x - resizer.origin.x
  const resizeDeltaY = resizer.transform.y - resizer.origin.y

  const onResizeFinish = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()

      document.removeEventListener('mousemove', resizeMoveRef.current)
      document.removeEventListener('mouseup', resizeFinishRef.current)

      if (resizer.column && onColumnResize) {
        const deltaWidth = resizer.column.width + event.pageX - resizer.origin.x
        onColumnResize(resizer.column.id, Math.max(deltaWidth, CELL_MIN_WIDTH))
      }

      if (resizer.row && onRowResize) {
        const deltaHeight = getRowHeight(resizer.row) + event.pageY - resizer.origin.y
        onRowResize(resizer.row.id, Math.max(deltaHeight, CELL_MIN_HEIGHT))
      }

      setResizer({
        column: null,
        row: null,
        origin: { x: 0, y: 0 },
        transform: { x: 0, y: 0 },
      })
    },
    [resizer.column, resizer.origin, resizer.row, onColumnResize, onRowResize]
  )

  const onResizeMove = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()

      // Sometimes we can get a mousemove event without a mouseup event,
      // I think caused by dragging. This check makes sure a button is
      // being pressed, and if not stop the resize by clearing targets
      if (event.buttons === 0) {
        onResizeFinish(event)
      }

      setResizer((state) => ({ ...state, transform: { x: event.pageX, y: event.pageY } }))
    },
    [onResizeFinish]
  )

  // Hack to get around the fact that our function references will change
  // on each render. If we're starting a resize:
  //
  // 1. Remove any existing resize handlers
  // 2. Assign the value we used to the ref
  // 3. Add the new function to the ref
  //
  // This way the ref will always point at the function we actually used
  useEffect(() => {
    if (resizer.column || resizer.row) {
      document.removeEventListener('mousemove', resizeMoveRef.current)
      document.removeEventListener('mouseup', resizeFinishRef.current)

      resizeMoveRef.current = onResizeMove
      resizeFinishRef.current = onResizeFinish

      document.addEventListener('mousemove', resizeMoveRef.current)
      document.addEventListener('mouseup', resizeFinishRef.current)
    }
  }, [resizer.column, resizer.row, onResizeMove, onResizeFinish])

  const renderCell = useCallback(
    (column: TableColumn, row: TableRow) => {
      const active = !!pointer && pointer.columnId === column.id && pointer.rowId === row.id

      const onColumnResizeStart = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()

        const origin = { x: event.pageX, y: event.pageY }

        setResizer((state) => ({ ...state, column, origin }))
      }

      const onRowResizeStart = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()

        const origin = { x: event.pageX, y: event.pageY }

        setResizer((state) => ({ ...state, row, origin }))
      }

      return (
        <Cell
          key={`${column.id}-${row.id}`}
          active={active}
          draggable={false}
          height={row.height || 'auto'}
          role="cell"
          width={column.width}>
          {cellRenderer({ active, column, row })}

          {onColumnResize && (
            <WidthResizeHandle
              delta={column === resizer.column ? resizeDeltaX : null}
              draggable={false}
              onMouseDown={onColumnResizeStart}
            />
          )}

          {onRowResize && (
            <HeightResizeHandle
              delta={resizer.row === row ? resizeDeltaY : null}
              draggable={false}
              onMouseDown={onRowResizeStart}
            />
          )}
        </Cell>
      )
    },
    [
      cellRenderer,
      pointer,
      resizer.column,
      resizer.row,
      resizeDeltaX,
      resizeDeltaY,
      onColumnResize,
      onRowResize,
    ]
  )

  return (
    <>
      <Flex flexDirection="column" role="table" position="relative" data-test="table">
        {rows.map((row) => {
          const onRowRender = (node: HTMLDivElement) => {
            if (!node || !onRowHeightChange) return
            if (Math.abs(node.clientHeight - row.computedHeight) > 10) {
              onRowHeightChange(row.id, node.clientHeight)
            }
          }

          return (
            <Flex key={row.id} ref={onRowRender} flexDirection="row" role="row">
              {columns.map((column) => renderCell(column, row))}
            </Flex>
          )
        })}
        {onInsertColumn && <AddColumnAction onClick={onInsertColumn} />}
        {onInsertRow && <AddRowAction onClick={onInsertRow} />}
      </Flex>
    </>
  )
}

Table.displayName = 'Table'

export default React.memo(Table)
