import React, { useRef, useState } from 'react'

import { Box, Flex, Icon } from 'stardust'

import styled from 'styled-components'

import useClickedOutside from '~/hooks/useClickedOutside'

import colors from './tokens/colors'

const HoverFlex = styled(Flex)`
  transition: background 0.25s ease;
  &:hover {
    background-color: ${colors.cosmicShade4};
  }
`

const Options = styled(Box)`
  z-index: 1;
  overflow-y: auto;
`
interface OptionProps {
  label: string
  value: any
  icon?: JSX.Element
}

interface DropdownSelectorProps {
  placeHolder?: string
  options: OptionProps[]
  DefaultSelectedOption?: OptionProps | null
  selectedOption: OptionProps | null | undefined
  onSelect: (option: OptionProps | null) => void
  renderOption: (option: OptionProps) => React.ReactNode
}

function DropdownSelector({
  options,
  placeHolder,
  selectedOption,
  onSelect,
  renderOption,
}: DropdownSelectorProps) {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  useClickedOutside(() => setIsOpen(false), dropdownRef)

  function handleOptionClick(option: { label: string; value: any }) {
    onSelect(option)
    setIsOpen(false)
  }

  return (
    <Box ref={dropdownRef} position="relative">
      <Flex
        bg="white"
        cursor="pointer"
        borderColor={colors.cosmicShade7}
        borderRadius="16px"
        border="1px solid"
        alignItems="center"
        justifyContent="space-between"
        height="48px"
        onClick={() => setIsOpen(!isOpen)}>
        <Flex alignItems="center" ml={2}>
          {selectedOption ? renderOption(selectedOption) : placeHolder}
        </Flex>
        <Icon name="chevronDown" mr={2} width={24} />
      </Flex>
      {isOpen && (
        <Options
          width="100%"
          mt={1}
          flexDirection="column"
          bg="white"
          border="2px solid"
          borderColor={colors.cosmicShade4}
          borderRadius="16px"
          maxHeight="240px"
          position="absolute">
          {options.map((option, index) => (
            <Box key={index} onClick={() => handleOptionClick(option)} alignItems="center">
              <HoverFlex height="48px" alignItems="center" cursor="pointer">
                {renderOption(option)}
              </HoverFlex>
            </Box>
          ))}
        </Options>
      )}
    </Box>
  )
}

DropdownSelector.displayName = 'DropdownSelector'

export default React.memo(DropdownSelector)
