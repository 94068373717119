import React, { useCallback, useRef, useState } from 'react'

import { Editor } from '@tinymce/tinymce-react'
import { debounce } from 'lodash'
import { Flex, Text } from 'stardust'
import styled from 'styled-components'
import { Editor as EditorClass, EditorEvent, TinyMCE } from 'tinymce'

import config from '~/config'

import colors from './tokens/colors'

const Container = styled(Flex)`
  > div.tox.tox-tinymce {
    flex-grow: 1;
    div.tox-toolbar__group {
      position: relative;
      &:not(:last-of-type)::after {
        content: '';
        position: absolute;
        height: 20px;
        width: 2px;
        background-color: ${colors.cosmicShade4};
        border-radius: 1px;
        right: -1px;
      }
    }
    .tox-editor-header {
      padding: 0px;
      box-shadow: none;
      border-bottom: 2px solid ${colors.cosmicShade4};
    }
    .tox-tbtn:focus:after,
    .tox-number-input button:focus::after,
    .tox-split-button:focus::after {
      box-shadow: 0 0 0 2px ${colors.nebulaBlue5};
    }
    .tox-tbtn--active,
    .tox-tbtn--enabled,
    .tox-tbtn--enabled:focus,
    .tox-tbtn--enabled:hover,
    .tox-tbtn:active {
      background-color: ${colors.nebulaBlue0};
    }
    .tox-number-input button:active {
      background-color: ${colors.nebulaBlue0};
    }
    .tox-edit-area::before {
      border-color: ${colors.nebulaBlue5};
      border-radius: 8px;
      body {
        caret-color: ${colors.nebulaBlue5};
      }
    }
  }

  /* border to show when TinyMCE is loading */
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 29px;
    border-radius: 8px;
    border: 2px solid ${colors.cosmicShade4};
  }
`

type EditorOptions = Parameters<TinyMCE['init']>[0]
type TinyMCEInitProps = EditorOptions &
  Partial<Record<'selector' | 'target' | 'readonly' | 'license_key', undefined>>

interface TinyMCEEditorProps {
  value?: string
  placeholder?: string
  onChange?: (plainText: string, richText: string) => void
  maxLength?: number
}

const TinyMCEEditor = ({ value, placeholder, onChange, maxLength }: TinyMCEEditorProps) => {
  const [lengthValidation, setLengthValidation] = useState<
    | undefined
    | {
        msg: string
        valid: boolean
        currentLength: number
      }
  >(undefined)
  const editorRef = useRef<EditorClass | null>(null)
  const initialValue = useRef(value)

  const getEditorCharacterCount = (): number => {
    if (!editorRef.current) return 0
    const wordcount = editorRef.current.plugins.wordcount
    return wordcount.body.getCharacterCount()
  }

  const getSelectedTextLength = (): number => {
    if (!editorRef.current) return 0
    const wordcount = editorRef.current.plugins.wordcount
    return wordcount.selection.getCharacterCount()
  }

  const validateDescriptionLength = useCallback(() => {
    if (!editorRef.current || !maxLength) return { valid: true, msg: '', currentLength: 0 }

    const editorCharacterCount = getEditorCharacterCount()
    return {
      valid: editorCharacterCount <= maxLength,
      msg: editorCharacterCount < maxLength ? `${editorCharacterCount}/${maxLength}` : '0 characters left',
      currentLength: editorCharacterCount,
    }
  }, [maxLength])

  // prevent exceding character limit
  const handleKeyPress = (event: EditorEvent<KeyboardEvent>) => {
    if (!editorRef.current || !maxLength) return
    const editorCharacterCount = getEditorCharacterCount()
    const selectedTextLength = getSelectedTextLength()

    if (
      editorCharacterCount >= maxLength &&
      event.key.length === 1 &&
      !event.ctrlKey &&
      !event.metaKey &&
      !selectedTextLength
    ) {
      event.preventDefault() // Prevent the new character from being added
    }
  }

  // handle on-change event
  const handleUpdate = debounce((richText: string, editor: EditorClass) => {
    if (!onChange) return
    const plainText = editor.getContent({ format: 'text' })
    if (maxLength) {
      const { msg, valid, currentLength } = validateDescriptionLength()
      if (currentLength > maxLength) editor.undoManager.undo() // removes the extra emojies
      if (valid || lengthValidation?.valid) onChange(plainText, richText)
      setLengthValidation({ msg, valid, currentLength })
      return
    }
    onChange(plainText, richText)
  }, 500)

  // prevent exceding character limit
  const pastePreprocess = async (editor: EditorClass, args: { content: string }) => {
    // Remove img tags
    args.content = args.content.replace(/<img[^>]*>/gi, '')

    if (!editor || !maxLength) return

    const editorCharacterCount = getEditorCharacterCount()
    const selectedTextLength = getSelectedTextLength()
    const remainingChars = maxLength - editorCharacterCount + selectedTextLength

    // If remaining chars are less than 0, do not allow any pasting
    if (remainingChars <= 0) {
      args.content = ''
      return
    }

    // If pasted content exceeds the remaining character limit, trim the content
    args.content = trimHtmlString(args.content, remainingChars)
  }

  return (
    <Container flexGrow={1} flexDirection="column">
      <Editor
        initialValue={initialValue.current}
        init={{
          ...defaultOptions,
          placeholder,
          paste_preprocess: pastePreprocess,
        }}
        onInit={(_evt, editor) => {
          editorRef.current = editor
          editor.on('keydown', handleKeyPress)
          // handle initial validation
          if (maxLength) setLengthValidation(validateDescriptionLength())
          // dispatch custom mousedown event so that it can be bubbled up outside the iframe
          const editorIFrame = document.querySelector('.tox-edit-area__iframe')
          editor.on('mousedown', (event) => {
            if (!editorIFrame) return
            const bounds = editorIFrame.getBoundingClientRect()
            const mceEditorClickEvent = new CustomEvent('mousedown', {
              bubbles: true,
              detail: {
                originalEvent: event,
                iframeCoords: bounds,
              },
            })
            editorIFrame.dispatchEvent(mceEditorClickEvent)
          })
        }}
        onEditorChange={handleUpdate}
        licenseKey={config.TINYMCE_KEY}
        tinymceScriptSrc={`${window.location.origin}/tinymce/tinymce.min.js`}
      />
      {maxLength && lengthValidation ? (
        <Flex px={2} py={1}>
          {lengthValidation.currentLength >= maxLength ? (
            <Text color={colors.cosmicShade20} fontSize="14px" fontWeight={400} lineHeight="21px">
              You have reached the maximum character limit of 15,000
            </Text>
          ) : null}
          <Text ml="auto" lineHeight="21px" fontSize="14px" color={colors.cosmicShade11}>
            {lengthValidation?.msg}
          </Text>
        </Flex>
      ) : null}
    </Container>
  )
}

TinyMCEEditor.displayName = 'TinyMCEEditor'
export default React.memo(TinyMCEEditor)

const defaultOptions: TinyMCEInitProps = {
  width: '100%',
  height: '191px', //minimum height to maintain responsiveness
  content_style: `
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
    body {
      font-size: 12pt;
      font-family: Inter, sans-serif;
    }
  `,
  font_family_formats:
    'Inter=Inter, sans-serif; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
  menubar: false,
  statusbar: true,
  elementpath: false,
  resize: false,
  images_file_types: 'jpg,svg,webp',
  block_unsupported_drop: true,
  plugins: [
    'advlist',
    // 'autolink',
    // 'checklist',
    // 'editimage',
    'emoticons',
    // 'link',
    'lists',
    // 'powerpaste',
    'wordcount',
    // 'tinymcespellchecker',
  ],
  toolbar:
    'undo redo | bold italic underline strikethrough | forecolor backcolor | fontsize fontfamily | align bullist numlist | emoticons hr wordcount', // quickimage
  quickbars_selection_toolbar: false,
  quickbars_insert_toolbar: false,
  quickbars_image_toolbar: 'alignleft aligncenter alignright',
  table_toolbar:
    'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
  contextmenu: false,
  link_context_toolbar: false,
  link_default_target: '_blank',
  editimage_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
  typography_default_lang: ['en-US'],
  spellchecker_language: 'en',
  // spellchecker_rpc_url: `${window.location.origin}/tinymce/tinymce-services/`,
  noneditable_class: 'mceNonEditable',
  forced_root_block: '',
  force_br_newlines: true,
  force_p_newlines: true,
  paste_data_images: false,
  paste_block_drop: false,
  smart_paste: false,
  branding: false,
  toolbar_mode: 'sliding',
  toolbar_sticky: true,
  toolbar_sticky_offset: 84,
  font_size_input_default_unit: 'pt',
  font_size_formats: '8pt 12pt 14pt 16pt 18pt 24pt 32pt 40pt 48pt',
}

const trimHtmlString = (htmlString: string, maxLength: number): string => {
  let remainingChars = maxLength
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')

  const textContent = doc.body.textContent || ''
  if (textContent.trim().length <= maxLength) return htmlString

  const buildNewNode = (node: ChildNode): Node | null => {
    // if no remaining characters, return null
    if (remainingChars <= 0) return null

    // Check for Text node
    if (node.nodeType === Node.TEXT_NODE && node.textContent) {
      // If the node is a newline character, skip other calculations and return a newline character
      if (node.textContent === '\n') return document.createTextNode('\n')

      // Remove newline and non-breaking space characters
      let nodeTextContent = node.textContent.replace(/\n|&nbsp;/g, '')

      // Calculate the number of invisible characters (emojis, special characters, etc.)
      const invisibleDifference = nodeTextContent.length - visibleStringLength(nodeTextContent)
      nodeTextContent =
        remainingChars >= nodeTextContent.length
          ? nodeTextContent
          : invisibleDifference
          ? trimEmojiText(nodeTextContent, remainingChars)
          : nodeTextContent.substring(0, remainingChars)

      remainingChars -= nodeTextContent.length - invisibleDifference
      return document.createTextNode(nodeTextContent)
    }

    // Check for Element node
    if (node.nodeType === Node.ELEMENT_NODE) {
      const newElement = node.nodeName === 'PRE' ? document.createElement('p') : node.cloneNode(false) // Clone just the element, not its children

      Array.from(node.childNodes).forEach((child) => {
        const childFragment = buildNewNode(child)
        if (childFragment) {
          newElement.appendChild(childFragment) // Append processed children
        }
      })
      return newElement
    }

    return null // If it's not a Text or Element node, return null
  }

  // Create a DocumentFragment to hold the new DOM structure
  const fragment = document.createDocumentFragment()

  // Process the entire body of the document and append each child node to the fragment
  Array.from(doc.body.childNodes)
    .map((child) => buildNewNode(child))
    .filter(Boolean) // Filter out null values
    .forEach((node) => node && fragment.appendChild(node)) // Append each node to the DocumentFragment

  // Convert DocumentFragment to HTMLElement
  const tempDiv = document.createElement('div')
  fragment && tempDiv.appendChild(fragment)

  return tempDiv.innerHTML
}

const visibleStringLength = (string: string) => [...string].length

const trimEmojiText = (text: string, remainingChars: number) => [...text].splice(0, remainingChars).join('')
