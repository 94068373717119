import React, { useState, useEffect } from 'react'

import { Box } from 'stardust'

import { FieldProps } from '../../types'
import { FileAttachments } from '../MediaAttachments/molecules/FileAttachments'

import UploadErrors from './UploadErrors'

interface MediaListProps extends FieldProps {
  media: Playground.MediaItem[]
  isReadOnly: boolean
  uploadedMedia: Playground.MediaItem[]
  uploadErrors: Playground.Error[]
  handleRemoveMedia: ({ id }: { id: number }) => void
  removeUploadedMedia: ({ id }: { id: number }) => void
}

const SIGNLE_ATTACHMENT_HEIGHT = 71
const ATTACHMENT_SPACING = 8
const MAX_VISIBLE_ROWS = 1.4 // .4 is added so the next row peeks a little bit
const ADJUSTMENT = 4 // for floating close icons on images
const VISIBLE_ROW_SPACING = (MAX_VISIBLE_ROWS - 1) * ATTACHMENT_SPACING + ADJUSTMENT

const MediaList = ({
  media,
  uploadedMedia,
  uploadErrors,
  handleRemoveMedia,
  removeUploadedMedia,
  onChange,
}: MediaListProps) => {
  const [displayUploadErrors, setDisplayUploadErrors] = useState(false)

  const uploadMedia = (lastUploadMedia: Playground.MediaItem) => {
    const addedMediaItems = media.concat(lastUploadMedia)

    onChange({
      name: 'media',
      value: addedMediaItems,
    })
  }

  useEffect(() => {
    const lastUploadedMedia = uploadedMedia[uploadedMedia.length - 1]
    if (!lastUploadedMedia) return

    const lastMedia = media[media.length - 1]
    if (lastMedia && lastMedia.id === lastUploadedMedia.id) return

    uploadMedia(lastUploadedMedia)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedMedia])

  const handelDetachClick = (id: number) => {
    const isIdInMedia = media.some((mediaItem) => mediaItem.id === id)
    const isIdInUploadedMedia = uploadedMedia.some((mediaItem) => mediaItem.id === id)

    if (isIdInMedia) {
      handleRemoveMedia({ id })
    }

    if (isIdInUploadedMedia) {
      removeUploadedMedia({ id })
    }
  }

  if (!media.length && !uploadErrors) {
    return null
  }

  return (
    <Box mb={media.length ? 2 : 0}>
      {uploadErrors.length > 0 && (
        <Box mb={displayUploadErrors ? 3 : 0}>
          <UploadErrors
            uploadErrors={uploadErrors}
            displayUploadErrors={displayUploadErrors}
            setDisplayUploadErrors={setDisplayUploadErrors}
          />
        </Box>
      )}
      <Box
        maxHeight={SIGNLE_ATTACHMENT_HEIGHT * MAX_VISIBLE_ROWS + VISIBLE_ROW_SPACING}
        overflowY="auto"
        overflowX="hidden">
        <FileAttachments files={media} onDetachClickCallback={handelDetachClick} forPostCreation={true} />
      </Box>
    </Box>
  )
}

MediaList.displayName = 'MediaList'
export default MediaList
