import React, { useState, useContext } from 'react'

import { useFlag } from '@unleash/proxy-client-react'

import { Box, Dropdown, Flex, Icon, Text } from 'stardust'

import styled from 'styled-components'

import { DropdownMenuItem } from '~/components/DropdownMenu'
import { FlexProps } from '~/ui-components/Flex'
import colors from '~/ui-components/tokens/colors'

import { PostContext } from '../PostProvider'

import { AssistActions } from './AssistModal'
import Media from './PostModalMedia'

const S = {
  VerticalLine: styled.div`
    border-left: 2px solid #d3d3d3;
    margin-left: 16px;
    margin-right: 16px;
  `,
  Icon: styled(Icon)`
    border-radius: 50%;
    padding: 10px;
    background-color: ${colors.cosmicShade4};
    &:hover {
      background-color: ${colors.cosmicShade6};
      transition: background-color 0.2s;
    }
  `,
  HighlightedIcon: styled(Icon)`
    border-radius: 50%;
    padding: 10px;
    background-color: ${colors.pulsarPurple16};
    &:hover {
      background-color: ${colors.pulsarPurple15};
      transition: background-color 0.2s;
    }
  `,
  StatusIcon: styled(Icon)`
    position: absolute;
    top: -12px;
    right: -5px;
  `,
  StatusForTagItems: styled(Box)`
    padding: 4px 8px 4px 8px;
    position: absolute;
    top: -12px;
    right: -1px;
  `,
}
interface Props {
  rooms?: Playground.ObservationRoom[]
  taggedChildren: Playground.SimpleChild[]
  learningOutcomes: Playground.LearningFrameworkOutcome[]
  taggedDocuments: Playground.Document[]
  isReadOnly?: boolean
  onOpenRoomModal: () => void
  onOpenChildrenModal: () => void
  onOpenLinksModal: () => void
  onOpenLearningOutcomesModal: () => void
  onOpenAssistModal: (option: Playground.AssistActions) => void
  setUploadErrors: (error: Playground.Error[]) => void
}

interface TagIconsProps extends FlexProps {
  iconName: string
  name: string
  size: number
  color?: string
  ml?: number
  mr?: number
  count?: number
  isReadOnly?: boolean
}

interface DropdownMenuItemProps {
  iconName: string
  name: string
  size: number
  color?: string
  ml?: number
  mr?: number
  handleClose: () => void
}

const ModalFooter = ({
  isReadOnly,
  rooms,
  taggedChildren,
  learningOutcomes,
  taggedDocuments,
  onOpenRoomModal,
  onOpenChildrenModal,
  onOpenLinksModal,
  onOpenLearningOutcomesModal,
  onOpenAssistModal,
  setUploadErrors,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const { state, handlers } = useContext(PostContext)
  const aiAssistFlag = useFlag('allow_ai_assist')

  const StatusTag = ({ count }: { count: number }) => (
    <S.StatusForTagItems borderRadius="16px" alignItems="center" height={22} bg={colors.pulsarPurple16}>
      <Text fontWeight={700} fontSize="14px" caps color="white">
        {count}
      </Text>
    </S.StatusForTagItems>
  )

  const TagIcons = ({ iconName, name, size, color, ml, mr, count }: TagIconsProps) => {
    const handleClick = () => {
      if (name === 'room') {
        onOpenRoomModal()
      }
      if (name === 'childrenAlt') {
        onOpenChildrenModal()
      }
      if (name === 'links') {
        onOpenLinksModal()
      }
      if (name === 'outcomesAlt') {
        onOpenLearningOutcomesModal()
      }
    }
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        ml={ml}
        mr={mr}
        position="relative"
        onClick={handleClick}>
        <S.Icon width={size} name={name} mb={1} color={color} />
        {name === 'room' && rooms && rooms[0]?.roomFkey ? (
          <S.StatusIcon width={20} height={19} name="checkCircleAlt2" />
        ) : count && count > 0 ? (
          <StatusTag count={count} />
        ) : null}
        <Text fontWeight={500} fontSize="14px" caps>
          {iconName}
        </Text>
      </Flex>
    )
  }

  const PopupButton = ({ iconName, name, size, color, ml, mr, handleClose }: DropdownMenuItemProps) => {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        ml={ml}
        mr={mr}
        position="relative"
        onClick={handleClose}
        data-test={`${iconName.replace(' ', '-').toLowerCase()}-popup-button`}>
        <S.HighlightedIcon width={size} name={name} mb={1} color={color} />
        <Text fontWeight={500} fontSize="14px" caps>
          {iconName}
        </Text>
      </Flex>
    )
  }

  const items: {
    label: string
    onClick: (option: Playground.AssistActions) => void
    key: Playground.AssistActions
  }[] = [
    {
      label: 'Improve spelling & grammar',
      onClick: onOpenAssistModal,
      key: AssistActions.SPELLING,
    },
    {
      label: 'Generate learning',
      onClick: onOpenAssistModal,
      key: AssistActions.GENERATE,
    },
    {
      label: 'Expand upon',
      onClick: onOpenAssistModal,
      key: AssistActions.EXPAND,
    },
    {
      label: 'Trim content',
      onClick: onOpenAssistModal,
      key: AssistActions.TRIM,
    },
    {
      label: 'Change tone',
      onClick: onOpenAssistModal,
      key: AssistActions.TONE,
    },
    {
      label: 'Translate',
      onClick: onOpenAssistModal,
      key: AssistActions.TRANSLATE,
    },
  ]

  return (
    <Box bg="surfacePrimary" borderRadius={1} borderColor="surfacePrimaryBorder" border="2px solid">
      <Flex justifyContent="space-between" alignItems="center" m={2} pt={1}>
        <Box minWidth="15%">
          <Text ml={2} fontSize={3} fontWeight={'bold'}>
            Add to post
          </Text>
        </Box>
        <Flex flexDirection="row" m={2}>
          <Box>
            <Media
              setUploadErrors={setUploadErrors}
              button={
                <Box cursor="pointer">
                  <TagIcons
                    color={colors.cosmicShade15}
                    size={42}
                    name="attachments"
                    iconName="Attachments"
                  />
                </Box>
              }
              handleUploadedMedia={handlers.uploadedMedia}
              loading={[loading, setLoading]}
              isReadOnly={isReadOnly || false}
              media={state.media}
              uploadedMedia={state.uploadedMedia}
              onChange={handlers.onChange}
            />
          </Box>
          <S.VerticalLine />
          <TagIcons size={40} name="room" iconName="room" ml={1} mr={1} color="black" />
          <TagIcons size={41} name="childrenAlt" iconName="children" ml={3} count={taggedChildren.length} />
          <TagIcons
            size={43}
            name="outcomesAlt"
            iconName="outcomes"
            ml={3}
            mt={2}
            count={learningOutcomes.length}
          />
          <TagIcons size={41} name="links" iconName="links" ml={3} count={taggedDocuments.length} />
          {aiAssistFlag ? (
            <Dropdown
              placement="top-end"
              renderButton={({ handleClose }) => (
                <PopupButton
                  size={41}
                  name="sparkles"
                  iconName="Assist"
                  ml={3}
                  color={colors.cosmicShade0}
                  handleClose={handleClose}
                />
              )}
              render={({ handleClose }) =>
                items.map((item) => {
                  const onClick = () => {
                    handleClose()
                    item.onClick(item.key)
                  }

                  return <DropdownMenuItem key={item.key} label={item.label} onClick={onClick} />
                })
              }
            />
          ) : null}
        </Flex>
      </Flex>
    </Box>
  )
}
ModalFooter.displayName = 'ModalFooter'

export default React.memo(ModalFooter)
