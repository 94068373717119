import React, { useState, useContext } from 'react'

import { Box, Divider, Flex, Text, Icon, RadioButton, Switch } from 'stardust'
import styled from 'styled-components'

import Modal from '~/components/Modal'
import StatusPill from '~/components/StatusPill'
import colors from '~/ui-components/tokens/colors'

import { PostContext } from '../PostProvider'

import SaveButtons from './SaveButtons'

interface SaveProps {
  TaggedChildren?: Playground.SimpleChild[]
  open: boolean
  isEditing: boolean
  onClose: () => void
  setIsPreviewOpen: (isOpen: boolean) => void
  onSubmit: (statusToSave: string, setLoadingFalse: () => void) => void
}

const S = {
  CloseIcon: styled(Icon)`
    border-radius: 50%;
    padding: 10px;
    background-color: ${colors.cosmicShade4};
    &:hover {
      background-color: ${colors.cosmicShade6};
      transition: background-color 0.2s;
    }
  `,
}

const Save = ({ open, TaggedChildren, isEditing, setIsPreviewOpen, onSubmit, onClose }: SaveProps) => {
  const { state, handlers } = useContext(PostContext)
  const [selectedStatusToSave, setSelectedStatusToSave] = useState(
    state.status === 'NEEDS_REVIEW' ? 'PUBLISHED' : 'DRAFT'
  )

  const [comments, setComments] = useState(true)
  const validateChildren = TaggedChildren && TaggedChildren.length > 0

  const setColorsForPublished = () => {
    if (!validateChildren && selectedStatusToSave === 'PUBLISHED') {
      return { borderColor: colors.superGiantRed5, backgroundColor: colors.cosmicShade0 }
    }
    if (validateChildren && selectedStatusToSave === 'PUBLISHED') {
      return { borderColor: colors.nebulaBlue4, backgroundColor: colors.nebulaBlue0 }
    }
    return { borderColor: colors.cosmicShade6, backgroundColor: colors.cosmicShade0 }
  }

  const handleCommentsSwitch = () => {
    const updatedComments = !comments
    setComments(updatedComments)
    handlers.onChange({ name: 'allowComments', value: updatedComments })
  }

  return (
    <Modal open={open} dontRenderCloseButton onClose={onClose}>
      <Box width="680px" height="max-content">
        <Flex p={3} flexDirection="column">
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontWeight={600} fontSize={3}>{`Save "${state.title}"`}</Text>
            <S.CloseIcon width={40} name="close" onClick={onClose} />
          </Flex>
          <Flex
            borderRadius="16px"
            flexDirection="column"
            bg={colors.cosmicShade4}
            height="60%"
            mt={2}
            mb={2}
            pt={3}
            pb={3}
            pl={2}
            pr={2}>
            {isEditing && state.status === 'DRAFT' ? (
              <Text fontSize={2} fontWeight={500} mb={3} ml={3} color={colors.cosmicShade11}>
                {"This post is currently saved as a 'Draft'."}
              </Text>
            ) : null}
            {isEditing && state.status === 'NEEDS_REVIEW' ? null : (
              <Flex
                onClick={() => setSelectedStatusToSave('DRAFT')}
                cursor="pointer"
                border="2px solid"
                borderColor={selectedStatusToSave === 'DRAFT' ? colors.nebulaBlue4 : colors.cosmicShade6}
                borderRadius="16px"
                flexDirection="column"
                bg={selectedStatusToSave === 'DRAFT' ? colors.nebulaBlue0 : colors.cosmicShade0}
                height="60%"
                p={3}>
                <Flex mb={3} justifyContent="space-between" alignItems="flex-end">
                  <StatusPill status="DRAFT" fontSize="14px" />
                  <Box mr={2}>
                    <RadioButton
                      checked={selectedStatusToSave === 'DRAFT'}
                      onChange={() => setSelectedStatusToSave('DRAFT')}
                    />
                  </Box>
                </Flex>
                <Flex>
                  <Text fontSize="14px" mr={1}>
                    Only seen within the service.
                  </Text>
                  <Text fontSize="14px" fontWeight={500}>
                    Not visible to families.
                  </Text>
                </Flex>
              </Flex>
            )}
            <Flex
              onClick={() => setSelectedStatusToSave('PUBLISHED')}
              cursor="pointer"
              border="2px solid"
              borderColor={setColorsForPublished().borderColor}
              borderRadius="16px"
              flexDirection="column"
              bg={setColorsForPublished().backgroundColor}
              height="60%"
              mt={2}
              p={3}>
              <Flex mb={3} justifyContent="space-between" alignItems="flex-end">
                <StatusPill status="PUBLISH" fontSize="14px" />
                <Box mr={2}>
                  <RadioButton
                    checked={selectedStatusToSave === 'PUBLISHED'}
                    onChange={() => setSelectedStatusToSave('PUBLISHED')}
                  />
                </Box>
              </Flex>
              <Text fontSize="14px" mb={2}>
                {"Share your post with families to keep them updated on children's learning."}
              </Text>
              <Divider borderColor={colors.cosmicShade6} mt={1} />
              <Flex mt={3} alignItems="center">
                <Icon name="commentsAlt3" mr={2} />
                <Text color="primary" fontWeight={700} fontSize="14px">
                  Comments
                </Text>
                <Box ml="auto" mr={1}>
                  <Flex>
                    <Text mr={3} fontWeight="bold" fontSize="14px">
                      {state.allowComments ? 'On' : 'Off'}
                    </Text>
                    <Switch
                      disabled={state.status === 'UNSHARED_CHANGES' ? true : false}
                      checked={state.allowComments}
                      onChange={handleCommentsSwitch}
                    />
                  </Flex>
                </Box>
              </Flex>
              <Text mt={3} fontSize="14px">
                Allow or disallow feedback on Posts by turning on or off comments.
              </Text>
              <Flex alignItems="baseline">
                <Text mt={3} fontSize="14px" fontWeight="bold" mr={1}>
                  {'Note'}
                </Text>
                <Text>{'-'}</Text>
                <Text mt={3} fontSize="14px" color="grey" ml={1}>
                  Comments cannot be turned on or off after the Post is shared with families.
                </Text>
              </Flex>
            </Flex>
            {!validateChildren && selectedStatusToSave === 'PUBLISHED' && (
              <Flex ml="28px" mt={2} alignItems="center">
                <Icon name="warning" color={colors.superGiantRed11} mr={1} />
                <Text fontSize="14px" color={colors.superGiantRed11}>
                  To share with families, please tag children
                </Text>
              </Flex>
            )}
          </Flex>

          <SaveButtons
            validateChildren={validateChildren}
            statusToSave={selectedStatusToSave}
            onClose={onClose}
            onSubmit={onSubmit}
            setIsPreviewOpen={setIsPreviewOpen}
          />
        </Flex>
      </Box>
    </Modal>
  )
}

Save.displayName = 'Save'
export default React.memo(Save)
