import React, { useEffect, useState } from 'react'

import { useQuery, useLazyQuery } from '@apollo/client'
import { navigate } from '@reach/router'
import * as queryString from 'query-string'
import { Box, Flex } from 'stardust'
import styled from 'styled-components'

import { isWebView } from '~/config'
import { GetDocuments } from '~/pages/Dashboard/queries'

import LinksDocuments from '~/pages/Observations/components/Form/Links/LinksDocuments'
import LinksDocumentsFilterWeb from '~/pages/Observations/components/Form/Links/LinksDocumentsFilterWeb'

import { GET_DOCUMENTS_BY_QUERY_TYPES_AND_CHILDREN } from '~/pages/Observations/queries'
import { GET_DOCUMENTS_BY_IDS } from '~/pages/Observations/queries'
import { UpdateProps } from '~/pages/Observations/types'
import { BREAKPOINTS } from '~/theme'
import colors from '~/ui-components/tokens/colors'

import TaggedDocumentsBottomMobile from './TaggedDocumentsBottomMobile'
import TaggedDocumentsBottomWeb from './TaggedDocumentsBottomWeb'
import TaggedDocumentsHeaderMobile from './TaggedDocumentsHeaderMobile'
import TaggedDocumentsHeaderWeb from './TaggedDocumentsHeaderWeb'

const StyledBox = styled(Box)<{ backgroundColor: string }>`
  flex: 1;
  > div:first-child {
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 24px;
    background-color: ${(props) => props.backgroundColor};
    max-width: none;
    > div:first-child {
      margin-top: 0px;
    }
  }
  @media (min-width: ${BREAKPOINTS.md}px) {
    padding: 20px 24px 0;
    > div:first-child {
      border-radius: 16px 16px 0 0;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 24px;
    }
  }
`

const BorderedBox = styled(Box)`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: ${colors.cosmicShade19};
  @media (min-width: ${BREAKPOINTS.md}px) {
    background-color: inherit;
  }
`

interface Props {
  serviceFkey: string
  onChange?: ({ name, value }: UpdateProps) => void
  onCloseLinksModal?: () => void
  documentIds?: string[]
  taggedDocuments?: Playground.Document[]
  setSelectedDocument?: React.Dispatch<React.SetStateAction<Nullable<Playground.SimpleDocument>>>
  scrollRef: React.RefObject<HTMLDivElement>
}

const TaggedDocumentsView = ({
  serviceFkey,
  onCloseLinksModal,
  onChange,
  setSelectedDocument,
  taggedDocuments,
  documentIds,
  scrollRef,
}: Props) => {
  const types = ['learning_story', 'learning_table', 'observation']

  const [selectedTaggedDocs, setSelectedTaggedDocs] = useState<string[]>(documentIds || [])
  const [taggedDocsDetails, setTaggedDocsDetails] = useState<Playground.SimpleDocument[]>(
    taggedDocuments || []
  )
  const [selectedChildren, setSelectedChildren] = useState<string[]>()
  const [isError, setIsError] = useState<boolean>(false)
  const [selectedSorting, setSelectedSorting] = useState<string>('Last modified')
  const [searchString, setSearchString] = useState<Nullable<string>>(null)
  const [isDropdownVisible, setDropdownVisible] = useState(false)

  useEffect(() => {
    const { data } = queryString.parse(location.search)
    if (data) {
      const decodeData = typeof data == 'string' && atob(data)
      const jsonData = decodeData && JSON.parse(decodeData)

      jsonData && setSelectedChildren(jsonData.childFkeys)
      jsonData && setSelectedTaggedDocs(jsonData.ids)
    }
  }, [])

  useEffect(() => {
    if (searchString == '') {
      setSearchString(null)
    }
  }, [searchString])

  const handleOnQueryChange = (query: string) => {
    setSearchString(query)
    refetch()
  }

  const { data, error, loading, fetchMore, refetch } = useQuery<GetDocuments>(
    GET_DOCUMENTS_BY_QUERY_TYPES_AND_CHILDREN,
    {
      variables: { serviceFkey, types: types, selectedChildren, query: searchString },
    }
  )

  const handleOnComplete = (data: any) => {
    const documents = data?.service.documents?.edges
    const formattedDocs = documents.map((doc: any) => {
      const formattedDoubleQuotesTitle = doc.node.title.replace(/"/g, '\\"')
      return `{"id": "${doc.node.id}", "title": "${formattedDoubleQuotesTitle}", "type": "${doc.node.type}"}`
    })

    const taggedDocsStringEncoded = btoa(`[${formattedDocs.toString()}]`)
    navigate(`xplor://tagged-documents-confim?data=${taggedDocsStringEncoded}`)
  }

  const [getDocumentsByIds] = useLazyQuery<GetDocuments>(GET_DOCUMENTS_BY_IDS, {
    variables: { serviceFkey, ids: selectedTaggedDocs },
    onCompleted: (data) => {
      handleOnComplete(data)
    },
  })

  const handleSortingChange = (option: string) => {
    setSelectedSorting(option)
  }

  const handleCardClick = (doc: Playground.SimpleDocument) => {
    const currentSelectedDocs = [...selectedTaggedDocs]

    if (currentSelectedDocs.includes(doc.id)) {
      const filteredDocs = currentSelectedDocs.filter((docStringId) => doc.id !== docStringId)

      const updatedSelectedDocuments = taggedDocsDetails.filter((currentDoc) => currentDoc.id !== doc.id)
      setTaggedDocsDetails(updatedSelectedDocuments)

      setSelectedTaggedDocs(filteredDocs)
      setSelectedDocument && setSelectedDocument(null)
      return
    }

    setTaggedDocsDetails([...taggedDocsDetails, doc])
    setSelectedTaggedDocs(currentSelectedDocs.concat([doc.id]))
    setSelectedDocument && setSelectedDocument(doc)
  }

  const handleConfirmWeb = () => {
    onChange &&
      onChange({
        name: 'documentIds',
        value: selectedTaggedDocs,
      })

    const deepClone = (obj: Playground.SimpleDocument) => JSON.parse(JSON.stringify(obj))
    const newDocumentsArray = taggedDocsDetails.map(deepClone)
    onChange &&
      onChange({
        name: 'documents',
        value: newDocumentsArray,
      })
    onCloseLinksModal && onCloseLinksModal()
  }

  const handleConfirmMobile = () => {
    getDocumentsByIds()
  }

  const handleCancelWeb = () => {
    setSelectedTaggedDocs([])
    setTaggedDocsDetails([])
    setSelectedDocument && setSelectedDocument(null)
    onCloseLinksModal && onCloseLinksModal()
  }

  const handleCancelMobile = () => {
    navigate('xplor://tagged-documents-cancel')
  }

  useEffect(() => {
    error && setIsError(true)
  }, [error])

  const handleDropdownToggle = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  return (
    <Flex flexDirection="column" bg="surfacePrimary" width={isWebView ? undefined : '55vw'} ref={scrollRef}>
      {isWebView ? (
        <TaggedDocumentsHeaderMobile onCancelClick={handleCancelMobile} />
      ) : (
        <TaggedDocumentsHeaderWeb onCancelClick={handleCancelWeb} />
      )}
      <BorderedBox px={['20px', '24px']} pt={isWebView ? '24px' : undefined} mt={[3, '20px']}>
        <LinksDocumentsFilterWeb
          selectedSorting={selectedSorting}
          isDropdownVisible={isDropdownVisible}
          handleSortingChange={handleSortingChange}
          handleDropdownToggle={handleDropdownToggle}
          onQueryChange={(event) => handleOnQueryChange(event.currentTarget.value)}
        />
      </BorderedBox>
      <StyledBox backgroundColor={isWebView ? colors.cosmicShade19 : colors.cosmicShade12}>
        <LinksDocuments
          handleCardClick={(doc) => handleCardClick(doc)}
          refetch={refetch}
          fetchMore={fetchMore}
          selectedTaggedDocs={selectedTaggedDocs}
          selectedSorting={selectedSorting}
          data={data}
          isLoading={loading}
          isError={isError}
          scrollElementRef={scrollRef}
        />
      </StyledBox>
      {isWebView ? (
        <TaggedDocumentsBottomMobile
          onConfirmClick={handleConfirmMobile}
          onCancelClick={handleCancelMobile}
          totalTaggedDocs={selectedTaggedDocs.length}
        />
      ) : (
        <TaggedDocumentsBottomWeb
          onConfirmClick={handleConfirmWeb}
          onCancelClick={handleCancelWeb}
          totalTaggedDocs={selectedTaggedDocs.length}
        />
      )}
    </Flex>
  )
}

TaggedDocumentsView.displayName = 'TaggedDocumentsView'

export default TaggedDocumentsView
