import React from 'react'

import { Box, Flex, Icon, Text } from 'stardust'
import styled from 'styled-components'

import { isWebView } from '~/config'

import colors from '~/ui-components/tokens/colors'

interface Props {
  onCancelClick: () => void
}

const S = {
  Icon: styled(Icon)<{ backgroundColor: string; hoverColor: string }>`
    border-radius: 50%;
    padding: 10px;
    background-color: ${(props) => props.backgroundColor};
    &:hover {
      background-color: ${(props) => props.hoverColor};
      transition: background-color 0.2s;
    }
  `,
  Top: styled(Box)`
    background-color: ${colors.cosmicShade0};
    z-index: 100;
    position: sticky;
    top: 0;
    overflow-y: 0;
  `,
}

const TaggedDocumentsHeaderWeb = ({ onCancelClick }: Props) => {
  return (
    <S.Top p="24px 24px 0px" pb={1}>
      <Flex justifyContent="space-between" bg="white" width={'100%'}>
        <Flex>
          <S.Icon
            name="links"
            width={40}
            height={40}
            backgroundColor={colors.gaiaGreen10}
            hoverColor={colors.gaiaGreen9}
          />
          <Flex flexDirection="column">
            <Text ml={3} fontSize={3} fontWeight={600}>
              Link to Related Learning
            </Text>
            {!isWebView && (
              <Text ml={3} fontSize={2} mt={2} mr={4} lineHeight="130%">
                Include links to related Learning Documentation.
              </Text>
            )}
          </Flex>
        </Flex>
        <S.Icon
          width={40}
          height={40}
          onClick={onCancelClick}
          name="close"
          backgroundColor={colors.cosmicShade4}
          hoverColor={colors.cosmicShade6}
        />
      </Flex>
    </S.Top>
  )
}

TaggedDocumentsHeaderWeb.displayName = 'TaggedDocumentsHeaderWeb'

export default TaggedDocumentsHeaderWeb
