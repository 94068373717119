import React from 'react'

import { Image } from 'stardust'

import styled from 'styled-components'

interface Props {
  postMedia: Playground.MediaItem[]
  isPrintPreview?: boolean
  onMediaClick: (id: number) => void
}

const S = {
  Image: styled(Image)<{ borderRadius?: string; aspectRatio: string }>`
    border-radius: ${(props) => props.borderRadius};
    aspect-ratio: ${(props) => props.aspectRatio};
    object-fit: cover;
    &:hover {
      filter: brightness(90%);
      transition: background-color 0.3s;
    }
  `,
}

const FourMedia = ({ postMedia, isPrintPreview = false, onMediaClick }: Props) => {
  const getMediaCoverUrl = (media: Playground.MediaItem) =>
    media.mimeType?.includes('video') ? media.coverUrl : media.url

  return (
    <>
      <S.Image
        width="100%"
        aspectRatio="672/384"
        borderRadius={!isPrintPreview ? '15px 15px 0 0' : '0'}
        pb="2px"
        src={getMediaCoverUrl(postMedia[0])}
        onClick={() => onMediaClick(postMedia[0]?.id)}
        alt=""
      />
      <S.Image
        width="calc(100% / 3)"
        aspectRatio="222/284"
        borderRadius={!isPrintPreview ? '0 0 0 15px' : '0'}
        pr="2px"
        src={getMediaCoverUrl(postMedia[1])}
        onClick={() => onMediaClick(postMedia[1]?.id)}
        alt=""
      />
      <S.Image
        width="calc(100% / 3)"
        aspectRatio="222/284"
        src={getMediaCoverUrl(postMedia[2])}
        onClick={() => onMediaClick(postMedia[2]?.id)}
        alt=""
      />
      <S.Image
        width="calc(100% / 3)"
        aspectRatio="222/284"
        borderRadius={!isPrintPreview ? '0 0 15px 0' : '0'}
        pl="2px"
        src={getMediaCoverUrl(postMedia[3])}
        onClick={() => onMediaClick(postMedia[3]?.id)}
        alt=""
      />
    </>
  )
}

FourMedia.displayName = 'FourMedia'

export default React.memo(FourMedia)
