import React, { useContext, useEffect, useRef, useState } from 'react'

import { useMutation } from '@apollo/client'

import { Absolute, Box, Flex, Icon, Popover, Text } from 'stardust'
import styled from 'styled-components'

import Comment from '~/components/Comment/Comment'
import CommentInput from '~/components/CommentInput'
import { ADD_OBSERVATION_COMMENT_WITH_REPLY } from '~/components/Editors/components/Menu/components/Reflections/mutations'
import Modal from '~/components/Modal'
import { UserContext } from '~/contexts/User'
import { useScreenDetector } from '~/hooks/useScreenDetector'
import colors from '~/ui-components/tokens/colors'

import CommentsDateAndTime from '../../Comments/CommentsDateAndTime'

const FixedWrapper = styled.div`
  position: fixed;
  top: 20px;
  z-index: 1000;
`

const CloseIcon = styled(Icon)<{ backgroundColor: string; hoverColor: string }>`
  border-radius: 50%;
  padding: 10px;
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    background-color: ${(props) => props.backgroundColor};
    transition: background-color 0.2s;
  }
`

const DeleteButton = styled(Box)`
  transition: opacity 0.5s;
`
interface Props {
  postId?: Nullable<number>
  reflection: Playground.Reflection
  anchorRef: HTMLDivElement
  closePopup: () => void
  showDeleteModal?: (id: number) => void
  handleAddReply?: (reflectionId: number, newReply: Playground.Comment) => void
}

const ReflectionPopup = ({
  postId,
  reflection,
  anchorRef,
  closePopup,
  showDeleteModal,
  handleAddReply,
}: Props) => {
  const { isDesktop } = useScreenDetector()
  const [showDeleteIcon, setShowDeleteIcon] = useState(false)
  const anchor = useRef(anchorRef)
  const repliesRef = useRef<HTMLDivElement | null>(null)
  const [addReflectionOnObservation] = useMutation(ADD_OBSERVATION_COMMENT_WITH_REPLY)
  const replies = reflection.replies || []
  const repliesCount = useRef(replies.length)
  const user = useContext(UserContext)
  const userRef = user.state?.reference
  const authorRef = reflection.author.userRef
  const isUserAuthor = userRef === authorRef

  const addReply = async (content: string) => {
    const response = await addReflectionOnObservation({
      refetchQueries: ['Reflections'],
      awaitRefetchQueries: true,
      variables: {
        documentId: postId,
        replyParentId: reflection.id,
        content: content,
      },
    })

    if (response.data?.createObservationReflection) {
      handleAddReply && handleAddReply(reflection.id, response.data?.createObservationReflection)
    }

    return response
  }

  // scroll to top of the replies list when the popup is opened
  useEffect(() => {
    if (repliesRef.current) {
      repliesRef.current.scrollTop = 0
    }
  }, [])

  // scroll to bottom of the replies list when a new reply is added
  useEffect(() => {
    if (repliesCount.current < replies.length) {
      repliesRef.current?.scrollTo({
        top: repliesRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
    repliesCount.current = replies.length
  }, [replies.length])

  return isDesktop ? (
    <FixedWrapper>
      <Popover
        anchor={anchor}
        placement={isDesktop ? 'right-start' : 'bottom'}
        show
        onToggle={closePopup}
        targetCenter={isDesktop}>
        <Flex
          flexDirection="column"
          maxHeight="464px"
          width="576px"
          maxWidth="calc(100vw - 40px)"
          overflowY="auto">
          <Flex minHeight={10} ref={repliesRef} flexDirection="column" flexGrow={1} p={3} overflowY="auto">
            <Comment
              comment={reflection}
              xsmall
              onDeleteComment={postId ? showDeleteModal : undefined}
              onClose={closePopup}
            />
            <Box mt={replies.length > 0 ? 3 : 0} pl={3}>
              {replies.map((reply, idx) => (
                <Box key={idx} mb={idx === replies.length - 1 ? 0 : 3}>
                  <Comment
                    comment={reply}
                    xxsmall
                    key={reply.id}
                    onDeleteComment={postId ? showDeleteModal : undefined}
                  />
                </Box>
              ))}
            </Box>
          </Flex>
          {handleAddReply && postId ? (
            <Box p={3} borderTop="1px solid" borderColor="surfacePrimaryBorder">
              <CommentInput addComment={addReply} buttonText="Send" placeholder="Write a reply..." inlineV2 />
            </Box>
          ) : null}
        </Flex>
      </Popover>
    </FixedWrapper>
  ) : (
    <Modal open onClose={closePopup} dontRenderCloseButton>
      <Box maxWidth="calc(100vw - 40px)" width="608px" height="calc(100vh - 40px)">
        {/* title */}
        <Flex
          alignItems="center"
          justifyContent="space-between"
          px={3}
          pb={2}
          pt={3}
          borderBottom={`1px solid ${colors.cosmicShade14}`}>
          <Text fontSize="16px" fontWeight={700} lineHeight="24px">
            Reply in Reflection
          </Text>

          <CloseIcon
            width={40}
            height={40}
            onClick={closePopup}
            name="close"
            backgroundColor={colors.cosmicShade4}
            hoverColor={colors.cosmicShade6}
          />
        </Flex>

        <Box maxHeight="calc(100% - 64px)" overflowY="auto" ref={repliesRef}>
          {/* new reply input */}
          {handleAddReply && postId ? (
            <Box px="20px" py={2} borderBottom={`1px solid ${colors.cosmicShade4}`}>
              <CommentInput addComment={addReply} buttonText="Send" placeholder="Write a reply..." inlineV2 />
            </Box>
          ) : null}

          {/* reflection */}
          <Box
            py={3}
            mx="20px"
            borderBottom={`1px solid ${colors.cosmicShade14}`}
            onMouseEnter={() => setShowDeleteIcon(true)}
            onMouseLeave={() => setShowDeleteIcon(false)}
            onTouchStart={() => setShowDeleteIcon(true)}>
            <Text fontSize="16px" fontWeight={700} lineHeight="24px" mb={1}>
              {reflection.author.name}
            </Text>
            <Text fontSize="16px" fontWeight={500} lineHeight="24px" color={colors.cosmicShade11}>
              <CommentsDateAndTime updatedAt={reflection.insertedAt} />
            </Text>

            <Box position="relative">
              {reflection.content.split(/[\n\r]+/g).map((text, index) => (
                <Text key={index} mt={2} fontSize="16px" fontWeight={500} lineHeight="24px">
                  {text}
                </Text>
              ))}
              {isUserAuthor && postId && showDeleteModal && (
                <Absolute top={-12} right={1}>
                  <DeleteButton
                    bg={colors.superGiantRed12}
                    borderRadius={3}
                    boxShadow="2dp"
                    cursor="pointer"
                    opacity={showDeleteIcon ? 1 : 0}
                    onClick={() => {
                      showDeleteModal(reflection.id)
                      setShowDeleteIcon(false)
                    }}>
                    <Icon name="close" fill="white" />
                  </DeleteButton>
                </Absolute>
              )}
            </Box>
          </Box>

          {/* replies */}
          <Box mt="12px" px="20px" pb="20px">
            {replies.map((reply, idx) => (
              <Box key={idx} mb={idx === replies.length - 1 ? 0 : 3}>
                <Comment
                  comment={reply}
                  xxsmall
                  key={reply.id}
                  onDeleteComment={postId ? showDeleteModal : undefined}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

ReflectionPopup.displayName = 'ReflectionPopup'

export default React.memo(ReflectionPopup)
