import React from 'react'

import { Text, Flex, Icon } from 'stardust'

import PostTextField from '~/ui-components/PostTextField'
import colors from '~/ui-components/tokens/colors'

import { FieldProps } from '../../types'

interface TitleProps extends FieldProps {
  title?: string
  isSaving: boolean
}

const ModalTitle = ({ onChange, isSaving, isReadOnly, title = '' }: TitleProps) => {
  const characterLimit = 75
  const isTitleOverLimit = title && title.length === characterLimit
  const isTitleValid = () => (isSaving ? !!title : true)

  return (
    <>
      <Flex mb={1}>
        <PostTextField
          borderRadius={1}
          valid={isTitleValid()}
          placeholder="Title is required*"
          name="title"
          maxLength={characterLimit}
          readOnly={isReadOnly}
          value={title || ''}
          required
          onChange={(e) => onChange({ name: 'title', value: e.target.value.trimStart() })}
        />
      </Flex>
      {!isTitleValid() && (
        <Flex ml={2} mb={2} alignItems="center">
          <Icon name="warning" color={colors.superGiantRed5} mr={1} />
          <Text fontSize="14px" color={colors.superGiantRed5}>
            Title is required
          </Text>
        </Flex>
      )}
      {isTitleValid() && (
        <Flex alignItems="center" justifyContent={isTitleOverLimit ? 'space-between' : 'right'} px={2} mb={2}>
          {isTitleOverLimit && (
            <Text color={colors.cosmicShade20} fontSize="14px" fontWeight={400} lineHeight="21px" px="16px">
              You have reached the maximum character limit of 75
            </Text>
          )}
          <Flex>
            <Text color={colors.cosmicShade20} fontSize="14px" fontWeight={400} lineHeight="21px">{`${
              (title || '').length
            }/${characterLimit}`}</Text>
          </Flex>
        </Flex>
      )}
    </>
  )
}

ModalTitle.displayName = 'ModalTitle'
export default React.memo(ModalTitle)
