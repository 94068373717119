import React from 'react'

import TruncateMarkup from 'react-truncate-markup'
import { Box, Flex, Stack, Text } from 'stardust'

import type { DropdownMenuItemProps } from '~/components/DropdownMenu'
import OverflowMenu from '~/components/OverflowMenu'
import { formatDate } from '~/utils'

interface Props {
  authorFullName?: string
  updatedAt: string | Date
  menuItems?: DropdownMenuItemProps[]
}

const DocumentFooter = ({ authorFullName, updatedAt, menuItems }: Props) => {
  return (
    <Flex mt={['14.4px', 2]} justifyContent="space-between">
      <Box width="100%">
        <Stack>
          <TruncateMarkup ellipsis="...">
            <Text fontSize="10px" lineHeight="15px">
              Author: <strong>{authorFullName}</strong>
            </Text>
          </TruncateMarkup>
          <Text pt={1} fontSize="10px" lineHeight="15px">
            Last modified: <strong>{formatDate(updatedAt, 'YY', 'DD')}</strong>
          </Text>
        </Stack>
      </Box>
      <Box>
        {menuItems && menuItems.length > 0 && (
          <OverflowMenu items={menuItems} iconSize={16} iconButtonSize={40} />
        )}
      </Box>
    </Flex>
  )
}

DocumentFooter.displayName = 'DocumentFooter'

export default React.memo(DocumentFooter)
