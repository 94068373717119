import React, { useEffect, useMemo, useState } from 'react'

import { ApolloProvider } from '@apollo/client'
import { Router } from '@reach/router'
import { FlagProvider } from '@unleash/proxy-client-react'
import * as queryString from 'query-string'

import Splash from '~/components/Loading/Splash'
import config, { isGuestRoute } from '~/config'

import { createApolloClient } from '~/modules/api'

import Authentication, { clearAuthToken, logout, redirectToLogin, setAuthToken } from '~/modules/auth'

import AdminRoot from '~/pages/AdminRoot'
import SessionExpired from '~/pages/Errors/SessionExpired'
import Unexpected from '~/pages/Errors/Unexpected'
import GuestRoot from '~/pages/GuestRoot'
import PrivateRoot from '~/pages/Root'

import ErrorBoundary from './ErrorBoundary'

const App = () => {
  const [expired, setExpired] = useState(false)
  const [refreshing, setRefreshing] = useState(true)

  const unleashConfig = config.UNLEASH_CLIENT_KEY
    ? {
        url: config.UNLEASH_URL,
        clientKey: config.UNLEASH_CLIENT_KEY,
        refreshInterval: 15,
        appName: config.UNLEASH_APP_NAME,
        context: {
          properties: {
            platform: 'web',
          },
        },
      }
    : undefined

  const apolloClient = useMemo(() => {
    if (!config.GOLDEN_GATEWAY_API || !config.HODOR_API) return null
    const onAuthExpire = () => setExpired(true)
    return createApolloClient(config.GOLDEN_GATEWAY_API, config.HODOR_API, onAuthExpire)
  }, [])

  useEffect(() => {
    if (isGuestRoute) {
      setRefreshing(false)
      return
    }

    const queryParams = queryString.parse(location.search)
    const queryAuthToken = queryParams.authToken

    if (queryAuthToken) {
      setAuthToken(queryAuthToken as string)
      setRefreshing(false)
    } else {
      clearAuthToken()

      Authentication(config.HODOR_API!)
        .refresh()
        .then((token) => {
          setAuthToken(token)
          setRefreshing(false)
        })
        .catch(() => logout().then(redirectToLogin))
    }
  }, [])

  if (expired) return <SessionExpired />
  if (refreshing) return <Splash />
  if (!apolloClient) return <Unexpected />

  return (
    <ErrorBoundary>
      <FlagProvider config={unleashConfig}>
        <ApolloProvider client={apolloClient}>
          <Router>
            <AdminRoot path="/admin/*" />
            <GuestRoot path="/guest/*" />
            <PrivateRoot path="/*" />
          </Router>
        </ApolloProvider>
      </FlagProvider>
    </ErrorBoundary>
  )
}

App.displayName = 'App'

export default App
