import React, { useState } from 'react'

import { Box, Divider, Text, hoverMixin, Icon, Flex } from 'stardust'

import styled from 'styled-components'

import Can from '~/components/Authorisation/Can'

import ErrorModal from '~/components/Errors/ErrorModal'
import CopyTemplateModal from '~/components/Layouts/CopyTemplateModal'
import LayoutSelector from '~/components/Layouts/LayoutSelector'
import LayoutServiceSelector from '~/components/Layouts/LayoutServiceSelector'
import ListItem from '~/components/ListItem'

import Modal from '~/components/Modal'

interface ButtonAndModalProps {
  documentType: 'LEARNING_STORY' | 'LEARNING_PLAN' | 'LEARNING_TABLE'
  iconName: string
  permission: string
}

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.textActive};
`

const HoverFlex = styled(Flex)`
  cursor: pointer;
  ${hoverMixin};
`

const ButtonAndLayoutModal = ({ documentType, iconName, permission }: ButtonAndModalProps) => {
  const typeOfDocument = (type: string) => {
    switch (type) {
      case 'LEARNING_STORY':
        return 'Document'
      case 'LEARNING_PLAN':
        return 'Canvas'
      case 'LEARNING_TABLE':
        return 'Table'
      default:
        return ''
    }
  }
  const [showCopyLayoutSelector, setShowCopyLayoutSelector] = useState(false)
  const [layoutIdToCopy, setLayoutIdToCopy] = useState<Nullable<number>>(null)
  const [errors, setErrors] = useState(null)
  const [showLayoutSelector, setShowLayoutSelector] = useState(false)

  return (
    <>
      <Can perform={permission}>
        {(canWrite) =>
          canWrite && (
            <HoverFlex
              alignItems="center"
              onClick={() => {
                setShowLayoutSelector(true)
              }}
              data-test={`header-new-${typeOfDocument(documentType).toLowerCase()}-button`}>
              <ListItem
                renderIcon={() => <StyledIcon name={iconName} />}
                renderText={() => (
                  <Box>
                    <Text bold mb={1} fontSize={2}>
                      {`New ${typeOfDocument(documentType)}`}
                      <Text fontSize={1} fontWeight={'lighter'} color={'mediumEmphasis'} mt={2}>
                        Record and document learning
                      </Text>
                    </Text>
                  </Box>
                )}
              />
            </HoverFlex>
          )
        }
      </Can>
      <Divider borderColor="surfacePrimaryBorder" mb={0} />
      <Modal open={showLayoutSelector} onClose={() => setShowLayoutSelector(false)}>
        <LayoutSelector
          fromHome={true}
          type={documentType}
          onTileClick={() => setShowLayoutSelector(false)}
          onCopyToService={(layoutId) => {
            setShowLayoutSelector(false)
            setShowCopyLayoutSelector(true)
            setLayoutIdToCopy(layoutId)
          }}
        />
      </Modal>

      <Modal open={showCopyLayoutSelector} onClose={() => setShowCopyLayoutSelector(false)}>
        <LayoutServiceSelector
          layoutId={layoutIdToCopy}
          onCopySuccess={() => {
            setShowCopyLayoutSelector(false)
            setShowLayoutSelector(true)
            setLayoutIdToCopy(null)
          }}
          type={documentType}
        />
      </Modal>

      <CopyTemplateModal />

      <ErrorModal errors={errors} onClose={() => setErrors(null)} />
    </>
  )
}

ButtonAndLayoutModal.displayName = 'ButtonAndLayoutModal'

export default ButtonAndLayoutModal
