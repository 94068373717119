import React, { useState } from 'react'

import { Box, Flex, Icon, Text } from 'stardust'

import colors from '~/ui-components/tokens/colors'

import NewReflection from './ReflectionComponents/NewReflection'
import ReflectionList from './ReflectionComponents/ReflectionList'

interface Props {
  reflections?: Playground.Reflection[]
  tagType: string
  postId: Nullable<number>
  isPostPreview?: boolean
}

const Reflections = ({ tagType, reflections = [], postId, isPostPreview }: Props) => {
  const [reflectionsList, setReflectionsList] = useState(reflections)

  const handleAddNewReflection = (newReflection: Playground.Reflection) => {
    setReflectionsList((prevReflections) => {
      if (!Array.isArray(prevReflections)) {
        return [newReflection]
      }
      return [...prevReflections, newReflection]
    })
  }

  const handleAddReply = (reflectionId: number, newReply: Playground.Comment) => {
    // Find the reflection and add new reply in its replies array list
    setReflectionsList((prevReflections) => {
      // Map through the current list to find the matching reflection
      return prevReflections.map((reflection) => {
        if (reflection.id === reflectionId) {
          return {
            ...reflection,
            replies: [...reflection.replies, newReply], // Add new reply to the end of the replies array
          }
        } else {
          return reflection
        }
      })
    })
  }

  const handleDeleteReflection = (reflectionId: number) => {
    setReflectionsList((prevReflections) => {
      return prevReflections.filter((reflection) => reflection.id !== reflectionId)
    })
  }

  const handleDeleteReply = (reflectionId: number, replyId: number) => {
    setReflectionsList((prevReflections) =>
      prevReflections.map((reflection) => {
        if (reflection.id === reflectionId) {
          return {
            ...reflection,
            replies: reflection.replies.filter((reply) => reply.id !== replyId),
          }
        } else {
          return reflection
        }
      })
    )
  }

  return tagType === 'Reflections' ? (
    <Box p={3} maxHeight="464px" overflowY="auto">
      {/* add new reflection button */}
      {postId && !isPostPreview ? (
        <NewReflection postId={postId} handleAddNewReflection={handleAddNewReflection} />
      ) : null}

      {/* info banner */}
      <Flex
        border={1}
        borderColor={colors.pulsarPurple12}
        bg={colors.pulsarPurple13}
        borderRadius="16px"
        p={3}
        mt="12px"
        width="fit-content"
        alignItems="flex-start">
        <Icon color={colors.pulsarPurple10} name="info" data-test="reflections-info-icon" />
        <Text fontWeight={700} fontSize="14px" ml={2} lineHeight="24px">
          Reflections are only visible for educators in the service.
        </Text>
      </Flex>

      {/* list of reflections */}
      {reflectionsList?.length ? (
        <ReflectionList
          postId={postId}
          reflections={reflectionsList}
          handleAddReply={handleAddReply}
          handleDeleteReflection={handleDeleteReflection}
          handleDeleteReply={handleDeleteReply}
          isPostPreview={isPostPreview}
        />
      ) : null}
    </Box>
  ) : null
}

Reflections.displayName = 'Reflections'

export default React.memo(Reflections)
