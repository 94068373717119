import config, { coralogixAppName, coralogixKey } from '~/config'

const isCoralogixEnabled =
  typeof window !== 'undefined' && window.CoralogixRum && coralogixKey && coralogixAppName

export default () => {
  isCoralogixEnabled &&
    window.CoralogixRum.init({
      application: coralogixAppName,
      environment: config.APP_ENV,
      public_key: coralogixKey,
      coralogixDomain: 'EU2',
      version: config.APP_VERSION, // app version in package.json
      ignoreUrls: [
        /https:\/\/px\.ads\.linkedin\.com.*/,
        /https:\/\/eu\.app\.unleash-hosted\.com.*/,
        /https:\/\/www\.google-analytics\.com.*/,
        /.*\/sockjs-node\/info*./,
      ],
    })
}

export const setUserContextInCoralogix = (userContext: { [key: string]: any }) =>
  isCoralogixEnabled &&
  window.CoralogixRum.setUserContext({
    ...userContext,
  })

export const captureCoralogixError = (
  error: Error,
  otherInfo: {
    [key: string]: any
  }
) => isCoralogixEnabled && window.CoralogixRum.captureError(error, { ...otherInfo })

declare global {
  interface Window {
    CoralogixRum: any
  }
}
