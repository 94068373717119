import React from 'react'

import { Placement } from '@popperjs/core'

import { Box, Dropdown, Icon, IconButton } from 'stardust'

import { DropdownMenuItem, DropdownMenuItemProps } from '~/components/DropdownMenu'

import { isHomeWebview } from '~/config'
import colors from '~/ui-components/tokens/colors'

interface Props {
  items: DropdownMenuItemProps[]
  icon?: JSX.Element
  iconSize?: number | string
  iconButtonSize?: number | string
  placement?: Placement
}

const OverflowMenu = ({ items, icon, iconSize, iconButtonSize, placement }: Props) => (
  <Box cursor="pointer" data-test="overflow-menu">
    <Dropdown
      placement={placement}
      renderButton={({ handleClose }) => (
        <Box onClick={handleClose}>
          {icon ? (
            icon
          ) : (
            <IconButton
              icon={
                <Icon
                  name="overflow"
                  fill={isHomeWebview ? colors.cosmicShade0 : colors.cosmicShade15}
                  width={iconSize}
                />
              }
              aria-hidden="false"
              aria-label="more-options"
              {...(iconButtonSize ? { style: { width: iconButtonSize, height: iconButtonSize } } : {})}
            />
          )}
        </Box>
      )}
      render={({ handleClose }) =>
        items.map((item) => {
          const onClick = () => {
            handleClose()
            item.onClick()
          }

          return <DropdownMenuItem key={item.label} label={item.label} icon={item.icon} onClick={onClick} />
        })
      }
    />
  </Box>
)

OverflowMenu.displayName = 'OverflowMenu'

export default React.memo(OverflowMenu)
