import React from 'react'

import styled from 'styled-components'
import {
  AlignItemsProps,
  AlignSelfProps,
  AlignContentProps,
  BackgroundProps,
  BorderColorProps,
  BorderRadiusProps,
  BordersProps,
  BoxShadowProps,
  ColorProps,
  FlexDirectionProps,
  FlexProps,
  FlexWrapProps,
  HeightProps,
  JustifyContentProps,
  LineHeightProps,
  MaxHeightProps,
  MaxWidthProps,
  MinHeightProps,
  MinWidthProps,
  OpacityProps,
  OverflowProps,
  PositionProps,
  SpaceProps,
  WidthProps,
  ZIndexProps,
  alignItems,
  alignContent,
  alignSelf,
  background,
  borderColor,
  borderRadius,
  borders,
  boxShadow,
  color,
  flex,
  flexDirection,
  flexWrap,
  height,
  justifyContent,
  lineHeight,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  opacity,
  overflow,
  position,
  space,
  width,
  zIndex,
  TextAlignProps,
  textAlign,
} from 'styled-system'

type BaseProps = AlignItemsProps &
  AlignSelfProps &
  AlignContentProps &
  BackgroundProps &
  BordersProps &
  BorderColorProps &
  BorderRadiusProps &
  BoxShadowProps &
  ColorProps &
  FlexProps &
  FlexDirectionProps &
  FlexWrapProps &
  HeightProps &
  JustifyContentProps &
  LineHeightProps &
  MaxHeightProps &
  MaxWidthProps &
  MinHeightProps &
  MinWidthProps &
  OpacityProps &
  OverflowProps &
  PositionProps &
  SpaceProps &
  TextAlignProps &
  WidthProps &
  ZIndexProps &
  React.HTMLAttributes<HTMLDivElement>

export interface BoxProps extends BaseProps {
  border?: string | number
  cursor?: string
  flexGrow?: string | number
  flexShrink?: number
  flexBasis?: string
  outline?: string
  outlineColor?: string
  overflowY?: string
  overflowX?: string
  printOnly?: boolean
  screenOnly?: boolean
  gap?: string
}

const cursor = (props: BoxProps) => (props.cursor ? `cursor: ${props.cursor}` : null)
const flexGrow = (props: BoxProps) => (props.flexGrow ? `flex-grow: ${props.flexGrow}` : null)
const flexShrink = (props: BoxProps) => (props.flexShrink ? `flex-shrink: ${props.flexShrink}` : null)
const flexBasis = (props: BoxProps) => (props.flexBasis ? `flex-basis: ${props.flexBasis}` : null)
const outline = (props: BoxProps) => (props.outline ? `outline: ${props.outline}` : null)
const overflowY = (props: BoxProps) => (props.overflowY ? `overflow-y: ${props.overflowY}` : null)
const overflowX = (props: BoxProps) => (props.overflowX ? `overflow-x: ${props.overflowX}` : null)
const printOnly = (props: BoxProps) => (props.printOnly ? '@media screen {display: none;}' : null)
const screenOnly = (props: BoxProps) => (props.screenOnly ? '@media print {display: none;}' : null)
const gap = (props: BoxProps) => (props.gap ? `gap: ${props.gap}` : null)

const CSS_IN_JS_PROPS = new Set([
  // Layout Properties
  'maxWidth',
  'minWidth',
  'height',
  'maxHeight',
  'minHeight',
  'sizeWidth',
  'sizeHeight',
  'size',
  'ratioPadding',
  'ratio',
  'verticalAlign',
  'alignItems',
  'alignContent',
  'justifyItems',
  'justifyContent',
  'flexWrap',
  'flexBasis',
  'flexDirection',
  'flex',
  'justifySelf',
  'alignSelf',
  'order',
  'gridGap',
  'gridColumnGap',
  'gridRowGap',
  'gridColumn',
  'gridRow',
  'gridAutoFlow',
  'gridAutoColumns',
  'gridAutoRows',
  'gridTemplateColumns',
  'gridTemplateRows',
  'gridTemplateAreas',
  'gridArea',
  'gap',
  'textAlign',

  // Border Properties
  'border',
  'borderTop',
  'borderRight',
  'borderBottom',
  'borderLeft',
  'borders',
  'borderColor',
  'borderRadius',
  'borderWidth',
  'brTopRight',
  'brTopLeft',
  'brBottomRight',
  'brBottomLeft',

  // Visual Properties
  'boxShadow',
  'opacity',
  'overflow',
  'background',
  'backgroundImage',
  'backgroundPosition',
  'backgroundRepeat',
  'backgroundSize',
  'backgroundColor',
  'hoverColor',
  'outline',
  'outlineColor',

  // Position Properties
  'position',
  'zIndex',
  'top',
  'right',
  'bottom',
  'left',

  // Style Properties
  'textStyle',
  'colorStyle',
  'buttonStyle',

  // New Properties
  'cursor',
  'flexGrow',
  'flexShrink',
  'overflowY',
  'overflowX',
  'show',
  'aspectRatio',
  'textTransform',
  'isloading',
  'outline',
  'screenOnly',
  'showBackground',
  'read',
  'showArrow',
  'fullwidth',
  'isLoading',
])

const shouldForwardProp = (prop: string) => !CSS_IN_JS_PROPS.has(prop)

const Box = styled.div.withConfig({ shouldForwardProp })<BoxProps>`
  ${flexGrow};
  ${flexShrink};
  ${flexBasis};
  ${gap};
  ${overflowY};
  ${overflowX};
  ${screenOnly};
  ${printOnly};

  ${alignItems};
  ${alignContent};
  ${alignSelf};
  ${background};
  ${borders};
  ${borderColor};
  ${borderRadius};
  ${boxShadow};
  ${color};
  ${cursor};
  ${flex};
  ${flexDirection};
  ${flexWrap};
  ${height};
  ${justifyContent};
  ${lineHeight};
  ${maxHeight};
  ${maxWidth};
  ${minHeight};
  ${minWidth};
  ${opacity};
  ${outline};
  outline-color: ${(props) =>
    props.outlineColor ? props.theme.colors[props.outlineColor] : props.theme.colors.surfacePrimaryBorder};
  ${overflow};
  ${position};
  ${space};
  ${textAlign};
  ${width};
  ${zIndex};
`

Box.displayName = 'Box'

export default React.memo(Box)
