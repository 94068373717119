import React from 'react'

import { Icon, Text } from 'stardust'
import styled from 'styled-components'

const StyledButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  margin-left: 16px;
  padding: 0px;
  transition: opacity 0.2s;

  &:active {
    outline: none;
  }

  &:first-child {
    margin-left: 0px;
  }

  &:hover {
    opacity: 0.66;
  }
`
interface Props {
  onClick: () => void
  icon?: string
  text?: string
}

const ModalButton = ({ onClick, icon, text }: Props) => (
  <StyledButton onClick={onClick}>
    {icon && <Icon name={icon} width={16} fill="white" mr={1} />}
    <Text color="white" fontSize={1}>
      {text}
    </Text>
  </StyledButton>
)

ModalButton.displayName = 'Button'
export default ModalButton
