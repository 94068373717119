import React, { ReactNode } from 'react'

import { Placement } from '@popperjs/core'

import { Box, Dropdown, Flex, Icon, Text } from 'stardust'

import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

export interface DropdownMenuItemProps {
  label: string
  icon?: JSX.Element
  onClick(): unknown
}

interface Props {
  disabled?: boolean
  items: DropdownMenuItemProps[]
  label: ReactNode
  placement?: Placement
  portal?: boolean
  zIndex?: number
  callFrom: string
}

const HoverFlex = styled(Flex)`
  min-width: 200px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.textPrimaryMedium};
  transition: background 0.25s ease;

  &:hover {
    background: ${colors.cosmicShade4};
  }

  text-decoration: none;

  &:last-child {
    border-bottom: 0;
  }
`

export const DropdownMenuItem = ({ label, icon, onClick }: DropdownMenuItemProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    onClick()
  }

  return (
    <HoverFlex
      cursor="pointer"
      borderBottom="1px solid"
      borderColor="surfacePrimaryBorder"
      py="14px"
      px="20px"
      onClick={handleClick}
      data-test={`dropdown-menu-item-${label.replace(' ', '-').toLocaleLowerCase()}`}>
      {icon && (
        <Flex height="32px" width="24px" mr={3} alignItems="center" justifyContent="center">
          {icon}
        </Flex>
      )}
      <Box py={1}>
        <Text.span fontSize="16px" lineHeight="24px">
          {label}
        </Text.span>
      </Box>
    </HoverFlex>
  )
}

const DropdownMenu = ({ disabled, items, label, placement, portal, zIndex, callFrom }: Props) => {
  const color = disabled ? 'textPrimaryLow' : 'textPrimaryMedium'
  const safeItems = disabled ? [] : items

  return (
    <Box cursor="pointer" width={1} data-test={`dropdown-menu-${callFrom.replace(' ', '-')}`}>
      <Dropdown
        portal={portal}
        renderButton={({ handleClose }) => (
          <Flex alignItems="center" width={1} onClick={handleClose}>
            <Flex flexGrow={1}>
              {typeof label === 'string' ? <Text.span color={color}>{label}</Text.span> : label}
            </Flex>
            <Icon color={color} ml={1} name="chevronDown" />
          </Flex>
        )}
        render={({ handleClose }) => (
          <Flex
            flexDirection="column"
            maxHeight={'75vh'}
            overflow={'auto'}
            data-item={`dropdown-menu-list-${callFrom.replace(' ', '-')}`}>
            {safeItems.map((item) => {
              const onClick = () => {
                handleClose()
                item.onClick()
              }

              return <DropdownMenuItem key={item.label} label={item.label} onClick={onClick} />
            })}
          </Flex>
        )}
        zIndex={zIndex}
        placement={placement}
      />
    </Box>
  )
}

DropdownMenu.displayName = 'DropdownMenu'
DropdownMenuItem.displayName = 'DropdownMenuItem'

export default React.memo(DropdownMenu)
