import React, { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'

import { Box, Card, Text, Divider, Flex, Icon, MainButton, SecondaryButton } from 'stardust'

import styled from 'styled-components'

import Modal from '~/components/Modal'

import { useSelectedServiceFkey } from '~/contexts/Service'

import { CONSENTS } from '~/modules/children'

import colors from '~/ui-components/tokens/colors'

import { sortObjectsBy } from '~/utils'

import { GET_CHILDREN_WITH_INFO, GET_ROOMS } from '../../queries'

import { FieldProps } from '../../types'

import ChildPhotoVideoConsent from './Children/ChildPhotoVideoConsent'
import ChildrenLoading from './Children/ChildrenLoading'
import ChildrenSearch from './Children/ChildrenSearch'
import TagChildrenFilters from './Children/TagChildrenFilters'
import TagChildrenResult from './Children/TagChildrenResult'

const S = {
  Icon: styled(Icon)<{ backgroundColor: string; hoverColor: string }>`
    border-radius: 50%;
    padding: 10px;
    background-color: ${(props) => props.backgroundColor};
    &:hover {
      background-color: ${(props) => props.backgroundColor};
      transition: background-color 0.2s;
    }
  `,

  Top: styled(Flex)`
    background-color: white;
    padding: 24px 24px 16px 24px;
    z-index: 100;
    position: sticky;
    top: 0;
    overflow-y: 0;
  `,

  ChildrenCard: styled(Flex)`
    background-color: ${colors.cosmicShade12};
    flex-direction: column;
    margin: 0 24px 0 24px;
    padding: 16px 16px 8px 16px;
    border-radius: 16px 16px 0 0;
  `,

  Bottom: styled(Flex)`
    background-color: white;
    z-index: 100;
    position: sticky;
    bottom: 0;
    overflow-y: 0;
  `,
}

interface ChildrenProps extends FieldProps {
  taggedChildren: Playground.SimpleChild[]
  isQikkids: boolean
  onCloseChildrenModal: () => void
}

const TagChildren = ({ taggedChildren, isQikkids, onChange, onCloseChildrenModal }: ChildrenProps) => {
  const [childrenList, setChildrenList] = useState<Playground.SimpleChild[]>([])
  const [childrenInCurrentView, setChildrenInCurrentView] = useState<Playground.SimpleChild[]>([])
  const [bookedInAndSignInChildren, setBookedInAndSignInChildren] = useState<Playground.SimpleChild[]>([])
  const [selectedChildrenToTag, setSelectedChildrenToTag] = useState(taggedChildren)
  const [roomFkey, setRoomFkey] = useState('')
  const [filteringByGroup, setFilteringByGroup] = useState('All children')
  const [searchTerm, setSearchTerm] = useState('')
  const [sortChildren, setSortChildren] = useState(true)
  const [viewSelected, setViewSelected] = useState(false)
  const [expandAll, setExpandAll] = useState(false)
  const [allChildrenSelected, setAllChildrenSelected] = useState(false)
  const [selectedDate, setSelectedDate] = useState('')
  const [photoVideoConsent, setPhotoVideoConsent] = useState(false)
  const [noPhotoVideoConsentChildren, setNoPhotoVideoConsentChildren] = useState(Number)
  const serviceFkey = useSelectedServiceFkey()

  const {
    data: childrenData,
    error: childrenError,
    loading: childrenLoading,
  } = useQuery(GET_CHILDREN_WITH_INFO, {
    variables: { serviceFkey },
  })

  const {
    data: roomsData,
    error: roomsError,
    loading: roomsLoading,
  } = useQuery(GET_ROOMS, {
    variables: { serviceFkey },
  })

  useEffect(() => {
    const fetchData = () => {
      if (!childrenLoading && childrenData) {
        const loadedChildren = sortObjectsBy(
          childrenData.service.children,
          'fullName'
        ) as Playground.SimpleChild[]
        setChildrenList(loadedChildren)
      }
    }

    fetchData()
  }, [childrenLoading, childrenData])

  useEffect(() => {
    // Check if all children in childrenInCurrentView are in selectedChildrenToTag
    const allChildrenSelected =
      childrenInCurrentView.length > 0 &&
      childrenInCurrentView.every((child) =>
        selectedChildrenToTag.some((selectedChild) => selectedChild.fkey === child.fkey)
      )

    setAllChildrenSelected(allChildrenSelected)
  }, [childrenInCurrentView, selectedChildrenToTag, setAllChildrenSelected])

  if (childrenError) {
    return null
  }

  if (roomsError) {
    throw null
  }

  if (childrenLoading || roomsLoading) {
    return <ChildrenLoading />
  }

  const resetFilters = () => {
    setRoomFkey('')
    setFilteringByGroup('All children')
    setSelectedDate('')
  }

  const selectAndDeselectAllChildren = () => {
    if (allChildrenSelected && childrenInCurrentView.length > 0) {
      // Deselect all children that are currently in view
      setSelectedChildrenToTag((prevChildObjs) =>
        prevChildObjs.filter(
          (selectedChild) => !childrenInCurrentView?.some((child) => child.fkey === selectedChild.fkey)
        )
      )
      setAllChildrenSelected(false)
      setViewSelected(false)
    } else {
      setSelectedChildrenToTag((prevChildObjs) => {
        const childrenToAdd = childrenInCurrentView?.filter(
          (child) => !prevChildObjs.some((selectedChild) => selectedChild.fkey === child.fkey)
        )
        if (childrenInCurrentView.length > 0) {
          setAllChildrenSelected(true)
          return [...prevChildObjs, ...(childrenToAdd || [])]
        }
        return prevChildObjs
      })
    }
  }

  const handleChildrenSort = () => {
    if (roomFkey === 'All room' && !viewSelected) {
      return setExpandAll(!expandAll)
    }
    setSortChildren((prevSortOrder) => {
      const sortOrder = prevSortOrder ? 'desc' : 'asc'
      const sortedChildren = sortObjectsBy(childrenList, 'fullName')
      const finalSortedChildren = sortOrder === 'asc' ? sortedChildren : sortedChildren.reverse()
      setChildrenList(finalSortedChildren)
      return !prevSortOrder
    })
  }

  const handleViewAndSelectedAll = () => {
    if (!allChildrenSelected && !viewSelected) {
      setAllChildrenSelected(true)
    }
    if (viewSelected && allChildrenSelected) {
      setAllChildrenSelected(false)
    }
    setViewSelected(!viewSelected)
  }

  const handleBack = () => {
    onCloseChildrenModal()
  }

  const handleTagChildren = () => {
    const nonConsentingChildrenCount = selectedChildrenToTag.filter(
      (child) => !child.otherDetails?.consents?.includes(CONSENTS.activitiesAndExcursions)
    ).length

    {
      nonConsentingChildrenCount > 0
        ? (setNoPhotoVideoConsentChildren(nonConsentingChildrenCount), setPhotoVideoConsent(true))
        : (onChange({ name: 'children', value: selectedChildrenToTag }), onCloseChildrenModal())
    }
  }

  const rooms = roomsLoading
    ? []
    : [
        ...(isQikkids ? [] : [{ fkey: 'All room', name: 'All room' }]),
        ...(roomsData.service.rooms as Playground.Room[]),
      ].filter((room): room is Playground.Room => room !== undefined)

  const selectedRoom = rooms.find((room) => room.fkey === roomFkey)

  return (
    <Box width="100%">
      <Modal open dontRenderCloseButton onClose={onCloseChildrenModal} borderRadius="16px">
        <Card bg={colors.cosmicShade0} height="max-content" width="52vw" overflow-y="hidden" minWidth="0">
          <Flex flexDirection="column" width="100%">
            <S.Top justifyContent="space-between">
              <Flex alignItems="center">
                <S.Icon
                  fill={colors.pulsarPurple5}
                  name="childrenAlt"
                  width={40}
                  height={40}
                  backgroundColor={colors.pulsarPurple0}
                  hoverColor={colors.pulsarPurple1}
                />
                <Text ml={2} fontSize={4} fontWeight={600}>
                  Tag Children
                </Text>
              </Flex>
              <S.Icon
                width={40}
                height={40}
                onClick={() => onCloseChildrenModal()}
                name="close"
                backgroundColor={colors.cosmicShade4}
                hoverColor={colors.cosmicShade6}
              />
            </S.Top>

            <S.ChildrenCard>
              <TagChildrenFilters
                roomFkey={roomFkey}
                rooms={rooms}
                selectedRoom={selectedRoom}
                filteringByGroup={filteringByGroup}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setRoomFkey={setRoomFkey}
                setFilteringByGroup={setFilteringByGroup}
                resetFilters={resetFilters}
              />

              <Box width={1} mb="24px" borderColor="surfacePrimaryBorder">
                <Text fontWeight={700} fontSize={2} mb={2}>
                  Search
                </Text>
                <ChildrenSearch
                  childrenList={childrenList}
                  searchTerm={searchTerm}
                  roomFkey={roomFkey}
                  childrenInCurrentView={childrenInCurrentView}
                  setSearchTerm={setSearchTerm}
                />
              </Box>

              <Flex alignItems="center" justifyContent="space-between" mb={1}>
                <Flex alignItems="center">
                  <Text mb={2} ml={1} fontWeight={700} fontSize={2}>
                    Children
                  </Text>
                  <Text mb={2} ml={1} fontWeight={400} fontSize={2}>
                    {`(${childrenInCurrentView.length})`}
                  </Text>
                  <Flex
                    ml="16px"
                    pr="10px"
                    pt="5px"
                    pb="5px"
                    pl="6px"
                    alignItems="center"
                    bg={colors.cosmicShade4}
                    borderRadius="40px">
                    <Icon color="" name="noPhotosAlt" mr={1} width={24} />
                    <Text fontWeight={400} fontSize={2}>
                      = No photo consent
                    </Text>
                  </Flex>
                </Flex>
                <Flex>
                  <Flex
                    border={`1px solid ${colors.cosmicShade6}`}
                    cursor="pointer"
                    height="48px"
                    borderRadius="16px"
                    width={'max-content'}
                    py={2}
                    pl={10}
                    pr={14}
                    alignItems="center"
                    onClick={handleChildrenSort}
                    mr={2}>
                    <Flex alignItems="center">
                      <Icon
                        name={
                          roomFkey === 'All room' && !viewSelected
                            ? expandAll
                              ? 'expand'
                              : 'collapse'
                            : 'chevronSort'
                        }
                        mr={2}
                      />
                      <Text fontWeight={400} fontSize={2}>
                        {roomFkey === 'All room' && !viewSelected
                          ? expandAll
                            ? 'Expand all'
                            : 'Collapse all'
                          : sortChildren
                          ? 'A - Z'
                          : 'Z - A'}
                      </Text>
                    </Flex>
                  </Flex>

                  {childrenInCurrentView.some((child) =>
                    selectedChildrenToTag.some((selectedChild) => selectedChild.fkey === child.fkey)
                  ) && (
                    <Flex
                      border={`1px solid ${colors.cosmicShade6}`}
                      cursor="pointer"
                      height="48px"
                      borderRadius="16px"
                      p={1}
                      alignItems="center"
                      onClick={handleViewAndSelectedAll}
                      mr={2}>
                      <Flex alignItems="center" pr={2}>
                        <Icon name="eye" mr={1} ml={1} />
                        <Text fontWeight={400} fontSize={2}>
                          {viewSelected ? ' View all' : ' View selected'}
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                  <SecondaryButton
                    textTransform="none"
                    height="48px"
                    width="116px"
                    outline
                    onClick={selectAndDeselectAllChildren}>
                    {allChildrenSelected ? 'Deselect all' : 'Select all'}
                  </SecondaryButton>
                </Flex>
              </Flex>

              <TagChildrenResult
                roomFkey={roomFkey}
                rooms={rooms}
                isQikkids={isQikkids}
                expandAll={expandAll}
                serviceFkey={serviceFkey}
                selectedDate={selectedDate}
                sortChildren={sortChildren}
                searchTerm={searchTerm}
                childrenList={childrenList}
                selectedChildrenToTag={selectedChildrenToTag}
                childrenInCurrentView={childrenInCurrentView}
                viewSelected={viewSelected}
                filteringByGroup={filteringByGroup}
                bookedInAndSignInChildren={bookedInAndSignInChildren}
                setSelectedChildrenToTag={setSelectedChildrenToTag}
                setChildrenInCurrentView={setChildrenInCurrentView}
                setBookedInAndSignInChildren={setBookedInAndSignInChildren}
              />
            </S.ChildrenCard>

            <ChildPhotoVideoConsent
              photoVideoConsent={photoVideoConsent}
              setSelectedChildrenToTag={setSelectedChildrenToTag}
              setPhotoVideoConsent={setPhotoVideoConsent}
              noPhotoVideoConsentChildren={noPhotoVideoConsentChildren}
              selectedChildrenToTag={selectedChildrenToTag}
              onChange={onChange}
              onCloseChildrenModal={onCloseChildrenModal}
              isReadOnly={false}
            />

            <S.Bottom pb={2}>
              <Box width="100%">
                <Divider borderColor="lightGrey" />
                {selectedChildrenToTag.length > 0 && (
                  <Flex justifyContent="right" mr={5} mt={3}>
                    <Text color={colors.nebulaBlue4}>
                      <strong>{`Total ${selectedChildrenToTag.length} selected`}</strong>
                    </Text>
                  </Flex>
                )}
                <Flex width="100%" alignItems="center" p={3}>
                  <Box flex={1}>
                    <SecondaryButton height="48px" outline onClick={handleBack}>
                      Back
                    </SecondaryButton>
                  </Box>
                  <Box flex={1} ml={2}>
                    <MainButton
                      height="48px"
                      pt="12px"
                      pr="24px"
                      pb="12px"
                      pl="24px"
                      outline
                      disabled={!selectedChildrenToTag.length}
                      onClick={handleTagChildren}>
                      Tag Children
                    </MainButton>
                  </Box>
                </Flex>
              </Box>
            </S.Bottom>
          </Flex>
        </Card>
      </Modal>
    </Box>
  )
}

TagChildren.displayName = 'TagChildren'
export default React.memo(TagChildren)
