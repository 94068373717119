import React, { ReactNode } from 'react'

import { Button } from '@rmwc/button'
import styled from 'styled-components'
import { ColorProps, SpaceProps, color, space } from 'styled-system'

type BaseProps = ColorProps & SpaceProps

interface ButtonProps extends BaseProps {
  children: ReactNode
  disabled?: boolean
  label?: string
  icon?: ReactNode
  outline?: boolean
  type?: string
  textTransform?: string
  width?: number | string
  height?: number | string
  borderRadius?: number
  fontSize?: string
  onClick?(): void
}

const StyledButton = styled(Button)<ButtonProps>`
  && {
    height: ${(props: ButtonProps) => props.height || ''};
    width: ${(props: ButtonProps) => props.width || '100%'};
    border-radius: ${(props: ButtonProps) =>
      props.borderRadius ? `${props.borderRadius}px` : '16px'}; // Use borderRadius property
    border: ${(props) => (props.outline ? `solid 1px ${props.theme.colors.primary}` : 'initial')};
    text-transform: ${(props: ButtonProps) => props.textTransform || 'capitalize'};

    .mdc-button--dense {
      height: 22px !important;
      font-size: 12px !important;
    }

    .mdc-button__icon {
      height: 24px !important;
      width: 24px !important;
    }

    .mdc-button__label {
      white-space: nowrap;
      font-size: ${(props) => props.fontSize || '14px'};
    }

    border-color: ${(props) => (props.disabled ? props.theme.colors.cosmicShade4 : 'initial')};

    ${space};
    ${color};
  }
`

export const Main = (props: ButtonProps) => <StyledButton unelevated {...props} />

export const Secondary = (props: ButtonProps) => (
  <StyledButton {...props} color={props.disabled ? 'secondary' : 'primary'} />
)

export const Outline = (props: ButtonProps) => <StyledButton {...props} color="primary" outline />
