import React, { useCallback, useContext, useMemo, useState } from 'react'

import { format } from 'date-fns'

import { Absolute, Box, Fab, Flex, Heading, Icon, Image, Pill, Text } from 'stardust'
import styled from 'styled-components'

import observationImage from '~/assets/images/observations.svg'
import scoreChangeImage from '~/assets/images/score-change.svg'
import { sortObjectsBy } from '~/utils'

import { LearningAnalysisContext } from '../Context'

import ScoreChangeForm from './ScoreChangeForm'
interface Props {
  category: Playground.LearningFrameworkCategory
  records: Playground.LearningRecord[]
  onScoreChange(attrs: Record<string, number>): void
}

const ScrollableFlex = styled(Flex)`
  overflow-x: hidden;
  overflow-y: auto;
`

export const CELL_COLORS = [
  'surfacePrimary',
  'nebulaBlue0',
  'nebulaBlue1',
  'nebulaBlue3',
  'nebulaBlue4',
  'nebulaBlue5',
]

interface EntityStyle {
  color: string
  image: string
  title: string
}

const entityStyles: Record<string, EntityStyle> = {
  observation: {
    color: 'observation',
    image: observationImage,
    title: 'Observation',
  },
  score_change: {
    color: 'primary',
    image: scoreChangeImage,
    title: 'Score Change',
  },
}

const getEducatorName = (educators: Playground.Educator[], record: Playground.LearningRecord) => {
  if (!record.contributorRef.includes('/Educator/')) return 'Administrator'
  const educator = educators.find((educator) => educator.userReference === record.contributorRef!)
  return educator ? educator.fullName : 'Educator'
}

const Evidence = ({ category, records, onScoreChange }: Props) => {
  const { cohortsEnabled, educators, findAgeGroup, findRank } = useContext(LearningAnalysisContext)

  const [showScoreChange, setShowScoreChange] = useState(false)

  const onSaveScoreChange = useCallback(
    async (outcomeId: number, ageGroupId: number, rankId: number) => {
      await onScoreChange({ outcomeId, ageGroupId, rankId })

      setShowScoreChange(false)
    },
    [onScoreChange]
  )

  const onToggleScoreChange = useCallback(() => setShowScoreChange((value) => !value), [])

  const sortedRecords = useMemo(() => {
    return sortObjectsBy(records, 'achievedAt').reverse()
  }, [records])

  return (
    <Flex flexDirection="column" width={400}>
      <Box p={4} pb={3} width={1}>
        <Heading.h5 bold lineHeight={1.4} mb={0} mt={0}>
          {category.name}
        </Heading.h5>
      </Box>

      {showScoreChange ? (
        <ScoreChangeForm category={category} onCancel={onToggleScoreChange} onSave={onSaveScoreChange} />
      ) : (
        <Flex flexGrow={1} width={1}>
          <ScrollableFlex flexDirection="column" maxHeight="75vh" minHeight="400px" width={1}>
            {sortedRecords.length === 0 && (
              <Box px={4} width={1}>
                <Text.span lineHeight={1.4} textAlign="center">
                  No evidence has been recorded for this child
                </Text.span>
              </Box>
            )}

            {sortedRecords.map((record) => {
              const entityStyle = entityStyles[record.entityType]

              const ageGroup = cohortsEnabled && record.ageGroupId ? findAgeGroup(record.ageGroupId) : null
              const rank = cohortsEnabled && record.rankId ? findRank(record.rankId) : null

              const onEvidenceClick = (record: Playground.LearningRecord) => {
                record.entityType === 'observation'
                  ? window.open(`/observations/${record.entityId}`, '_blank')
                  : null
              }

              return (
                <Box key={record.id} px={4} width={1} onClick={() => onEvidenceClick(record)}>
                  <Flex
                    alignItems="center"
                    borderBottom="1px solid"
                    borderColor="surfacePrimaryBorder"
                    py={3}
                    width={1}>
                    <Flex
                      alignItems="center"
                      bg={entityStyle.color}
                      borderRadius={2}
                      height={80}
                      justifyContent="center"
                      minWidth={80}>
                      <Image src={entityStyle.image} height={46} width={46} alt={entityStyle.title} />
                    </Flex>
                    <Flex flexDirection="column" ml={3}>
                      <Text.span bold color="black" mb={1}>
                        {record.entityTitle}
                      </Text.span>
                      <Text.span color="cosmicShade8" fontSize={0}>
                        {format(record.achievedAt, 'D MMM')}&nbsp;&bull;&nbsp;
                        {getEducatorName(educators, record)}
                      </Text.span>
                      {cohortsEnabled && (
                        <Flex mt={2}>
                          <Pill text={rank?.name || 'Invalid rank'} />
                          <Pill text={ageGroup?.name || 'Invalid age group'} />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Box>
              )
            })}
          </ScrollableFlex>

          {cohortsEnabled && (
            <Absolute bottom={16} right={16}>
              <Fab bg="primary" borderRadius={21} height={42} width={80} onClick={onToggleScoreChange}>
                <Flex alignItems="center">
                  <Icon color="white" name="add" width={16} mr={2} />
                  <Text.span color="white" fontSize={1}>
                    Add
                  </Text.span>
                </Flex>
              </Fab>
            </Absolute>
          )}
        </Flex>
      )}
    </Flex>
  )
}

Evidence.displayName = 'Evidence'

export default React.memo(Evidence)
