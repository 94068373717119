import React, { useContext, useMemo } from 'react'

import { navigate } from '@reach/router'

import { Absolute, Anchor, Avatar, Box, Flex, Text, TruncateMultiline } from 'stardust'
import styled from 'styled-components'

import DropdownMenu from '~/components/DropdownMenu'
import FilterDropdown, { FkeyOrId, ProfileGroup, ProfileItem } from '~/components/FilterDropdown'
import Pagination from '~/components/Pagination'
import { initials, shortAge } from '~/components/Profiles/core'
import useLocalStorage from '~/hooks/useLocalStorage'
import usePagination from '~/hooks/usePagination'
import { LAYERS } from '~/theme'
import { sortObjectsBy } from '~/utils'

import { LearningAnalysisContext } from '../Context'

import Cell, { CELL_COLORS } from './Cell'
import ExportButton from './ExportButton'

const PaddedFlex = styled(Flex)`
  padding: 0 12px;
`

const TruncatedText = styled(Text.span)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ChildName = styled(TruncatedText)`
  width: 140px;
`

interface Props {
  defaultFilter?: ProfileItem<FkeyOrId>
  filters: ProfileGroup<FkeyOrId>[]
  filterDropdownText?: string
  localStorageKey?: string
  records: Playground.LearningRecord[]
  serviceFkey: string
}

type RecordLookup = Record<string, Playground.LearningRecord[]>
type ChildCategoryLookup = Record<string, RecordLookup>

const Heatmap = ({
  defaultFilter,
  filters,
  filterDropdownText,
  localStorageKey,
  records,
  serviceFkey,
}: Props) => {
  const {
    categories,
    children,
    cohortsEnabled,
    ranks,
    addScoreChange,
    filterChildren,
    findAgeGroup,
    findOutcome,
    findRank,
  } = useContext(LearningAnalysisContext)

  const defaultValue = defaultFilter ? defaultFilter : null
  const [filter, setFilter] = useLocalStorage<Nullable<ProfileItem<FkeyOrId>>>(defaultValue, localStorageKey)

  const filteredChildren = useMemo(() => {
    return sortObjectsBy(filterChildren(children, filter), 'fullName')
  }, [children, filter, filterChildren])

  const {
    pagedData: pagedChildren,
    page,
    pageCount,
    pageSize,
    onPageChange,
    onPageSizeChange,
  } = usePagination(filteredChildren)

  // Put the records into a lookup map
  const rawRecordLookup = useMemo(() => {
    return records.reduce((acc, record) => {
      const outcome = findOutcome(record.outcomeId)
      if (!outcome) return acc

      const childCategoryLookup: RecordLookup = acc[record.childFkey] || {}
      const categoryRecords = childCategoryLookup[outcome.primaryId] || []

      childCategoryLookup[outcome.primaryId] = [...categoryRecords, record]
      acc[record.childFkey] = childCategoryLookup

      return acc
    }, {} as ChildCategoryLookup)
  }, [findOutcome, records])

  // Create a loookup map which has an entry for every child/category
  const childCategoryRecordLookup = useMemo(() => {
    return children.reduce((childAcc, child) => {
      const innerAcc = categories.primary.reduce((categoryAcc, category) => {
        categoryAcc[category.id] = rawRecordLookup?.[child.fkey]?.[category.id] || []
        return categoryAcc
      }, {} as RecordLookup)

      childAcc[child.fkey] = innerAcc
      return childAcc
    }, {} as ChildCategoryLookup)
  }, [categories, children, rawRecordLookup])

  const pageSizeOptions = useMemo(() => {
    return [
      { label: '10 rows', onClick: () => onPageSizeChange(10) },
      { label: '20 rows', onClick: () => onPageSizeChange(20) },
      { label: '30 rows', onClick: () => onPageSizeChange(30) },
    ]
  }, [onPageSizeChange])

  return (
    <Flex flexDirection="column" width={1}>
      <Flex alignItems="center" justifyContent="space-between" py={2}>
        <Flex justifyContent="flex-start" flexGrow={1} width={0}>
          <FilterDropdown
            buttonDefaultText={filterDropdownText}
            filters={filters}
            portal
            selection={filter}
            zIndex={LAYERS.SectionHeader}
            onSelect={setFilter}
          />
        </Flex>

        {cohortsEnabled && (
          <Flex justifyContent="center" flexGrow={1} width={0}>
            {ranks.map((rank) => (
              <Flex key={rank.id} alignItems="center" ml={3}>
                <Box bg={CELL_COLORS[rank.value]} borderRadius={0} height={16} width={16} />
                <Text.span fontSize={0} ml={1}>
                  {rank.name}
                </Text.span>
              </Flex>
            ))}
          </Flex>
        )}
        <Flex justifyContent="flex-end" flexGrow={1} width={0}>
          <ExportButton records={records} />
        </Flex>
      </Flex>

      <Flex bg="surfacePrimary" position="relative" width={1}>
        <Flex
          bg="surfacePrimary"
          boxShadow="1dp"
          flexDirection="column"
          width={200}
          zIndex={LAYERS.RaisedElement}>
          <PaddedFlex
            alignItems="center"
            borderBottom="1px solid"
            borderColor="surfacePrimaryBorder"
            height={75}
          />
          {pagedChildren.map((child) => (
            <Anchor key={child.fkey} onClick={() => navigate(`/children/${child.fkey}`)}>
              <PaddedFlex
                alignItems="center"
                borderBottom="1px solid"
                borderColor="surfacePrimaryBorder"
                height={56}>
                <Avatar alt={child.fullName} src={child.image} xsmall text={initials(child.fullName)} />
                <Flex flexDirection="column" ml={2}>
                  <ChildName fontSize={0} mb={1}>
                    {child.fullName}
                  </ChildName>
                  <Text.span color="cosmicShade8" fontSize={0}>
                    {shortAge(child.dob)}
                  </Text.span>
                </Flex>
              </PaddedFlex>
            </Anchor>
          ))}
        </Flex>
        <Absolute left={200} right={0}>
          <Flex
            outline="1px solid"
            outlineColor="surfacePrimaryBorder"
            flexDirection="column"
            flexGrow={1}
            overflow="auto">
            <Flex bg="surfaceSecondary">
              {categories.primary.map((category) => (
                <PaddedFlex
                  key={category.id}
                  alignItems="center"
                  bg="surfacePrimary"
                  borderColor="surfacePrimaryBorder"
                  justifyContent="center"
                  height={75}
                  maxWidth={150}
                  minWidth={150}
                  title={category.name}>
                  <TruncateMultiline fontSize={0} maxLines={4}>
                    {category.name}
                  </TruncateMultiline>
                </PaddedFlex>
              ))}
            </Flex>
            {pagedChildren.map((child) => (
              <Flex key={child.fkey} bg="surfaceSecondary">
                {categories.primary.map((category) => (
                  <Cell
                    key={category.id}
                    category={category}
                    child={child}
                    cohortsEnabled={cohortsEnabled}
                    records={childCategoryRecordLookup[child.fkey][category.id]}
                    serviceFkey={serviceFkey}
                    onAgeGroupLookup={findAgeGroup}
                    onRankLookup={findRank}
                    addScoreChange={addScoreChange}
                  />
                ))}
              </Flex>
            ))}
          </Flex>
        </Absolute>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center" py={3}>
        <Pagination page={page} pageCount={pageCount} onPageChange={onPageChange} />

        <Flex flexDirection="column">
          <DropdownMenu
            portal
            label={<Text.span fontSize={0}>Rows {pageSize}</Text.span>}
            items={pageSizeOptions}
            callFrom="learning-heatmap-rows"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

Heatmap.displayName = 'Heatmap'

export default React.memo(Heatmap)
