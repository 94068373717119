import React, { useState, useRef } from 'react'

import distanceInWords from 'date-fns/distance_in_words'
import { Box, Card, Flex, Icon, Interactive, Text, TruncateMultiline } from 'stardust'
import styled from 'styled-components'

import Modal from '~/components/Modal'
import colors from '~/ui-components/tokens/colors'
import { hasAtLeastOne } from '~/utils'

import { FieldProps } from '../../types'
import ReflectionPopup from '../View/Tags/ReflectionComponents/ReflectionPopup'

const S = {
  Icon: styled(Icon)<{ backgroundColor: string; hoverColor: string }>`
    border-radius: 50%;
    padding: 10px;
    background-color: ${(props) => props.backgroundColor};
    &:hover {
      background-color: ${(props) => props.hoverColor};
      transition: background-color 0.2s;
    }
  `,
  Content: styled(Text)`
    margin-top: 8px;
    align-self: stretch;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
  `,
  Top: styled(Flex)`
    background-color: white;
    padding: 24px 24px 16px 24px;
    z-index: 100;
    position: sticky;
    top: 0;
    overflow-y: 0;
  `,
}

interface PostReflectionsModalProps extends FieldProps {
  reflections?: Playground.Reflection[]
  onCloseReflectionsModal: () => void
}

const PostReflectionsModal = ({ reflections, onCloseReflectionsModal }: PostReflectionsModalProps) => {
  const [selectedReflection, setSelectedReflection] = useState<
    { reflection: Playground.Reflection; index: number } | undefined
  >(undefined)

  const closeModalTimeout = useRef<NodeJS.Timeout | null>(null)
  const anchorRefs = useRef<HTMLDivElement[]>([])

  const closeReflectionReplies = () => {
    !closeModalTimeout.current && onCloseReflectionsModal()

    if (selectedReflection) {
      setSelectedReflection(undefined)
      // Start a timer that will set the variable to false after 1 second
      closeModalTimeout.current = setTimeout(() => {
        closeModalTimeout.current = null
      }, 1000)
    }
  }

  return (
    <Box width="100%">
      <Modal open dontRenderCloseButton onClose={closeReflectionReplies} borderRadius="16px">
        <Card
          bg={colors.cosmicShade0}
          height="max-content"
          width="608px"
          overflow-y="hidden"
          minWidth="0"
          maxWidth="calc(100vw - 40px)">
          <S.Top justifyContent="space-between" alignItems="center">
            <Flex>
              <Text ml={2} fontSize={4} fontWeight={600}>
                Reflections
              </Text>
            </Flex>

            <S.Icon
              width={40}
              height={40}
              onClick={closeReflectionReplies}
              name="close"
              backgroundColor={colors.cosmicShade4}
              hoverColor={colors.cosmicShade6}
            />
          </S.Top>
          <Box px={3} bg="surfacePrimary" borderTop={1} borderColor="surfacePrimaryBorder">
            {hasAtLeastOne(reflections) && (
              <Flex
                flexDirection="column"
                width={1}
                maxHeight="calc(100vh - 200px)"
                overflow="auto"
                minHeight="483px">
                {reflections?.map((reflection, index) => {
                  const postedAt = `${distanceInWords(reflection.insertedAt, new Date())} ago`
                  const replies = reflection.replies ? reflection.replies : []
                  const textBlocks = reflection.content.split(/[\n\r]+/g)
                  const totalReplies = replies.length
                  return (
                    <Interactive
                      key={reflection.id}
                      onClick={() => {
                        closeModalTimeout.current
                          ? clearTimeout(closeModalTimeout.current)
                          : (closeModalTimeout.current = setTimeout(() => {}, 0))
                        setSelectedReflection({
                          reflection,
                          index,
                        })
                      }}>
                      <Box>
                        <Flex alignItems="center" justifyContent="space-between" width={1}>
                          <Text mt={3} fontSize={0} bold>
                            {reflection.author.name}
                          </Text>
                          <Text mt={3} ml={2} fontSize={0}>
                            {postedAt}
                          </Text>
                        </Flex>
                        <Box
                          borderBottom={1}
                          borderColor="surfacePrimaryBorder"
                          mt={2}
                          pb={3}
                          ref={(element: HTMLDivElement) => (anchorRefs.current[index] = element)}>
                          <TruncateMultiline fontSize={1} maxLines={4}>
                            {textBlocks.map((text, index) => (
                              <S.Content key={index}>{text}</S.Content>
                            ))}
                          </TruncateMultiline>
                          {totalReplies > 0 && (
                            <Flex pt={3} alignItems="center">
                              <Icon name="comments" fill="textPrimaryMedium" />
                              <Text.span fontSize="14px" ml={1} color="textPrimaryMedium">
                                {totalReplies}
                              </Text.span>
                            </Flex>
                          )}
                        </Box>
                      </Box>
                    </Interactive>
                  )
                })}

                {selectedReflection ? (
                  <ReflectionPopup
                    reflection={selectedReflection?.reflection}
                    anchorRef={anchorRefs.current[selectedReflection.index]}
                    closePopup={closeReflectionReplies}
                  />
                ) : null}
              </Flex>
            )}
          </Box>
        </Card>
      </Modal>
    </Box>
  )
}

PostReflectionsModal.displayName = 'PostReflectionsModal'
export default React.memo(PostReflectionsModal)
