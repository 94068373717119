import React from 'react'

import { Flex, FlexItem, SecondaryButton, TextField } from 'stardust'

interface InlineCommentProps {
  onChange: (value: string) => void
  buttonText: string
  commentValue: string
  placeholder: string
  disabled: boolean
  error: boolean
  autoFocus: boolean
}

const InlineComment = ({
  onChange,
  buttonText,
  commentValue,
  placeholder,
  disabled,
  error,
  autoFocus,
}: InlineCommentProps) => (
  <Flex alignItems="center" flexDirection="row" justifyContent="space-between">
    <FlexItem width="100%" flexGrow={2}>
      <TextField
        autoComplete="off"
        data-test="comment-field"
        textarea
        resizeable={false}
        invalid={error}
        m="0px"
        rows={2}
        outlined={true}
        name="comment"
        value={commentValue}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
    </FlexItem>
    <FlexItem pl={2}>
      <SecondaryButton width={50} disabled={disabled} type="submit" data-test="comment-submit">
        {buttonText}
      </SecondaryButton>
    </FlexItem>
  </Flex>
)

export default InlineComment
