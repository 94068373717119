import React from 'react'

import { Image } from 'stardust'

import styled from 'styled-components'

interface Props {
  postMedia: Playground.MediaItem[]
  isPrintPreview?: boolean
  onMediaClick: (id: number) => void
}

const S = {
  Image: styled(Image)<{ borderRadius: string }>`
    border-radius: ${(props) => props.borderRadius};
    aspect-ratio: 672/412;
    object-fit: cover;
    &:hover {
      filter: brightness(90%);
      transition: background-color 0.3s;
    }
  `,
}

const OneMedia = ({ postMedia, isPrintPreview = false, onMediaClick }: Props) => {
  const getMediaCoverUrl = (media: Playground.MediaItem) =>
    media.mimeType?.includes('video') ? media.coverUrl : media.url

  return (
    <S.Image
      width="100%"
      borderRadius={!isPrintPreview ? '15px' : '0'}
      src={getMediaCoverUrl(postMedia[0])}
      onClick={() => onMediaClick(postMedia[0]?.id)}
      alt=""
    />
  )
}

OneMedia.displayName = 'OneMedia'

export default React.memo(OneMedia)
