import React, { useRef, useState } from 'react'

import { Box, Flex, Text, Divider, Icon } from 'stardust'

import styled from 'styled-components'

import useClickedOutside from '~/hooks/useClickedOutside'

import colors from './tokens/colors'

const Options = styled(Box)<{ dropdownPosition?: string }>`
  z-index: ${(props) => props.zIndex || 100};
  box-shadow: ${(props) => props.theme.shadows['1dp']};
  border-radius: 16px;
  background-color: white;
  max-height: 240px;
  overflow-y: auto;
  width: 262px;
  position: absolute;
  inset: ${(props) => (props.dropdownPosition === 'left' ? 'auto 0 auto auto' : 'auto auto auto 0')};
  margin-top: 4px;
  div {
    &:hover {
      background-color: ${colors.cosmicShade4};
    }
  }
`

interface FlexDropdownProps {
  defalutPlaceHolder?: string
  options: { label: string; value: any }[]
  selectedOption: { label: string; value: any } | null
  onSelect: (option: { label: string; value: any } | null) => void
  renderOption: (option: { label: string; value: any }) => React.ReactNode
  dropdownZIndex?: number
  dropdownPosition?: 'left' | 'right'
}

function FlexDropdown({
  options,
  defalutPlaceHolder,
  selectedOption,
  onSelect,
  renderOption,
  dropdownZIndex,
  dropdownPosition,
}: FlexDropdownProps) {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  useClickedOutside(() => setIsOpen(false), dropdownRef)

  function handleOptionClick(option: { label: string; value: any }) {
    onSelect(option)
    setIsOpen(false)
  }

  const handleButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsOpen(!isOpen)
  }

  return (
    <Box position="relative">
      <Flex
        bg="white"
        cursor="pointer"
        borderColor={selectedOption ? colors.cosmicShade11 : colors.cosmicShade6}
        borderRadius="16px"
        border="1px solid"
        width="100%"
        alignItems="center"
        px="15px"
        py="11px"
        onClick={handleButtonClick}>
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Box>
            <Text
              fontSize={2}
              fontWeight={400}
              color={selectedOption ? undefined : 'textPrimaryLow'}
              lineHeight="24px">
              {selectedOption ? renderOption(selectedOption) : defalutPlaceHolder}
            </Text>
          </Box>
          <Box>
            <Icon name={isOpen ? 'chevronUpTransparent' : 'chevronDown'} ml={3} width={24} />
          </Box>
        </Flex>
      </Flex>
      {isOpen && (
        <Options
          zIndex={dropdownZIndex}
          dropdownPosition={dropdownPosition}
          flexDirection="column"
          ref={dropdownRef}>
          {options.map((option, index) => (
            <Box key={index} onClick={() => handleOptionClick(option)} alignItems="center">
              <Text p={3}> {renderOption(option)}</Text>
              <Divider borderColor={colors.cosmicShade4} />
            </Box>
          ))}
        </Options>
      )}
    </Box>
  )
}

FlexDropdown.displayName = 'FlexDropdown'

export default React.memo(FlexDropdown)
