import gql from 'graphql-tag'

export const ADD_COMMENT_WITH_REPLY = gql`
  mutation ($documentId: Int!, $content: String!, $replyParentId: Int) {
    createReflection(
      reflection: { articleContentId: $documentId, content: $content, replyParentId: $replyParentId }
    ) {
      author {
        name
        id
      }
      id
      content
      insertedAt
      replies {
        author {
          id
          name
        }
        content
        id
        insertedAt
      }
    }
  }
`

export const ADD_OBSERVATION_COMMENT_WITH_REPLY = gql`
  mutation ($documentId: Int!, $content: String!, $replyParentId: Int) {
    createObservationReflection(
      observationReflection: { observationId: $documentId, content: $content, replyParentId: $replyParentId }
    ) {
      author {
        name
        id
        userRef
      }
      id
      content
      insertedAt
      replies {
        author {
          id
          name
        }
        content
        id
        insertedAt
      }
    }
  }
`

export const ADD_COMMENT = gql`
  mutation ($documentId: Int!, $content: String!) {
    createReflection(reflection: { articleContentId: $documentId, content: $content }) {
      author {
        name
        id
      }
      id
      content
      insertedAt
      replies {
        author {
          id
          name
        }
        content
        id
        insertedAt
      }
    }
  }
`

export const ADD_OBSERVATION_COMMENT = gql`
  mutation ($documentId: Int!, $content: String!) {
    createObservationReflection(observationReflection: { observationId: $documentId, content: $content }) {
      author {
        name
        id
        userRef
      }
      id
      content
      insertedAt
      replies {
        author {
          id
          name
        }
        content
        id
        insertedAt
      }
    }
  }
`
export const ARCHIVE_REFLECTION = gql`
  mutation archiveReflection($reflectionId: Int!) {
    archiveReflection(reflectionId: $reflectionId) {
      author {
        id
        name
      }
      content
      id
      replies {
        content
        id
        updatedAt
      }
    }
  }
`

export const ARCHIVE_OBSERVATION_REFLECTION = gql`
  mutation archiveObservationReflection($reflectionId: Int) {
    archiveObservationReflection(observationReflectionId: $reflectionId) {
      author {
        id
        name
      }
      content
      id
      replies {
        content
        id
        updatedAt
      }
    }
  }
`
