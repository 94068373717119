import React from 'react'

import { Box, Divider, Flex, FlexItem, Icon, StencilLine } from 'stardust'

import DocumentModal from '~/components/DocumentModal'

interface Props {
  loading?: boolean
  onClose(): void
}

const DocumentModalPlaceholder = ({ loading = true, onClose }: Props) => {
  return (
    <DocumentModal open={loading} onClose={onClose}>
      <Flex alignItems="center" justifyContent="center" width={1}>
        <Flex flexDirection={['column', 'row']} height="100%" width={1}>
          <Flex flexDirection="column" height="100%" width={[1, 3 / 5]}>
            <FlexItem flexGrow={1}>
              <Flex alignItems="center" height="100%" justifyContent="center" width={1}>
                <Icon fill="rgb(225,225,225)" name="imagePlaceholder" width={100} />
              </Flex>
            </FlexItem>
            <Flex alignItems="center" borderTop={1} borderColor="surfacePrimaryBorder" height="80px" px={3}>
              <StencilLine py={1} width={1 / 2} />
            </Flex>
          </Flex>

          <Flex
            borderColor="surfacePrimaryBorder"
            borderLeft="1px solid"
            flexDirection="column"
            height="100%"
            pl={3}
            width={[1, 2 / 5]}>
            <Box p={3} pl={0}>
              <StencilLine mb={2} py={1} width={2 / 3} />
              <StencilLine py={2} width={1} />
            </Box>
            <Divider borderColor="surfacePrimaryBorder" m={0} width={1} />
            <Box p={3} pl={0}>
              <StencilLine py={1} width={1} />
              <StencilLine mt={3} py={1} width={1} />
              <StencilLine mt={3} py={1} width={3 / 5} />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </DocumentModal>
  )
}

DocumentModalPlaceholder.displayName = 'DocumentModalPlaceholder'

export default React.memo(DocumentModalPlaceholder)
