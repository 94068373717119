import * as queryString from 'query-string'

import { isViewFromPlaygroundApp, isViewFromMobileApp } from '~/utils'

import * as packageJson from '../package.json'

const config = {
  APP: getRequestedApp() || process.env.PLAYGROUND_APP,
  APP_ENV: process.env.PLAYGROUND_APP_ENV,
  RELEASE_NAME: process.env.RELEASE_NAME || 'playground@test',
  APP_VERSION: packageJson.version,

  HOME_HOST: process.env.HOME_HOST || 'home.myxplor.com',
  PLAYGROUND_HOST: process.env.PLAYGROUND_HOST || 'playground.myxplor.com',

  GOLDEN_GATEWAY_API: process.env.GOLDEN_GATEWAY_API,
  HODOR_API: process.env.HODOR_API,
  STARGATE_API: process.env.STARGATE_API,

  KICKBACK_URL: process.env.KICKBACK_URL,
  PRODADMIN_URL: process.env.PRODADMIN_URL,
  STARGATE_URL: process.env.STARGATE_URL,

  GOOGLE_ANALYTICS_PROPERTY: process.env.GOOGLE_ANALYTICS_PROPERTY,
  GOOGLE_TAG_MANAGER_ID: process.env.GOOGLE_TAG_MANAGER_ID,

  APPSIGNAL_KEY_HOME: process.env.APPSIGNAL_KEY_HOME,
  APPSIGNAL_KEY_PLAYGROUND: process.env.APPSIGNAL_KEY_PLAYGROUND,

  CORALOGIX_KEY_HOME: process.env.CORALOGIX_KEY_HOME,
  CORALOGIX_KEY_PLAYGROUND: process.env.CORALOGIX_KEY_PLAYGROUND,

  TINYMCE_KEY: process.env.TINYMCE_KEY,

  UNLEASH_CLIENT_KEY: process.env.UNLEASH_CLIENT_KEY,
  UNLEASH_URL: 'https://eu.app.unleash-hosted.com/eubb7006/api/frontend/',
  UNLEASH_APP_NAME: 'PES Playground And Home',
}

export const isHome = config.APP === 'HOME'
export const isPlayground = config.APP === 'PLAYGROUND'

export const isAdminRoute = window.location.href.includes('/admin')
export const isGuestRoute = window.location.href.includes('/guest')
export const isWebView = window.location.href.includes('/webview')
export const isInPlaygroundApp = isViewFromPlaygroundApp()
export const isInMobileApp = isViewFromMobileApp()
export const isHomeWebview = isHome && isWebView ? true : false

export const isDev = config.APP_ENV === 'development'

export const homeUrl = urlWithEnv(config.HOME_HOST)
export const playgroundUrl = urlWithEnv(config.PLAYGROUND_HOST)

export const appsignalKey = isHome ? config.APPSIGNAL_KEY_HOME : config.APPSIGNAL_KEY_PLAYGROUND

export const coralogixAppName = isHome ? 'home-web' : 'playground-web'
export const coralogixKey = isHome ? config.CORALOGIX_KEY_HOME : config.CORALOGIX_KEY_PLAYGROUND

export const currentNativeAppVersion = (app: 'PLAYGROUND' | 'HOME') => {
  if (typeof window === 'undefined') return null

  const localStorageKey = app === 'HOME' ? 'homeAppVersion' : 'playgroundAppVersion'
  const versionString = window.localStorage.getItem(localStorageKey)
  if (!versionString) return null

  return versionString
}

// Allow switching between Home/Playground in local development
function getRequestedApp() {
  if (process.env.PLAYGROUND_APP_ENV === 'development') {
    const queryParams = queryString.parse(location.search)
    const requestedApp = queryParams.app as string | undefined

    if (requestedApp) {
      return requestedApp.toUpperCase()
    }

    const host = window.location.host
    if (host.match(/dev-home/)) {
      return 'HOME'
    }
    if (host.match(/dev-playground/)) {
      return 'PLAYGROUND'
    }
  }

  return null
}

function urlWithEnv(url: string) {
  const app = url.includes('home') ? 'home' : 'playground'

  return process.env.PLAYGROUND_APP_ENV === 'development'
    ? `${window.location.origin}?app=${app}`
    : `https://${url}`
}

function validateConfig() {
  Object.entries(config).forEach(([key, value]: [string, Nullable<string>]) => {
    if (value === null || value === undefined) {
      throw Error(
        `App not configured correctly - missing environment variable: ${key}.
        If running locally: Have you configured your .env?`
      )
    }
  })
}

validateConfig()

export default config
