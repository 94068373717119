import React, { ReactNode } from 'react'

import isPropValid from '@emotion/is-prop-valid'
import RPath from 'ramda/src/path'

import homeTheme from 'stardust/theme/home'
import homeWebviewTheme from 'stardust/theme/homeWebview'
import playgroundTheme from 'stardust/theme/playground'
import { Theme } from 'stardust/theme/types'
import webViewTheme from 'stardust/theme/webview'
import { StyleSheetManager, ThemeProvider, createGlobalStyle } from 'styled-components'

import { isHome, isWebView, isHomeWebview } from '~/config'

import AppThemeProvider from '~/contexts/Theme'
import { LAYERS } from '~/theme'
import colors from '~/ui-components/tokens/colors'

const fontFamily = RPath<string>(['theme', 'fontFamily'])
const primaryColor = RPath<string>(['theme', 'colors', 'primary'])
const selectionColor = RPath<string>(['theme', 'colors', 'selection'])

const appName = () => {
  if (isHomeWebview) {
    return Theme.homeWebview
  }

  if (isHome) {
    return Theme.home
  }

  return Theme.playground
}

const GlobalStyle = createGlobalStyle`
  /* @rmwc theme can be set as CSS variables on root, this is all the theme provider does */
  :root {
    --mdc-theme-primary: ${primaryColor};
    --mdc-theme-secondary: ${primaryColor};
    --mdc-theme-on-surface: white;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    font-size: 16px;
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: auto; // setting the value to "auto" to fix the modal overlapping issue in ios safari
    background-color: ${isHome ? '#242527' : '#ffffff'};
    color: rgba(0, 0, 0, 0.8);
    font-family: ${fontFamily};
    font-size: 1rem;
    font-weight: normal;
    line-height: ${isWebView ? 1.6 : 1};
    margin: 0;
    padding: 0;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  ol:not(.mceContent ol):not([data-slate-editor='true'] ol), 
  ul:not(.mceContent ul):not([data-slate-editor='true'] ul) {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li:not(.mceContent li):not([data-slate-editor='true'] li) {
    margin: 0;
    padding: 0;
  }

  hr:not(.mceContent hr) {
    border: 0;
    margin: 0;
    padding: 0;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  input,
  button,
  select,
  textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 0;
    border-radius: 0;
    line-height: inherit;
    margin: 0;
    outline: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p:not(.mceContent p) {
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  a {
    cursor: pointer;
  }

  ::selection {
    background: ${selectionColor};
  }

    /* FullScreen layout is 1000 z-index, +10 to make context menu work */
  .react-contextmenu {
    z-index: ${LAYERS.Dropdown};
  }

  .ReactModal__Overlay, .ReactModal__Body--open {
    overflow-y: hidden;
  }

  /* TinyMCE dialogue box customization */
  div.tox.tox-silver-sink.tox-tinymce-aux {
    .tox-collection--list .tox-collection__item--active {
      background-color: ${colors.nebulaBlue5};
    }
    .tox-collection--toolbar .tox-collection__item--enabled, .tox-collection--toolbar .tox-collection__item--enabled.tox-collection__item--active, .tox-collection--toolbar .tox-collection__item--enabled.tox-collection__item--active:hover {
      background-color: ${colors.nebulaBlue0};
    }
    .tox-collection--toolbar .tox-collection__item--active:focus::after {
      box-shadow: 0 0 0 2px ${colors.nebulaBlue5};
    }
    .tox-button {
      background-color: ${colors.nebulaBlue5};
      border-color: ${colors.nebulaBlue5};
    }
    .tox-button--naked {
      color: ${colors.cosmicShade0}
    }
  }
`

function getAppTheme(name: Theme) {
  switch (name) {
    case Theme.home:
    default:
      return homeTheme
    case Theme.playground:
      return playgroundTheme
    case Theme.webview:
      return webViewTheme
    case Theme.homeWebview:
      return homeWebviewTheme
  }
}

interface ShouldForwardProp {
  (propName: string, target: any): boolean
}

const shouldForwardProp: ShouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}

interface ThemeWrapperProps {
  children: ReactNode
}

const ThemeWrapper: React.FC<ThemeWrapperProps> = ({ children }) => {
  const theme = getAppTheme(appName())
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <AppThemeProvider theme={theme}>{children}</AppThemeProvider>
      </ThemeProvider>
    </StyleSheetManager>
  )
}

ThemeWrapper.displayName = 'Theme'
export default ThemeWrapper
