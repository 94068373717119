import { useEffect, useState } from 'react'

import { BREAKPOINTS } from '~/theme'
import { isTouchDevice } from '~/utils'

export const useScreenDetector = () => {
  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowResize = () => setWidth(window.innerWidth)

  const tabletFeatureDetection = (isMobile: boolean) => {
    if (typeof window === 'undefined' || isMobile) return false

    const userAgent = window.navigator.userAgent.toLowerCase()

    const hasTouch = isTouchDevice()
    const isMobileTabletDevice = /ipad|iphone|ipod|android/.test(userAgent)

    return hasTouch || isMobileTabletDevice
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const isMobile = width <= BREAKPOINTS.sm
  const isTablet = (width > BREAKPOINTS.sm && width <= BREAKPOINTS.md) || tabletFeatureDetection(isMobile)
  const isDesktop = !isMobile && !isTablet

  return { isMobile, isTablet, isDesktop }
}
