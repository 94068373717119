import React, { useContext } from 'react'

import { Box, Card, Flex, Image, SecondaryButton } from 'stardust'

import homeIcon from '~/assets/images/home.svg'
import playgroundIcon from '~/assets/images/playground.png'
import FullScreen from '~/components/Layouts/FullScreen'
import Modal from '~/components/Modal'
import UserStrategySelector from '~/components/UserStrategySelector'
import { isHome, isInMobileApp, isWebView } from '~/config'

import { StrategyContext } from '~/contexts/Strategy'

const UserStrategyPrompt = () => {
  const {
    toggleStrategyPrompt,
    state: { selectedStrategyRef, strategies, showStrategyPrompt },
  } = useContext(StrategyContext)

  // Don't need a selector if we're not an Xplor ID user, or if there's only
  // one linked profile
  if (strategies.length < 2 || isWebView || isInMobileApp) {
    return null
  }

  return selectedStrategyRef ? (
    <Modal open={showStrategyPrompt} onClose={toggleStrategyPrompt}>
      <Card width="500px">
        <UserStrategySelector />
        <Box borderColor="surfacePrimaryBorder" borderTop="1px solid" px={3} py={2} pt={1}>
          <SecondaryButton onClick={toggleStrategyPrompt}>Cancel</SecondaryButton>
        </Box>
      </Card>
    </Modal>
  ) : (
    <FullScreen>
      <Flex alignItems="center" flexDirection="column" height="100vh" justifyContent="center" width="100vw">
        <Card width="500px">
          <Flex justifyContent="center" width={1}>
            <Box width={80} pb={3} pt={4}>
              <Image
                src={isHome ? homeIcon : playgroundIcon}
                alt={isHome ? 'Xplor home' : 'Xplor playground'}
              />
            </Box>
          </Flex>
          <UserStrategySelector />
        </Card>
      </Flex>
    </FullScreen>
  )
}

UserStrategyPrompt.displayName = 'UserStrategyPrompt'

export default React.memo(UserStrategyPrompt)
