import React, { useState, useEffect } from 'react'

import { Box, Flex, Text, Icon } from 'stardust'

import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

import Outcomes from './Outcomes'

const S = {
  Flex: styled(Flex)`
    border: 2px solid;
    border-color: ${colors.cosmicShade6};
    background-color: ${colors.cosmicShade1};
    border-radius: 16px;
    padding: 10px;
  `,
  BottomLine: styled(Flex)`
    border-bottom: 2px solid;
    border-color: ${colors.cosmicShade6};
  `,
}

interface Props {
  categories: Playground.LearningFrameworkCategory[]
  outcomes: Playground.LearningFrameworkOutcome[]
  showCategories: boolean
  clickedOutcomes: Playground.LearningFrameworkOutcome[]
  onOutcomeClick: (outcome: Playground.LearningFrameworkOutcome) => void
  expandAll: boolean
}

const Categories = ({
  outcomes,
  showCategories,
  categories,
  onOutcomeClick,
  clickedOutcomes,
  expandAll,
}: Props) => {
  const [categoryStates, setCategoryStates] = useState<Record<string, boolean>>({})

  useEffect(() => {
    if (expandAll) {
      const newCategoryStates: Record<string, boolean> = {}
      categories.forEach((category) => {
        newCategoryStates[category.id] = true
      })
      setCategoryStates(newCategoryStates)
    }
  }, [expandAll, categories])

  const handleCategoryClick = (categoryId: number) => {
    setCategoryStates((prevStates) => ({
      ...prevStates,
      [categoryId]: !prevStates[categoryId],
    }))
  }

  const groupedOutcomes: Record<number, Playground.LearningFrameworkOutcome[]> = outcomes.reduce(
    (
      result: Record<number, Playground.LearningFrameworkOutcome[]>,
      outcome: Playground.LearningFrameworkOutcome
    ) => {
      const categoryId = outcome.primaryId

      if (result[categoryId]) {
        result[categoryId].push(outcome)
      } else {
        result[categoryId] = [outcome]
      }

      return result
    },
    {}
  )

  const groupedCategories = categories.map((category: Playground.LearningFrameworkCategory) => ({
    ...category,
    outcomes: groupedOutcomes[category.id] || [],
  }))

  if (!showCategories) {
    return null
  }

  return (
    <Box m={3}>
      <S.Flex flexDirection="column">
        {groupedCategories.map((category, index) => {
          const isCategoryOpen = categoryStates[category.id]
          return category.outcomes.length > 0 ? (
            <Box
              key={category.id}
              cursor="pointer"
              mb={index == groupedCategories.length - 1 && groupedCategories.length > 1 ? 2 : undefined}>
              <Flex
                justifyContent="space-between"
                onClick={() => handleCategoryClick(category.id)}
                alignItems="center"
                mt={groupedCategories.length > 1 ? 1 : undefined}
                mb={index < groupedCategories.length - 1 ? 3 : undefined}>
                <Flex>
                  <Icon name={isCategoryOpen ? 'frameworkCategory' : 'frameworkCategoryOpen'} mr={3} />
                  <Text bold>{category.name}</Text>
                </Flex>
                <Icon
                  color={colors.cosmicShade15}
                  name={isCategoryOpen ? 'arrowUp' : 'arrowDown'}
                  width={12}
                  mr={3}
                />
              </Flex>
              <Outcomes
                outcomes={category.outcomes}
                showOutcomes={categoryStates[category.id]}
                onOutcomeClick={onOutcomeClick}
                clickedOutcomes={clickedOutcomes}
              />
              {index < groupedCategories.length - 1 && (
                <Box borderBottom="1px solid" borderColor={colors.cosmicShade6} mb={2}></Box>
              )}
            </Box>
          ) : null
        })}
      </S.Flex>
    </Box>
  )
}

Categories.displayName = 'Categories'
export default React.memo(Categories)
