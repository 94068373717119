import React, { useContext } from 'react'

import { Box, Card, Flex, Image, SecondaryButton } from 'stardust'

import homeIcon from '~/assets/images/home.svg'
import playgroundIcon from '~/assets/images/playground.png'
import FullScreen from '~/components/Layouts/FullScreen'
import Modal from '~/components/Modal'
import ServiceSelector from '~/components/ServiceSelector'
import { isHome } from '~/config'

import { ServiceContext, useSelectedServiceFkey } from '~/contexts/Service'

const ServiceSelectPrompt = ({ enableSearch }: { enableSearch: boolean }) => {
  const {
    toggleServicePrompt,
    state: { showServicePrompt },
  } = useContext(ServiceContext)
  const selectedServiceFkey = useSelectedServiceFkey()

  return selectedServiceFkey ? (
    <Modal open={showServicePrompt} onClose={toggleServicePrompt}>
      <Card width="500px">
        <ServiceSelector enableSearch={enableSearch} />
        <Box borderColor="surfacePrimaryBorder" borderTop="1px solid" px={3} py={2} pt={1}>
          <SecondaryButton onClick={toggleServicePrompt}>Cancel</SecondaryButton>
        </Box>
      </Card>
    </Modal>
  ) : (
    <FullScreen>
      <Flex alignItems="center" flexDirection="column" height="100vh" justifyContent="center" width="100vw">
        <Card width="500px">
          <Flex justifyContent="center" width={1}>
            <Box pb={3} pt={4} width={80}>
              <Image
                src={isHome ? homeIcon : playgroundIcon}
                alt={isHome ? 'Xplor home' : 'Xplor playground'}
              />
            </Box>
          </Flex>
          <ServiceSelector enableSearch={enableSearch} />
        </Card>
      </Flex>
    </FullScreen>
  )
}

ServiceSelectPrompt.displayName = 'ServiceSelectPrompt'

export default React.memo(ServiceSelectPrompt)
