import React from 'react'

import 'react-toastify/dist/ReactToastify.css'

import { ToastContainer, Slide } from 'react-toastify'

import styled from 'styled-components'
import colors from 'ui-components/tokens/colors'

const S = {
  ToastContainer: styled(ToastContainer)`
    &&&.Toastify__toast-container {
      width: max-content;
    }
    .Toastify__toast {
      background-color: ${colors.nebulaBlue0};
      box-shadow: none;
      border: 1px solid ${colors.nebulaBlue6};
      border-radius: 16px;
    }
    .Toastify__close-button {
      margin-top: 16px;
    }
  `,
}

const StyledToastMessage = () => {
  return (
    <S.ToastContainer
      position="top-center"
      icon={false}
      hideProgressBar={true}
      autoClose={2000}
      closeOnClick={true}
      pauseOnHover={true}
      draggable={true}
      theme="light"
      transition={Slide}
    />
  )
}

StyledToastMessage.displayName = 'StyledToastMessage'

export default React.memo(StyledToastMessage)
