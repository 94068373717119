import React from 'react'

import { Flex } from 'stardust'

import Modal, { ModalProps } from './Modal'

const DocumentModal = ({ children, ...props }: ModalProps) => {
  return (
    <Modal {...props}>
      <Flex
        height={['80vh']}
        overflowX="hidden"
        overflowY="auto"
        width={['90vw', '80vw', 1200]}
        data-test="document-modal">
        {children}
      </Flex>
    </Modal>
  )
}

DocumentModal.displayName = 'DocumentModal'

export default React.memo(DocumentModal)
