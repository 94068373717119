import React from 'react'

import { Box, Flex, Icon, Image, Text, Truncate } from 'stardust'

import EmptyImage from '~/components/Loading/EmptyImage'

interface Props {
  media: Playground.MediaItem
  type: string
}

const MediaImage = ({ media, type }: Props) => {
  const { path, coverUrl, metadata, url } = media

  if (metadata && metadata.content_type && new RegExp('application|text').test(metadata.content_type)) {
    const src = metadata.filename || path
    return (
      <Box data-test="media-file">
        <Flex
          border={1}
          borderColor="cosmicShade6"
          borderRadius={1}
          justifyContent="center"
          alignItems="center"
          width="128px"
          height="128px"
          mb={2}>
          <Icon color="primary" name="file" width={64} />
        </Flex>
        <Truncate textAlign="center">
          <Text.span fontSize={0} title={src}>
            {src}
          </Text.span>
        </Truncate>
      </Box>
    )
  } else if (url === null) {
    return null
  } else {
    return coverUrl ? (
      <Image
        src={coverUrl}
        maxWidth={200}
        maxHeight={200}
        alt={metadata.filename || path}
        data-test="media-image"
      />
    ) : (
      <EmptyImage />
    )
  }
}

export default MediaImage
