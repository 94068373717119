// Will need to check with Nicha as to what our new colour pallete will be

const palette = {
  superGiantRed0: '#FCE6EC',
  superGiantRed1: '#F8C0CF',
  superGiantRed2: '#F498B0',
  superGiantRed3: '#EF7292',
  superGiantRed4: '#EA587B',
  superGiantRed5: '#E64766',
  superGiantRed6: '#FDE8EE',
  superGiantRed7: '#BF3D5D',
  superGiantRed8: '#AA3859',
  superGiantRed9: '#833050',
  superGiantRed10: '#DB3B03',
  superGiantRed11: '#D21D3E',
  superGiantRed12: '#D54263',
}

export default {
  ...palette,
  superGiantRed: palette.superGiantRed6,
}
