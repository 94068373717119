import React, { ReactNode } from 'react'

import RAlways from 'ramda/src/always'
import RCond from 'ramda/src/cond'
import RPropEq from 'ramda/src/propEq'

import { Flex } from 'stardust'
import styled from 'styled-components'
import { borderRadius } from 'styled-system'

const Moment = () => (
  <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#A565EF"
    />
    <path
      d="M6.95337 8.12512C7.08281 8.12512 7.18774 8.23006 7.18774 8.3595C7.18774 8.48894 7.08281 8.59387 6.95337 8.59387C6.82393 8.59387 6.71899 8.48894 6.71899 8.3595C6.71899 8.23006 6.82393 8.12512 6.95337 8.12512"
      stroke="white"
      strokeWidth="0.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.9458 12.1401C6.739 13.6443 8.2997 14.5857 10.0002 14.5858C10.1052 14.5858 10.2089 14.582 10.3127 14.5751C10.3989 14.5693 10.4846 14.5612 10.5696 14.5508"
      stroke="white"
      strokeWidth="0.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0245 9.70193C16.8614 5.86083 13.6436 2.86283 9.80056 2.97162C5.95754 3.08041 2.91443 6.25565 2.96898 10.0998C3.02354 13.944 6.15552 17.0316 10.0001 17.0313C10.1051 17.0313 10.2088 17.0313 10.3126 17.0238"
      stroke="white"
      strokeWidth="0.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5288 8.53753C11.7925 8.11615 12.2545 7.86023 12.7516 7.86023C13.2487 7.86023 13.7108 8.11615 13.9744 8.53753"
      stroke="white"
      strokeWidth="0.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0244 9.7019L10.3125 17.0238"
      stroke="white"
      strokeWidth="0.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3057 17.0313V16.4201C10.3057 12.7056 13.3168 9.69446 17.0313 9.69446"
      stroke="white"
      strokeWidth="0.9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Post = () => (
  <svg width="32" height="32" viewBox="0 0 133 133" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="67.5" cy="67.5" r="67.5" fill="#D21D3E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.9813 45.3742C37.4974 45.3742 37.1051 45.7621 37.1051 46.2405V84.6209C37.1051 85.2968 37.8532 85.7123 38.4371 85.3607L52.7788 76.7238C53.5203 76.2772 54.3717 76.041 55.24 76.041H76.8833C77.3672 76.041 77.7594 75.6532 77.7594 75.1747V46.2405C77.7594 45.7621 77.3672 45.3742 76.8833 45.3742H37.9813ZM33.25 46.2405C33.25 43.6569 35.3683 41.5625 37.9813 41.5625H76.8833C79.4963 41.5625 81.6146 43.6569 81.6146 46.2405V75.1747C81.6146 77.7583 79.4963 79.8527 76.8833 79.8527H55.24C55.0792 79.8527 54.9215 79.8965 54.7842 79.9792L40.4425 88.6161C37.2896 90.5149 33.25 88.271 33.25 84.6209V46.2405Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.24 79.8527L55.2406 85.6186C55.2406 86.097 55.6328 86.4849 56.1167 86.4849H77.76C78.6283 86.4849 79.4797 86.7211 80.2212 87.1676L94.5629 95.8045C95.1468 96.1562 95.8948 95.7406 95.8948 95.0647V56.6843C95.8948 56.2059 95.5026 55.818 95.0187 55.818H81.6161V52.0063H95.0187C97.6317 52.0063 99.75 54.1007 99.75 56.6843V95.0647C99.75 98.7148 95.7104 100.959 92.5575 99.0599L78.2158 90.423C78.0785 90.3403 77.9208 90.2966 77.76 90.2966H56.1167C53.5037 90.2966 51.3854 88.2021 51.3854 85.6186V82.0269L54.7842 79.9792C54.9215 79.8965 55.0792 79.8527 55.24 79.8527Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.9429 63.9424C58.9429 63.1184 59.7324 62.4504 60.7062 62.4504H67.7594C68.7332 62.4504 69.5227 63.1184 69.5227 63.9424C69.5227 64.7664 68.7332 65.4344 67.7594 65.4344H60.7062C59.7324 65.4344 58.9429 64.7664 58.9429 63.9424Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.9429 55.7364C58.9429 54.5004 59.7599 53.4984 60.7677 53.4984L70.7208 53.4984C71.7285 53.4984 72.5455 54.5004 72.5455 55.7364C72.5455 56.9724 71.7285 57.9744 70.7208 57.9744L60.7677 57.9744C59.7599 57.9744 58.9429 56.9724 58.9429 55.7364Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.6443 57.2858V63.139H50.5736V57.2858H44.6443ZM43.598 53.4984C42.0569 53.4984 40.8077 54.7316 40.8077 56.2529V64.1719C40.8077 65.6931 42.0569 66.9263 43.598 66.9263H51.62C53.161 66.9263 54.4102 65.6931 54.4102 64.1719V56.2529C54.4102 54.7316 53.161 53.4984 51.62 53.4984H43.598Z"
      fill="white"
    />
  </svg>
)

const Plan = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
      fill="#8068BA"
    />
    <path
      d="M10.0417 10.3929C10.0417 10.1364 10.2469 9.92857 10.5 9.92857H16.3705C16.4919 9.92862 16.6084 9.97756 16.6943 10.0645L17.9492 11.2707C18.035 11.3577 18.0833 11.4758 18.0833 11.5988V13.5357C18.0833 13.7921 18.2885 14 18.5417 14C18.7948 14 19 13.7921 19 13.5357V11.5986C18.9999 11.2295 18.8551 10.8753 18.5975 10.6142L17.3423 9.40764C17.0845 9.1467 16.7349 9.00008 16.3705 9H10.5C9.74061 9 9.125 9.6236 9.125 10.3929V20.6071C9.125 21.3764 9.74061 22 10.5 22H13.5417C13.7948 22 14 21.7564 14 21.5C14 21.2436 13.7948 21 13.5417 21H10.5C10.2469 21 10.0417 20.7921 10.0417 20.5357V10.3929Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9052 14.8709C22.4371 14.8136 22.9736 14.8136 23.5055 14.8709C23.7382 14.8959 23.9224 15.079 23.9488 15.3115C24.2888 18.3019 22.8845 20.5061 21.2 21.7532C20.3621 22.3735 19.4408 22.7686 18.6077 22.9049C17.985 23.0067 17.342 22.9723 16.8342 22.7062L15.6869 23.8535C15.4916 24.0488 15.175 24.0488 14.9798 23.8535C14.7845 23.6583 14.7845 23.3417 14.9798 23.1464L16.1239 22.0023C15.9391 21.6564 15.8637 21.2487 15.8642 20.8376C15.865 20.2463 16.0205 19.5886 16.3037 18.9396C16.8701 17.6417 17.9817 16.3006 19.5857 15.5132C19.8272 15.3946 20.1192 15.4884 20.2465 15.7255L20.6595 16.4946L21.5354 15.1018C21.6169 14.9723 21.7531 14.8872 21.9052 14.8709ZM18.4462 21.918C18.1104 21.9729 17.8334 21.9684 17.6148 21.9256L19.5762 19.9642C19.7715 19.769 19.7715 19.4524 19.5762 19.2571C19.381 19.0618 19.0644 19.0618 18.8691 19.2571L16.9002 21.226C16.8771 21.1142 16.8641 20.9855 16.8642 20.8389C16.8648 20.4136 16.9791 19.8922 17.2203 19.3396C17.6512 18.352 18.4596 17.3275 19.6032 16.6412L20.1848 17.7245C20.269 17.8814 20.4303 17.9816 20.6082 17.9877C20.7861 17.9938 20.9538 17.9049 21.0486 17.7542L22.25 15.844C22.4962 15.8266 22.7432 15.8234 22.9898 15.8342C23.1191 18.2039 21.9725 19.937 20.605 20.9495C19.878 21.4877 19.1027 21.8106 18.4462 21.918Z"
      fill="white"
    />
  </svg>
)

const Story = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
      fill="#01A39D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 12C12.4477 12 12 12.4477 12 13V14C12 14.5523 12.4477 15 13 15H14C14.5523 15 15 14.5523 15 14V13C15 12.4477 14.5523 12 14 12H13ZM14 13H13V14H14V13Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 18C12 17.4477 12.4477 17 13 17H14C14.5523 17 15 17.4477 15 18V19C15 19.5523 14.5523 20 14 20H13C12.4477 20 12 19.5523 12 19V18ZM13 18H14V19H13V18Z"
      fill="white"
    />
    <path
      d="M16 12.5C16 12.2239 16.2239 12 16.5 12H18.5C18.7761 12 19 12.2239 19 12.5C19 12.7761 18.7761 13 18.5 13H16.5C16.2239 13 16 12.7761 16 12.5Z"
      fill="white"
    />
    <path
      d="M16.5 17C16.2239 17 16 17.2239 16 17.5C16 17.7761 16.2239 18 16.5 18H18.5C18.7761 18 19 17.7761 19 17.5C19 17.2239 18.7761 17 18.5 17H16.5Z"
      fill="white"
    />
    <path
      d="M16 14.5C16 14.2239 16.2239 14 16.5 14H19.5C19.7761 14 20 14.2239 20 14.5C20 14.7761 19.7761 15 19.5 15H16.5C16.2239 15 16 14.7761 16 14.5Z"
      fill="white"
    />
    <path
      d="M16.5 19C16.2239 19 16 19.2239 16 19.5C16 19.7761 16.2239 20 16.5 20H19.5C19.7761 20 20 19.7761 20 19.5C20 19.2239 19.7761 19 19.5 19H16.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 9C10.4477 9 10 9.44772 10 10V22C10 22.5523 10.4477 23 11 23H21C21.5523 23 22 22.5523 22 22V10C22 9.44772 21.5523 9 21 9H11ZM21 10H11V22H21V10Z"
      fill="white"
    />
  </svg>
)

const Table = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
      fill="#EC7702"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0769 9C9.48215 9 9 9.48215 9 10.0769V21.9231C9 22.5178 9.48215 23 10.0769 23H21.9231C22.5178 23 23 22.5178 23 21.9231V10.0769C23 9.48215 22.5178 9 21.9231 9H10.0769ZM21.9231 10.0769H16.5385V15.4615H21.9231V10.0769ZM21.9231 16.5385H16.5385V21.9231H21.9231V16.5385ZM15.4615 15.4615V10.0769H10.0769V15.4615H15.4615ZM10.0769 16.5385H15.4615V21.9231H10.0769V16.5385Z"
      fill="white"
    />
  </svg>
)

const Circle = styled(Flex)<{ size?: number }>`
  width: ${(props) => (props.size ? props.size + 'px' : '20px')};
  height: ${(props) => (props.size ? props.size + 'px' : '20px')};
  ${borderRadius};
`

interface Props {
  type: string
  size?: number
}

const moment = RPropEq('type', 'moment')
const post = RPropEq('type', 'observation')
const plan = RPropEq('type', 'learning_plan')
const story = RPropEq('type', 'learning_story')
const table = RPropEq('type', 'learning_table')

export const colorOfType = RCond<Props, Nullable<string>>([
  [moment, RAlways('moment')],
  [post, RAlways('observation')],
  [plan, RAlways('plan')],
  [story, RAlways('story')],
  [table, RAlways('table')],
])

export const iconOfType = RCond<Props, ReactNode>([
  [moment, () => <Moment />],
  [post, () => <Post />],
  [plan, () => <Plan />],
  [story, () => <Story />],
  [table, () => <Table />],
])

const DocumentIcon = ({ size, ...props }: Props) => (
  <Circle
    size={size}
    alignItems="center"
    bg={colorOfType(props) || 'defaultColor'}
    borderRadius={4}
    justifyContent="center">
    {iconOfType(props)}
  </Circle>
)

DocumentIcon.displayName = 'DocumentIcon'

export default React.memo(DocumentIcon)
