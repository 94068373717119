import React from 'react'

import styled from 'styled-components'
import {
  HeightProps,
  MaxHeightProps,
  MaxWidthProps,
  MinHeightProps,
  MinWidthProps,
  SpaceProps,
  WidthProps,
  height,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  space,
  width,
} from 'styled-system'

export type ImageProps = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'height' | 'width'> &
  HeightProps &
  MaxHeightProps &
  MaxWidthProps &
  MinHeightProps &
  MinWidthProps &
  SpaceProps &
  WidthProps

const Image = styled.img<ImageProps>`
  display: block;
  max-width: 100%;

  ${height};
  ${maxHeight};
  ${maxWidth};
  ${minHeight};
  ${minWidth};
  ${space};
  ${width};
`

Image.displayName = 'Image'

Image.defaultProps = {
  height: 'auto',
}

export default React.memo(Image)
