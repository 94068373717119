import { currentNativeAppVersion } from '~/config'

export default (app: 'PLAYGROUND' | 'HOME', targetVersion: string): number | null => {
  const currentVersion = currentNativeAppVersion(app)
  if (!currentVersion) return null // if currentVersion is not available
  const parseVersion = (version: string) => version.split('.').map(Number)

  const [major1, minor1, patch1] = parseVersion(currentVersion)
  const [major2, minor2, patch2] = parseVersion(targetVersion)

  // Compare major versions
  if (major1 > major2) return 1 // if currentVersion is greater than targetVersion
  if (major1 < major2) return -1 // if currentVersion is less than targetVersion

  // Compare minor versions
  if (minor1 > minor2) return 1 // if currentVersion is greater than targetVersion
  if (minor1 < minor2) return -1 // if currentVersion is less than targetVersion

  // Compare patch versions
  if (patch1 > patch2) return 1 // if currentVersion is greater than targetVersion
  if (patch1 < patch2) return -1 // if currentVersion is less than targetVersion

  // Versions are the same
  return 0
}
