import React from 'react'

import TinyMCEEditor from '~/ui-components/TinyMCEEditor'

import { FieldProps } from '../../types'

interface DescriptionProps extends FieldProps {
  description?: string
  richTextDescription?: string
  borderRadius?: string | number
}

const ModalDescription = ({ onChange, description, richTextDescription }: DescriptionProps) => {
  const characterLimit = 15000

  const onTinyMCEChange = (plainText: string, richText: string) => {
    onChange({
      name: 'description',
      value: plainText,
    })
    onChange({
      name: 'richTextDescription',
      value: richText,
    })
  }

  function convertNewLinesToBrTags(inputString?: string) {
    if (!inputString) return ''

    // Escape special characters to prevent XSS attacks
    const escapedString = inputString.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')

    // Replace all newline characters with <br/>
    const result = escapedString.replace(/(?:\r\n|\r|\n)/g, '<br/>')

    return result
  }

  return (
    <TinyMCEEditor
      value={richTextDescription || convertNewLinesToBrTags(description)}
      placeholder="Description"
      maxLength={characterLimit}
      onChange={onTinyMCEChange}
    />
  )
}
ModalDescription.displayName = 'ModalDescription'

export default React.memo(ModalDescription)
