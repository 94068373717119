import React, { useState } from 'react'

import { useMutation } from '@apollo/client'

import { Box, Empty, Flex } from 'stardust'
import styled from 'styled-components'

import empty from '~/assets/images/empty.svg'

import { groupWithInsertedAt } from '~/components/Documents/core'
import SectionHeading from '~/components/Documents/SectionHeading'
import { determineFileType } from '~/modules/media'
import { InfiniteScroll, isNilOrEmpty } from '~/utils'

import MediaItem from './MediaItem'
import { ARCHIVE_MEDIA } from './mutations'

const ScrollContainer = styled(Box)`
  height: 500px;
  overflow-y: scroll;
`

interface MediaData {
  service: {
    media: {
      edges: {
        cursor: string
        node: Playground.MediaItem
      }[]
    }
  }
}

interface Props {
  type: 'all' | 'file' | 'image' | 'video'
  data: MediaData
  fetchMoreMedia: (props: any) => any
  onMediaSelect(mediaItem: Playground.MediaItem): void
}

const MediaList = ({ type, data, fetchMoreMedia, onMediaSelect }: Props) => {
  const [scrollCtx, setScrollCtx] = useState({
    innerHeight: 0,
    pageYOffset: 0,
    scrollHeight: 0,
  })

  const onScroll = (e: any) => {
    setScrollCtx({
      innerHeight: e.target.clientHeight,
      pageYOffset: e.target.scrollTop,
      scrollHeight: e.target.scrollHeight,
    })
  }

  const [archiveMedia] = useMutation(ARCHIVE_MEDIA)

  const path = ['service', 'media']
  const mediaEdges = data.service.media?.edges

  if (!mediaEdges) {
    return (
      <Flex justifyContent="center" height="532px">
        <Empty heading="Media" image={empty} verticallyCenter={true} />
      </Flex>
    )
  }

  const mediaNodes = mediaEdges.map((edge: any) => edge.node) as Playground.MediaItem[]

  if (isNilOrEmpty(mediaNodes)) {
    return (
      <Flex justifyContent="center" height="532px">
        <Empty
          heading={type === 'all' ? 'Media Found' : `${type}s Found`}
          image={empty}
          verticallyCenter={true}
        />
      </Flex>
    )
  }

  const mediaByDate = groupWithInsertedAt(mediaNodes) as any
  if (!mediaByDate) {
    return null
  }

  const onDelete = (event: React.MouseEvent<HTMLDivElement>, id: number, type: string) => {
    event.stopPropagation()
    const doubleCheck = confirm(
      `Deleting this ${type} will remove it from anywhere it is currently being used. Media which appears as the thumbnail for a document may take up to 24 hours to be fully removed. Are you sure you want to delete this ${type}?`
    )
    if (doubleCheck) {
      archiveMedia({
        variables: { id },
        update(cache) {
          cache.evict({
            id: cache.identify({ __typename: 'Media', id }),
          })
        },
      })
    }
  }

  return (
    <Box bg="surfaceSecondary" overflow="hidden" pt={4}>
      <InfiniteScroll
        data={data}
        fetchMore={fetchMoreMedia}
        innerHeight={scrollCtx.innerHeight}
        pageYOffset={scrollCtx.pageYOffset}
        scrollHeight={scrollCtx.scrollHeight}
        path={path}>
        <ScrollContainer onScroll={onScroll}>
          {mediaByDate.map((mediaForDate: Playground.MediaItem[]) => {
            let results = mediaForDate

            if (type !== 'all') {
              results = mediaForDate.filter((media) => type === determineFileType(media.mimeType))

              if (results.length === 0) {
                return null
              }
            }

            const groupedDate = results[0].insertedAt

            return (
              <Box key={groupedDate} px={3} mb={4}>
                <SectionHeading date={groupedDate} />

                <Flex alignItems="center" flexWrap="wrap" px={3}>
                  {results.map((media) => (
                    <MediaItem
                      key={media.id}
                      media={media}
                      type={type}
                      onMediaSelect={onMediaSelect}
                      onDelete={onDelete}
                    />
                  ))}
                </Flex>
              </Box>
            )
          })}
        </ScrollContainer>
      </InfiniteScroll>
    </Box>
  )
}

MediaList.displayName = 'MediaList'

export default React.memo(MediaList)
