import React, { ReactNode, useContext } from 'react'

import { useQuery } from '@apollo/client'
import { navigate } from '@reach/router'

import { Anchor, Box, Container, Flex, Heading, Icon, Text } from 'stardust'
import styled from 'styled-components'

import DropdownMenu from '~/components/DropdownMenu'
import DropdownSelector from '~/components/DropdownSelector'

import NoChildAgesSelected from '~/pages/LearningAnalysis/NoChildAgesSelected'
import NoLearningData from '~/pages/LearningAnalysis/NoLearningData'

import { HEADER_HEIGHT, LAYERS } from '~/theme'
import { ScrollUp } from '~/utils'

import LearningAnalysisProvider, { LearningAnalysisContext } from './Context'
import FirstUseBanner from './FirstUseBanner'
import Heatmap from './Heatmap'
import Loading from './Loading'
import { GET_LEARNING_RECORDS } from './queries'

import ComparisonTile from './Tiles/ComparisonTile'
import EvidenceRequiredTile from './Tiles/EvidenceRequiredTile'
import OutcomeAreasTile from './Tiles/OutcomeAreasTile'
import ProgressOverTimeTile from './Tiles/ProgressOverTimeTile'
import TopEmergingChildrenTile from './Tiles/TopEmergingChildrenTile'

interface Props {
  serviceFkey: string
}

const StickyHeader = styled(Box)`
  position: sticky;
  top: ${HEADER_HEIGHT};
`

const Overline = ({ children }: { children: ReactNode }) => (
  <Text.span fontSize={0} letterSpacing="3.2px" mb={2} upper>
    {children}
  </Text.span>
)

const LearningAnalysis = ({ serviceFkey }: Props) => {
  const {
    ageGroup,
    childAgeRanges,
    childAgeRangeLabel,
    children,
    cohortsEnabled,
    dateRange,
    filters,
    framework,
    menus,
    scoreChanges,
    onChildAgeRangeChange,
  } = useContext(LearningAnalysisContext)

  const { data, error, loading } = useQuery(GET_LEARNING_RECORDS, {
    skip: !framework,
    variables: {
      ageGroupId: ageGroup?.id,
      dateRange: { first: dateRange.from, last: dateRange.to },
      frameworkId: framework?.id,
      serviceFkey,
    },
  })

  if (error) {
    return <Box p={3}>Something went wrong</Box>
  }

  if (!framework || loading) {
    return <Loading />
  }

  const records = data.service.learningRecords.filter((record: Playground.LearningRecord) => {
    return children.map((child) => child.fkey).includes(record.childFkey)
  })

  const allLearningRecords = [...scoreChanges, ...records]

  const LearningDataPanel = () => {
    return (
      <Container>
        <Flex flexDirection="column" mb={4} mt={5}>
          <Overline>Compare Children or Groups</Overline>
          <ComparisonTile
            filters={filters}
            localStorageKeyLeft={'cohortComparisonLeftFilter'}
            localStorageKeyRight={'cohortComparisonRightFilter'}
            records={allLearningRecords}
          />
        </Flex>

        <Flex mb={4}>
          <Flex flexDirection="column" pr={3} width={2 / 3}>
            <Overline>Outcome Areas (Top 7)</Overline>
            <OutcomeAreasTile
              filters={filters}
              localStorageKey={'cohortOutcomesChildFilter'}
              records={allLearningRecords}
            />
          </Flex>

          <Flex flexDirection="column" pl={3} width={1 / 3}>
            <Overline>Needing Evidence</Overline>
            <EvidenceRequiredTile
              filters={filters}
              localStorageKey={'cohortEvidenceFilter'}
              records={allLearningRecords}
            />
          </Flex>
        </Flex>

        {cohortsEnabled && (
          <Flex mb={4}>
            <Flex flexDirection="column" pr={3} width={1 / 3}>
              <Overline>Top Emerging Children</Overline>
              <TopEmergingChildrenTile
                filters={filters}
                localStorageKey={'cohortEmergingChildrenFilter'}
                records={allLearningRecords}
              />
            </Flex>
            <Flex flexDirection="column" pl={3} width={2 / 3}>
              <Overline>Child Progress Over Time</Overline>
              <ProgressOverTimeTile
                filters={filters}
                localStoragePrimaryKey={'cohortProgressPrimaryFilter'}
                localStorageSecondaryKey={'cohortProgressSecondaryFilter'}
                records={allLearningRecords}
              />
            </Flex>
          </Flex>
        )}

        <Flex flexDirection="column" mb={5} width={1}>
          <Heading.h5 bold lineHeight={1} mb={0} mt={0}>
            Children
          </Heading.h5>
          <Heatmap
            filters={filters}
            localStorageKey={'cohortHeatmapFilter'}
            records={allLearningRecords}
            serviceFkey={serviceFkey}
          />
        </Flex>
      </Container>
    )
  }

  const MessageIfEmpty = childAgeRanges.length == 0 ? NoChildAgesSelected : NoLearningData
  const LearningData = records.length === 0 ? MessageIfEmpty : LearningDataPanel

  return (
    <ScrollUp>
      <Flex flexDirection="column" width={1}>
        <StickyHeader bg="surfacePrimary" boxShadow="1dp" zIndex={LAYERS.PageHeader}>
          <Container>
            <Flex flexDirection="column" width={1}>
              <Heading.h3 my={0} mx={0} data-test="page-title">
                Learning Analysis
              </Heading.h3>
              <Flex height={32} justifyContent="space-between" width={1}>
                <Flex flexGrow={1}>
                  <Box pr={4}>
                    <DropdownMenu
                      label={framework.fullName}
                      items={menus.frameworks}
                      portal
                      callFrom="learning-framework"
                    />
                  </Box>
                  <Box pr={4}>
                    <DropdownMenu
                      label={dateRange.label}
                      items={menus.dateRanges}
                      portal
                      callFrom="learning-date-range"
                    />
                  </Box>
                  {cohortsEnabled && (
                    <Box pr={4}>
                      <DropdownMenu
                        label={ageGroup?.name || 'All Outcome Ages'}
                        items={menus.ageGroups}
                        portal
                        callFrom="learning-age-group"
                      />
                    </Box>
                  )}
                  <Box pr={4}>
                    <DropdownSelector
                      label={childAgeRangeLabel}
                      options={menus.childAgeRanges}
                      values={childAgeRanges.map((childAgeRange) => childAgeRange.value)}
                      onChange={onChildAgeRangeChange}
                      portal
                      hasSelectAll={true}
                      selectAllStatus={childAgeRanges.length === menus.childAgeRanges.length}
                    />
                  </Box>
                </Flex>
                <Anchor onClick={() => navigate('/child-groups')}>
                  <Flex alignItems="center">
                    <Icon color="primary" name="cog" width={14} />
                    <Text.span color="primary" fontSize={1} ml={2}>
                      Manage Groups
                    </Text.span>
                  </Flex>
                </Anchor>
              </Flex>
            </Flex>
          </Container>
        </StickyHeader>

        <LearningData />

        <FirstUseBanner />
      </Flex>
    </ScrollUp>
  )
}

const LearningAnalysisLoader = ({ serviceFkey }: Props) => {
  return (
    <LearningAnalysisProvider serviceFkey={serviceFkey}>
      <LearningAnalysis serviceFkey={serviceFkey} />
    </LearningAnalysisProvider>
  )
}

export default LearningAnalysisLoader
