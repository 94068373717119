import React from 'react'

import styled from 'styled-components'
import { boxShadow } from 'styled-system'

import Flex from './Flex'
import Image from './Image'

const StyledWrapper = styled.div`
  position: relative;
  display: inline-block;
`

const StyledLabel = styled.label`
  display: block;
  padding: 12px;
  text-align: center;
  color: white;
  background: ${({ theme, isLoading }) => (isLoading ? theme.colors.cosmicShade8 : theme.colors.primary)};
  border-radius: 8px;
  transition: background 0.3s;
  white-space: nowrap;
  width: 100%;

  ${boxShadow}

  ${({ isLoading }) =>
    isLoading
      ? `
        box-shadow: 0 0 0 rgba(1, 119, 108, 0.4);
        animation: pulse 1s infinite;

        @keyframes pulse {
          0% {
            box-shadow: 0 0 0 0 rgba(1, 119, 108, 0.4);
          }
          70% {
            box-shadow: 0 0 0 10px rgba(1, 119, 108, 0);
          }
          100% {
            box-shadow: 0 0 0 0 rgba(1, 119, 108, 0);
          }
        }
        `
      : null}
  &:hover {
    cursor: ${({ isLoading }) => (isLoading ? 'cursor' : 'pointer')};
    background: ${({ theme, isLoading }) => (isLoading ? theme.colors.cosmicShade8 : theme.colors.textLink)};
  }
`

const StyledInput = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
`

const Upload = (props) => {
  const { button, text, image, isLoading, onChange, accept, multiple } = props
  return (
    <StyledWrapper>
      {button ? (
        <label htmlFor="fileUpload" {...props}>
          {button}
        </label>
      ) : (
        <StyledLabel isLoading={isLoading} htmlFor="fileUpload" {...props}>
          {text ||
            (image && (
              <Flex justifyContent="center" alignItems="center" width={1} height="100%">
                <Image src={image} width={32} alt="File upload" />
              </Flex>
            ))}
        </StyledLabel>
      )}
      {!isLoading ? (
        <StyledInput
          id="fileUpload"
          type="file"
          onChange={onChange}
          accept={accept || null}
          multiple={multiple ? true : null}
        />
      ) : null}
    </StyledWrapper>
  )
}

Upload.displayName = 'Upload'
StyledLabel.defaultProps = {
  boxShadow: '2dp',
}

export default Upload
