import React, { useEffect, useRef } from 'react'

import { Flex, FlexItem, Icon, IconButton, TextField } from 'stardust'
import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

interface props {
  onChange: (value: string) => void
  commentValue?: string
  placeholder?: string
  disabled?: boolean
  error?: boolean
  autoFocus?: boolean
}

const InlineV2Comment = ({ onChange, commentValue, placeholder, disabled, error, autoFocus }: props) => {
  const textAreaRef = useRef<null | HTMLInputElement | HTMLTextAreaElement>(null)

  const updateInputHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto' // Reset the height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px` // Set the height to scrollHeight
    }
  }

  useEffect(() => {
    updateInputHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentValue])

  return (
    <Flex alignItems="center" flexDirection="row" justifyContent="space-between">
      <FlexItem width="100%" flexGrow={2}>
        <StyledTextField
          autoComplete="off"
          data-test="comment-field"
          textarea
          resizeable={false}
          invalid={error}
          rows={1}
          name="comment"
          value={commentValue}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          ref={textAreaRef}
          autoFocus={autoFocus}
        />
      </FlexItem>
      <FlexItem pl={2}>
        <IconButton
          disabled={disabled}
          type="submit"
          data-test="comment-submit"
          icon={
            <Icon
              width={15}
              name="send"
              color={disabled ? colors.cosmicShade6 : colors.nebulaBlue7}
              mx="auto"
            />
          }
        />
      </FlexItem>
    </Flex>
  )
}

export default InlineV2Comment

const StyledTextField = styled(TextField)`
  width: 100%;

  .mdc-text-field__input {
    padding: 16px 24px;
    background-color: white;
    max-height: 200px;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`
