/*
TODO.ME: Remove selectedServiceId, only use fkey
*/

import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { isHome } from '~/config'

const LOCAL_STORAGE_KEY = 'serviceFkey'

export const getLocalService = () => localStorage.getItem(LOCAL_STORAGE_KEY)
const setLocalService = (serviceFkey: string) => localStorage.setItem(LOCAL_STORAGE_KEY, serviceFkey)
const removeLocalService = () => localStorage.removeItem(LOCAL_STORAGE_KEY)

const canUseService = (serviceFkey: Nullable<string>, services: Playground.SimpleService[]) =>
  !!services.find((service) => service.fkey === serviceFkey)

interface State {
  providerId: Nullable<number>
  selectedServiceFkey: Nullable<string>
  providerFkey: Nullable<string>
  selectedServiceId: Nullable<number>
  selectedServiceTimeZone: Nullable<string>
  selectedServiceLinks: Nullable<Playground.ServiceLinks>
  selectedServiceName: Nullable<string>
  services: Playground.SimpleService[]
  showServicePrompt: boolean
}

interface ServiceContextProps {
  clearService(): void
  selectService(serviceFkey: string): void
  setServices(services: Playground.SimpleService[]): void
  toggleServicePrompt(): void
  selectedServiceFeatures: Playground.ServiceFeature[]
  state: State
}

export const ServiceContext = React.createContext<ServiceContextProps>({
  clearService: () => {},
  selectService: () => {},
  setServices: (services: Playground.SimpleService[]) => {},
  toggleServicePrompt: () => {},
  selectedServiceFeatures: [],
  state: {
    providerId: null,
    selectedServiceFkey: null,
    providerFkey: null,
    selectedServiceId: null,
    selectedServiceTimeZone: null,
    selectedServiceLinks: null,
    selectedServiceName: null,
    services: [],
    showServicePrompt: false,
  },
})

export const useSelectedServiceTimeZone = (): string => {
  const {
    state: { selectedServiceTimeZone: timeZone },
  } = useContext(ServiceContext)
  return timeZone!
}

export const useSelectedServiceFkey = (): string => {
  const {
    state: { selectedServiceFkey: serviceFkey },
  } = useContext(ServiceContext)
  return serviceFkey!
}

export const useSelectedServiceName = (): string => {
  const {
    state: { selectedServiceName: serviceName },
  } = useContext(ServiceContext)
  return serviceName!
}

export const useSelectedServiceId = (): number => {
  const {
    state: { selectedServiceId: serviceId },
  } = useContext(ServiceContext)
  return serviceId!
}

export const useSelectedServiceProviderFkey = (): string => {
  const {
    state: { providerFkey },
  } = useContext(ServiceContext)
  return providerFkey!
}

interface Props {
  children: ReactNode
  services: Playground.SimpleService[]
}

const ServiceProvider = ({ children, services: initialServices }: Props) => {
  const [state, setState] = useState<State>({
    providerId: null,
    selectedServiceFkey: null,
    providerFkey: null,
    selectedServiceId: null,
    selectedServiceTimeZone: null,
    selectedServiceName: null,
    services: [],
    showServicePrompt: false,
    selectedServiceLinks: null,
  })

  const selectedService = useMemo(() => {
    const { selectedServiceFkey, services } = state
    return services.find((x) => x.fkey === selectedServiceFkey)
  }, [state])

  const selectedServiceFeatures = useMemo(() => {
    return selectedService?.features || []
  }, [selectedService])

  const clearService = useCallback(() => {
    setState((prevState) => {
      removeLocalService()
      return { ...prevState, selectedServiceId: null }
    })
  }, [])

  const selectService = useCallback((serviceFkey: string) => {
    setState((prevState) => {
      const { services } = prevState
      const selectedServiceDetails = services.find((x) => x.fkey === serviceFkey)
      const serviceId = serviceFkey?.startsWith('xplor-') ? Number(serviceFkey.split('-', 2)[1]) : 0
      setLocalService(serviceFkey)
      return {
        ...prevState,
        providerId: selectedServiceDetails?.providerId,
        selectedServiceFkey: serviceFkey,
        providerFkey: selectedServiceDetails?.providerFkey,
        selectedServiceId: serviceId,
        selectedServiceTimeZone: selectedServiceDetails?.timezone,
        selectedServiceLinks: selectedServiceDetails?.links,
        selectedServiceName: selectedServiceDetails?.name,
        showServicePrompt: false,
      }
    })
  }, [])

  const setServices = useCallback((services: Playground.SimpleService[]) => {
    setState((prevState) => {
      const { selectedServiceFkey } = prevState
      const unauthorized = selectedServiceFkey && !canUseService(selectedServiceFkey, services)

      if (unauthorized) removeLocalService()

      return {
        ...prevState,
        selectedServiceFkey: unauthorized ? null : selectedServiceFkey,
        services,
      }
    })
  }, [])

  const toggleServicePrompt = useCallback(() => {
    setState((prevState) => ({ ...prevState, showServicePrompt: !prevState.showServicePrompt }))
  }, [])

  useEffect(() => {
    const localServiceFkey = getLocalService() ? getLocalService() : null

    setServices(initialServices)

    // Figure out the starting strategy following these rules:
    // 1. If only one service, or if Home, use it
    // 2. Try to use local storage
    // 3. Show prompt
    if (initialServices.length === 1 || isHome) {
      selectService(initialServices[0].fkey!)
    } else if (canUseService(localServiceFkey, initialServices)) {
      selectService(localServiceFkey!)
    } else {
      toggleServicePrompt()
    }
  }, [initialServices, selectService, setServices, toggleServicePrompt])

  return (
    <ServiceContext.Provider
      value={{
        clearService,
        selectService,
        setServices,
        toggleServicePrompt,
        selectedServiceFeatures,
        state,
      }}>
      {children}
    </ServiceContext.Provider>
  )
}

export default ServiceProvider
