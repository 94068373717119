import React from 'react'

import { Box, Image, Text } from 'stardust'

import styled from 'styled-components'

interface Props {
  postMedia: Playground.MediaItem[]
  isPrintPreview?: boolean
  onMediaClick: (id: number) => void
}

const S = {
  Text: styled(Text)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  Image: styled(Image)<{ borderRadius?: string; aspectRatio: string }>`
    border-radius: ${(props) => props.borderRadius};
    aspect-ratio: ${(props) => props.aspectRatio};
    object-fit: cover;
    &:hover {
      filter: brightness(90%);
      transition: background-color 0.3s;
    }
  `,
  LastImageBox: styled(Box)`
    aspect-ratio: 222/284;
  `,
  LastImage: styled(Image)<{ borderRadius: string; filter: string; hoverFilter: string }>`
    border-radius: ${(props) => props.borderRadius};
    cursor: pointer;
    filter: ${(props) => props.filter};
    object-fit: cover;
    &:hover {
      filter: ${(props) => props.hoverFilter};
      transition: background-color 0.2s;
    }
  `,
}

const FiveMedia = ({ postMedia, isPrintPreview = false, onMediaClick }: Props) => {
  const getMediaCoverUrl = (media: Playground.MediaItem) =>
    media.mimeType?.includes('video') ? media.coverUrl : media.url

  return (
    <>
      <S.Image
        width="50%"
        aspectRatio="334/384"
        pr="1px"
        pb="2px"
        borderRadius={!isPrintPreview ? '15px 0 0 0' : '0'}
        src={getMediaCoverUrl(postMedia[0])}
        onClick={() => onMediaClick(postMedia[0]?.id)}
        alt=""
      />
      <S.Image
        width="50%"
        aspectRatio="334/384"
        pl="1px"
        pb="2px"
        borderRadius={!isPrintPreview ? '0 15px 0 0' : '0'}
        src={getMediaCoverUrl(postMedia[1])}
        onClick={() => onMediaClick(postMedia[1]?.id)}
        alt=""
      />
      <S.Image
        width="calc(100% / 3)"
        aspectRatio="222/284"
        pr="2px"
        borderRadius={!isPrintPreview ? '0 0 0 15px' : '0'}
        src={getMediaCoverUrl(postMedia[2])}
        onClick={() => onMediaClick(postMedia[2]?.id)}
        alt=""
      />
      <S.Image
        width="calc(100% / 3)"
        aspectRatio="222/284"
        src={getMediaCoverUrl(postMedia[3])}
        onClick={() => onMediaClick(postMedia[3]?.id)}
        alt=""
      />
      <S.LastImageBox
        width="calc(100% / 3)"
        position="relative"
        onClick={() => onMediaClick(postMedia[4]?.id)}>
        <S.LastImage
          width="100%"
          height="100%"
          pl="2px"
          borderRadius={!isPrintPreview ? '0 0 15px 0' : '0'}
          src={getMediaCoverUrl(postMedia[4])}
          filter={postMedia.length > 5 ? 'brightness(50%)' : ''}
          hoverFilter={postMedia.length <= 5 ? 'brightness(90%)' : 'brightness(30%)'}
          alt=""
        />
        <S.Text color={'white'} fontSize={5}>
          {postMedia.length > 5 && `+${postMedia.length - 5}`}
        </S.Text>
      </S.LastImageBox>
    </>
  )
}

FiveMedia.displayName = 'FiveMedia'

export default React.memo(FiveMedia)
