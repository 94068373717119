import React from 'react'

import { Absolute, Box, Flex, Image, Relative, Text } from 'stardust'
import styled from 'styled-components'

const CircleBox = styled(Flex)`
  border-radius: 50%;
  border: 1px solid #5f6064;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
`

const InlineMetricItem = ({ icon, count }) => (
  <Flex mr={2} alignItems="center" flex-direction="row">
    <Box mr={2}>
      <Relative>
        <Image height="auto" width={24} mr={1} role="presentation" src={icon} alt="" />
        <Absolute top={10} left={-10}>
          <CircleBox bg="surfacePrimary" p={1}>
            <Text fontSize={0}>{Number(count) > 100 ? '+99' : count}</Text>
          </CircleBox>
        </Absolute>
      </Relative>
    </Box>
  </Flex>
)

InlineMetricItem.displayName = 'InlineMetricItem'

export default InlineMetricItem
