import React, { useEffect, useState } from 'react'

import { Avatar, Box, Checkbox, Flex, Icon, Text } from 'stardust'

import styled from 'styled-components'

import * as profiles from '~/components/Profiles/core'

import { CONSENTS } from '~/modules/children'

import colors from '~/ui-components/tokens/colors'

const TagChildrenResultContainer = styled(Box)`
  max-height: 35vh;
  overflow-y: auto;
`

interface Props {
  rooms?: Playground.Room[]
  childrenList?: Playground.SimpleChild[]
  selectedChildrenToTag?: Playground.SimpleChild[]
  filteringByGroup?: string
  expandAll?: boolean
  viewSelected: boolean
  sortChildren: boolean
  bookedInAndSignInChildren?: Playground.SimpleChild[]
  handleChildClicked: (childFkey: string | undefined) => void
  isChecked: (fkey: string | undefined) => boolean
  getStyles: (fkey: string | undefined) => { border: string; background: string }
  setSelectedChildrenToTag: React.Dispatch<React.SetStateAction<any[] | never[]>>
}

const DisplayAllChildren = ({
  rooms,
  expandAll,
  childrenList,
  selectedChildrenToTag,
  viewSelected,
  sortChildren,
  filteringByGroup,
  bookedInAndSignInChildren,
  setSelectedChildrenToTag,
  isChecked,
  getStyles,
  handleChildClicked,
}: Props) => {
  const [expandedGroups, setExpandedGroups] = useState<number[]>([])

  useEffect(() => {
    setExpandedGroups(expandAll ? [] : childrenList ? Array.from(Array(childrenList.length).keys()) : [])
  }, [expandAll, childrenList])

  // Function to toggle individual group expansion
  const toggleIndividualGroup = (index: number) => {
    setExpandedGroups((prevGroups) => {
      if (prevGroups.includes(index)) {
        return prevGroups.filter((idx) => idx !== index)
      } else {
        return [...prevGroups, index]
      }
    })
  }

  const isGroupExpanded = (index: number) => expandedGroups.includes(index)

  const CheckBoxBgColor = !viewSelected ? colors.nebulaBlue7 : colors.cosmicShade17
  const groupedChildren: Playground.SimpleChild[][] = (childrenList || [])
    .reduce((acc: Playground.SimpleChild[][], child) => {
      const roomFkey =
        filteringByGroup === 'Signed in' || filteringByGroup === 'Booked in'
          ? bookedInAndSignInChildren?.find((bookedChild) => bookedChild.childFkey === child.fkey)?.roomFkey
          : child?.room?.fkey

      const updatedChild = { ...child, roomAssigned: { fkey: roomFkey } }

      if (roomFkey !== undefined) {
        const groupIndex = acc.findIndex((group) => group[0]?.room?.fkey === roomFkey)
        // If the group exists, push the child to it; otherwise, create a new group
        {
          groupIndex !== -1 ? acc[groupIndex].push(updatedChild) : acc.push([updatedChild])
        }
      }
      return acc
    }, [])
    .sort((a, b) => {
      const roomNameA = a[0]?.room?.name?.toUpperCase() || 'Unknown'
      const roomNameB = b[0]?.room?.name?.toUpperCase() || 'Unknown'
      return sortChildren ? roomNameA.localeCompare(roomNameB) : roomNameB.localeCompare(roomNameA)
    })

  const handleGroupSelected = (group: Playground.SimpleChild[]) => {
    // Check if all children in the group are in selectedChildrenToTag
    const isRemoveGroup = group.every((child) =>
      selectedChildrenToTag?.some((selectedChild) => selectedChild.fkey === child.fkey)
    )
    // If all children in the group are in selectedChildrenToTag, remove all of them and exit
    if (isRemoveGroup) {
      setSelectedChildrenToTag((prevChildObjs) =>
        prevChildObjs.filter((obj) => !group.some((child) => child.fkey === obj.fkey))
      )
      return
    }

    // If not, proceed with the logic
    let currentGroup = group

    const childrenToRemove = currentGroup.filter((child) =>
      selectedChildrenToTag?.some((selectedChild) => selectedChild.fkey === child.fkey)
    )

    currentGroup = currentGroup.filter((child) => !childrenToRemove.some((obj) => obj.fkey === child.fkey))

    setSelectedChildrenToTag((prevChildObjs) => [...prevChildObjs, ...currentGroup])
  }

  return (
    <TagChildrenResultContainer pl={3} pt={3} pr={3}>
      {groupedChildren.map((group, index) => {
        const selectedCount = group.filter((child) => isChecked(child.fkey)).length

        if (!viewSelected || (viewSelected && selectedCount > 0)) {
          return (
            <Flex mb={2} width="100%" justifyContent="space-between" key={index}>
              <Flex
                key={index}
                border={`2px solid ${colors.cosmicShade6}`}
                bg={selectedCount === group.length ? colors.nebulaBlue10 : colors.cosmicShade0}
                flexDirection="column"
                mb={2}
                borderRadius={2}
                pt="10px"
                pb="24px"
                pr="24px"
                pl="24px"
                width="100%">
                <Flex alignItems="center" justifyContent="space-between">
                  <Flex alignItems="center">
                    <Icon name="roomsNav" color={colors.nebulaBlue4} ml={1} width={24} mb="16px" mr={1} />
                    <Text fontWeight={700} fontSize={2} mb={2}>
                      {filteringByGroup === 'Signed in' || filteringByGroup === 'Booked in'
                        ? rooms && rooms.find((room) => room.fkey === group[0]?.roomAssigned?.fkey)?.name
                        : group[0]?.room?.name}
                    </Text>
                  </Flex>
                  <Flex alignItems="center">
                    <Text fontWeight={400} fontSize={2} mb={2}>
                      Selected {selectedCount} / {group.length}
                    </Text>
                    <Icon
                      name={isGroupExpanded(index) ? 'chevronUpTransparent' : 'chevronDown'}
                      ml={2}
                      width={24}
                      mb={2}
                      onClick={() => toggleIndividualGroup(index)}
                    />
                  </Flex>
                </Flex>
                <Flex flexWrap="wrap">
                  {isGroupExpanded(index) &&
                    group.map((child: any) => {
                      const { border, background } = getStyles(child.fkey)
                      if (!viewSelected || (viewSelected && isChecked(child.fkey))) {
                        return (
                          <Flex
                            key={child.fkey}
                            border={border}
                            borderRadius="50px"
                            pt={1}
                            pb={1}
                            mb={2}
                            mr={2}
                            bg={background}
                            onClick={() => handleChildClicked(child.fkey)}>
                            <Flex
                              alignItems="center"
                              pr={3}
                              pl={1}
                              onClick={(e: any) => {
                                e.stopPropagation()
                                handleChildClicked(child.fkey)
                              }}>
                              <Flex alignItems="center">
                                <Avatar
                                  src={child.image}
                                  alt={child.fullName}
                                  text={profiles.initials(child.fullName)}
                                  xsmall
                                />
                                <Text ml={2} fontSize="14px" mr={2}>
                                  {child.fullName}
                                </Text>
                                {!child.otherDetails?.consents?.includes(
                                  CONSENTS.activitiesAndExcursions
                                ) && <Icon color="" name="noPhotosAlt" mr={2} width={28} />}
                              </Flex>
                              <Checkbox
                                checked={isChecked(child.fkey) as boolean}
                                onChange={() => handleChildClicked(child.fkey)}
                              />
                            </Flex>
                          </Flex>
                        )
                      }
                    })}
                </Flex>
              </Flex>
              <Flex bg={colors.cosmicShade12} width="20px" ml={20} mt={20} mr={16}>
                <Checkbox
                  CheckBoxBgColor={CheckBoxBgColor}
                  checked={group.every((child) => isChecked(child.fkey))}
                  onChange={() => handleGroupSelected(group)}
                  indeterminate={selectedCount !== 0 && selectedCount !== group.length ? true : false}
                />
              </Flex>
            </Flex>
          )
        }
        return null // If viewSelected is true and no child is selected in the group then don't render the group.
      })}
    </TagChildrenResultContainer>
  )
}

DisplayAllChildren.displayName = 'DisplayAllChildren'

export default DisplayAllChildren
