import React, { useEffect } from 'react'

import { useQuery } from '@apollo/client'

import { Box } from 'stardust'

import styled from 'styled-components'

import { GET_CHILDREN_BOOKING_AND_SIGNIN_DETAILS } from '~/pages/Observations/queries'

import ChildBox from './ChildBox'
import DisplayAllChildren from './DisplayAllChildren'

const TagChildrenResultContainer = styled(Box)`
  max-height: 35vh;
  overflow-y: auto;
`

interface Props {
  serviceFkey: string
  roomFkey: string
  isQikkids: boolean
  rooms?: Playground.Room[]
  filteringByGroup: string
  selectedChildrenToTag?: Playground.SimpleChild[]
  childrenList?: Playground.SimpleChild[]
  selectedDate: string
  viewSelected: boolean
  sortChildren: boolean
  bookedInAndSignInChildren?: Playground.SimpleChild[]
  setSelectedChildrenToTag: React.Dispatch<React.SetStateAction<any[] | never[]>>
  handleChildClicked: (childFkey: string | undefined) => void
  isChecked: (fkey: string | undefined) => boolean
  getStyles: (fkey: string | undefined) => { border: string; background: string }
  setChildrenInCurrentView: React.Dispatch<React.SetStateAction<any[] | never[]>>
  setBookedInAndSignInChildren: React.Dispatch<React.SetStateAction<any[] | never[]>>
}

const BookedInAndSignedInChildren = ({
  rooms,
  isQikkids,
  filteringByGroup,
  serviceFkey,
  selectedDate,
  childrenList,
  selectedChildrenToTag,
  roomFkey,
  viewSelected,
  sortChildren,
  bookedInAndSignInChildren,
  isChecked,
  getStyles,
  handleChildClicked,
  setChildrenInCurrentView,
  setBookedInAndSignInChildren,
  setSelectedChildrenToTag,
}: Props) => {
  const dateToForQikkids = new Date(selectedDate)

  const dateFromObject = new Date(dateToForQikkids.getTime() - 24 * 60 * 60 * 1000)

  const dateFromDateOnly = dateFromObject.toISOString().split('T')[0]

  const dateFrom = `${dateFromDateOnly}T14:00:00Z`

  const selectedDateFrom = isQikkids ? dateFrom : `${selectedDate}T00:00:00Z`
  const selectedDateTo = isQikkids ? `${selectedDate}T13:59:00Z` : `${selectedDate}T12:00:00Z`

  const { data: childrenSignedInAndBookedInData, error: childrenDataError } = useQuery(
    GET_CHILDREN_BOOKING_AND_SIGNIN_DETAILS,
    {
      variables: {
        serviceFkey,
        dateFrom: selectedDateFrom,
        dateTo: selectedDateTo,
      },
    }
  )

  useEffect(() => {
    if (!childrenDataError && childrenSignedInAndBookedInData) {
      const { bookings } = childrenSignedInAndBookedInData.service
      setBookedInAndSignInChildren(bookings)
    }
  }, [
    roomFkey,
    childrenDataError,
    selectedDate,
    filteringByGroup,
    childrenSignedInAndBookedInData,
    setBookedInAndSignInChildren,
  ])

  if (childrenDataError) {
    return null
  }

  if (roomFkey === 'All room') {
    const signedInOrBookedInChildren =
      filteringByGroup === 'Signed in'
        ? bookedInAndSignInChildren &&
          bookedInAndSignInChildren
            .filter((child) => child && child.attendances && child.attendances.length > 0)
            .map((bookedInChild) => childrenList?.find((child) => child.fkey === bookedInChild.childFkey))
            .filter((child): child is Playground.SimpleChild => !!child)
        : bookedInAndSignInChildren &&
          bookedInAndSignInChildren
            .filter((child) => child && child.attendances && child.attendances.length === 0)
            .map((bookedInChild) => childrenList?.find((child) => child.fkey === bookedInChild.childFkey))
            .filter((child): child is Playground.SimpleChild => !!child)

    return (
      <DisplayAllChildren
        rooms={rooms}
        childrenList={signedInOrBookedInChildren}
        filteringByGroup={filteringByGroup}
        selectedChildrenToTag={selectedChildrenToTag}
        viewSelected={viewSelected}
        sortChildren={sortChildren}
        bookedInAndSignInChildren={bookedInAndSignInChildren}
        handleChildClicked={handleChildClicked}
        isChecked={isChecked}
        getStyles={getStyles}
        setSelectedChildrenToTag={setSelectedChildrenToTag}
      />
    )
  }

  if (filteringByGroup === 'All children' || filteringByGroup === 'Default room') {
    let allChild = childrenList || []

    if (isQikkids && bookedInAndSignInChildren) {
      const updatedRoomDetailsInAllChildrenList =
        childrenList?.map((child) => {
          // Find the matching bookedInChild in bookedInAndSignInChildren
          const bookedInChild = bookedInAndSignInChildren?.find(
            (bookedInChild) => bookedInChild.childFkey === child.fkey
          )

          // If a matching child is found, update RoomFkey
          if (bookedInChild) {
            return {
              ...child,
              roomFkey: bookedInChild.roomFkey,
            }
          }
          // If no matching bookedInChild is found, return the original child
          return child
        }) || [] // Default to an empty array if childrenList is undefined

      allChild = updatedRoomDetailsInAllChildrenList
    }

    if (roomFkey !== 'All room' && roomFkey !== '') {
      allChild = allChild.filter((child) => (isQikkids ? child.roomFkey : child?.room?.fkey) === roomFkey)
    }

    const includeSignedInandBookedInChildren =
      bookedInAndSignInChildren
        ?.filter((child) => child.roomFkey === roomFkey)
        .map((bookedInChild) => childrenList?.find((child) => child.fkey === bookedInChild.childFkey))
        .filter((child): child is Playground.SimpleChild => !!child)
        .filter((child) => !allChild.some((existingChild) => existingChild.fkey === child.fkey)) || []

    const displaySelectedChildren = viewSelected
      ? allChild &&
        allChild.filter(
          (child) =>
            selectedChildrenToTag &&
            selectedChildrenToTag.some((selectedChild) => selectedChild.fkey === child.fkey)
        )
      : allChild

    const displayChildren = viewSelected
      ? displaySelectedChildren
      : [...allChild, ...(filteringByGroup === 'Default room' ? [] : includeSignedInandBookedInChildren)]

    return (
      <TagChildrenResultContainer pt={3}>
        {displayChildren &&
          displayChildren.map((child) => {
            return (
              <ChildBox
                key={child.fkey}
                child={child}
                border={getStyles(child.fkey).border}
                background={getStyles(child.fkey).background}
                handleChildClicked={() => handleChildClicked(child.fkey)}
                isChecked={() => isChecked(child.fkey)}
              />
            )
          })}
      </TagChildrenResultContainer>
    )
  }

  const filterChildren =
    bookedInAndSignInChildren &&
    bookedInAndSignInChildren
      .filter((child) =>
        filteringByGroup === 'Signed in'
          ? child && child.attendances && child.attendances.length > 0
          : child && child.attendances && child.attendances.length === 0
      )
      .map((bookedInChild) => childrenList?.find((child) => child.fkey === bookedInChild.childFkey))
      .filter((child): child is Playground.SimpleChild => !!child)

  const displayChildren = viewSelected
    ? filterChildren &&
      filterChildren.filter(
        (child) =>
          selectedChildrenToTag &&
          selectedChildrenToTag.some((selectedChild) => selectedChild.fkey === child.fkey)
      )
    : filterChildren

  const sortedDisplayChildren =
    displayChildren &&
    [...displayChildren].sort((a, b) => {
      const roomNameA = a?.fullName?.toUpperCase() || 'Unknown'
      const roomNameB = b?.fullName?.toUpperCase() || 'Unknown'
      return sortChildren ? roomNameA.localeCompare(roomNameB) : roomNameB.localeCompare(roomNameA)
    })

  const AssignedRoomToChildren =
    sortedDisplayChildren &&
    sortedDisplayChildren.map((child) => {
      const roomFkey = bookedInAndSignInChildren?.find(
        (bookedChild) => bookedChild.childFkey === child.fkey
      )?.roomFkey

      const updatedChild = { ...child, roomAssigned: { fkey: roomFkey } }
      return updatedChild
    })

  return (
    <TagChildrenResultContainer pl={3} pt={3} pr={3}>
      {AssignedRoomToChildren &&
        AssignedRoomToChildren.filter((child) =>
          roomFkey ? child?.roomAssigned?.fkey === roomFkey : true
        ).map((child) => {
          return (
            <ChildBox
              key={child.fkey}
              child={child}
              border={getStyles(child.fkey).border}
              background={getStyles(child.fkey).background}
              handleChildClicked={() => handleChildClicked(child.fkey)}
              isChecked={() => isChecked(child.fkey)}
            />
          )
        })}
    </TagChildrenResultContainer>
  )
}

BookedInAndSignedInChildren.displayName = 'BookedInAndSignedInChildren'

export default BookedInAndSignedInChildren
