import React, { useRef, useState } from 'react'

import { Box, Flex, Text, Icon } from 'stardust'

import styled from 'styled-components'

import useClickedOutside from '~/hooks/useClickedOutside'

import colors from '~/ui-components/tokens/colors'

const Options = styled(Flex)`
  z-index: 100;
  border: 1px solid;
  border-color: grey;
  border-radius: 16px;
  background-color: white;
  max-height: 240px;
  overflow-y: auto;
  width: 100%;
  position: relative;
  div {
    &:hover {
      background-color: ${colors.cosmicShade4};
    }
  }
`

interface Props {
  childrenList?: Playground.SimpleChild[]
  searchTerm: string
  roomFkey: string
  childrenInCurrentView?: Playground.SimpleChild[]
  setSearchTerm: (searchTerm: string) => void
}

const S = {
  Input: styled.input`
    width: 90%;
    font-size: 16px;
  `,
  Text: styled(Text)`
    cursor: pointer;
  `,
}
const ChildrenSearch = ({
  childrenList,
  searchTerm,
  roomFkey,
  childrenInCurrentView,
  setSearchTerm,
}: Props) => {
  const dropdownRef = useRef<HTMLElement>(null)
  const [showCancelButton, setShowCancelButton] = useState(false)
  const [openSearchResultDropdown, setOpenSearchResultDropdown] = useState(false)

  useClickedOutside(() => {
    setOpenSearchResultDropdown(false)
    setShowCancelButton(true)
  }, [dropdownRef])

  const filteredChildrenBasedOnResult =
    searchTerm && roomFkey !== 'All room' && roomFkey !== ''
      ? (childrenInCurrentView &&
          childrenInCurrentView.filter(
            (child) =>
              child.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
              child.fkey.toLowerCase().includes(searchTerm.toLowerCase())
          )) ||
        []
      : (childrenList &&
          childrenList.filter(
            (child) =>
              child.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
              child.fkey.toLowerCase().includes(searchTerm.toLowerCase())
          )) ||
        []

  const handleSelectChild = (child: Playground.SimpleChild) => {
    setSearchTerm(child.fullName) // Set the search term to the selected child's name
    setOpenSearchResultDropdown(false)
  }

  return (
    <Box width="100%">
      <Flex alignItems="center">
        <Flex
          alignItems="center"
          width="100%"
          height={48}
          border="1px solid"
          borderColor={colors.cosmicShade6}
          bg="white"
          borderRadius="16px">
          <Icon name="search" ml={3} mr={2} />
          <S.Input
            placeholder="Search by children’s name"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value)
              setOpenSearchResultDropdown(e.target.value.length > 0)
            }}
          />
        </Flex>
        {showCancelButton && searchTerm !== '' && (
          <S.Text onClick={() => setSearchTerm('')} ml={2} fontSize={1} color={colors.nebulaBlue4}>
            Cancel
          </S.Text>
        )}
      </Flex>
      {openSearchResultDropdown && searchTerm && (
        <Options flexDirection="column" mt={1}>
          {filteredChildrenBasedOnResult.length > 0 && (
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontWeight={700} p={3}>
                Search results {`(${filteredChildrenBasedOnResult.length})`}
              </Text>
              <S.Text onClick={() => setSearchTerm('')} mr={2} fontSize={1} color={colors.nebulaBlue4}>
                Cancel
              </S.Text>
            </Flex>
          )}
          {filteredChildrenBasedOnResult.length > 0 ? (
            filteredChildrenBasedOnResult.map((child) => (
              <Box
                key={child.fkey}
                onClick={() => handleSelectChild(child)}
                borderBottom="1px solid"
                borderColor={colors.cosmicShade4}>
                <Text p={3}>{child.fullName}</Text>
              </Box>
            ))
          ) : (
            <Flex alignItems="center" flexDirection="column" px={16} py={24}>
              <Icon width={88} name="noChildrenFound" pb={3} />
              <Text fontSize="14px" fontWeight={700} lineHeight="21px" pb={2}>
                No children found
              </Text>
              <Text fontSize="14px" fontWeight={400} lineHeight="21px" textAlign="center" pb={3}>
                Sorry, we couldn’t find any children with the name <strong>{searchTerm}. </strong>
                Please try again.
              </Text>
              <S.Text onClick={() => setSearchTerm('')} fontSize={1} color={colors.nebulaBlue4}>
                Clear search
              </S.Text>
            </Flex>
          )}
        </Options>
      )}
    </Box>
  )
}

ChildrenSearch.displayName = 'ChildrenSearch'
export default React.memo(ChildrenSearch)
