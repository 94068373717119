import React from 'react'

import { Fab, Flex, Icon, Text } from 'stardust'

interface Props {
  page: number
  pageCount: number
  onPageChange(page: number): void
}

const Pagination = ({ page, pageCount, onPageChange }: Props) => {
  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < pageCount) {
      onPageChange(newPage)
    }
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Fab
        bg="surfacePrimary"
        boxShadow="2dp"
        disabled={page === 0}
        height="24px"
        mx={1}
        width="24px"
        onClick={() => handlePageChange(0)}>
        <Icon color="primary" width={16} name="chevronLeftDouble" />
      </Fab>
      <Fab
        bg="surfacePrimary"
        boxShadow="2dp"
        disabled={page === 0}
        height="24px"
        mx={1}
        width="24px"
        onClick={() => handlePageChange(page - 1)}>
        <Icon color="primary" width={16} name="chevronLeft" />
      </Fab>
      <Text mx={3} fontSize={0}>
        Page {page + 1} of {pageCount}
      </Text>
      <Fab
        bg="surfacePrimary"
        boxShadow="2dp"
        disabled={page === pageCount - 1}
        height="24px"
        mx={1}
        width="24px"
        onClick={() => handlePageChange(page + 1)}>
        <Icon color="primary" width={16} name="chevronRight" />
      </Fab>
      <Fab
        bg="surfacePrimary"
        boxShadow="2dp"
        disabled={page === pageCount - 1}
        height="24px"
        mx={1}
        width="24px"
        onClick={() => handlePageChange(pageCount - 1)}>
        <Icon color="primary" width={16} name="chevronRightDouble" />
      </Fab>
    </Flex>
  )
}

Pagination.displayName = 'Pagination'

export default React.memo(Pagination)
