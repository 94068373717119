import styled from 'styled-components'

import { TextBase } from './Text'

const lineHeight = (x = 1) => `${24 * x}px`
const anotherLineHeight = (x = 0) => `${24 + x}px`

const Heading = TextBase as typeof TextBase & {
  h1: typeof TextBase
  h2: typeof TextBase
  h3: typeof TextBase
  h4: typeof TextBase
  h5: typeof TextBase
  h6: typeof TextBase
}

const defaultHeadingProps = {
  color: 'textPrimary',
  lineHeight: lineHeight(2.67),
}

Heading.displayName = 'Heading'
Heading.defaultProps = {
  bold: false,
  color: 'textPrimary',
  m: 0,
  // regular: true,
}

Heading.h1 = styled(TextBase).attrs({ as: 'h1' })``
Heading.h1.defaultProps = {
  bold: true,
  fontSize: 7,
  mb: lineHeight(1.35),
  mt: lineHeight(2),
}

Heading.h2 = styled(TextBase).attrs({ as: 'h2' })``
Heading.h2.defaultProps = {
  ...defaultHeadingProps,
  bold: true,
  fontSize: 6,
  mb: lineHeight(1),
  mt: lineHeight(1.35),
}

Heading.h3 = styled(TextBase).attrs({ as: 'h3' })``
Heading.h3.defaultProps = {
  ...defaultHeadingProps,
  fontSize: 5,
  mb: lineHeight(1),
  mt: anotherLineHeight(8),
  // regular: true,
}

Heading.h4 = styled(TextBase).attrs({ as: 'h4' })``
Heading.h4.defaultProps = {
  ...defaultHeadingProps,
  fontSize: 4,
  mb: lineHeight(1),
  mt: lineHeight(1.35),
  // regular: true,
}

Heading.h5 = styled(TextBase).attrs({ as: 'h5' })``
Heading.h5.defaultProps = {
  ...defaultHeadingProps,
  fontSize: 3,
  mb: lineHeight(0.85),
  mt: lineHeight(1.15),
  // regular: true,
}

Heading.h6 = styled(TextBase).attrs({ as: 'h6' })``
Heading.h6.defaultProps = {
  ...defaultHeadingProps,
  fontSize: 2,
  mb: lineHeight(0.85),
  mt: lineHeight(1.15),
  // regular: true,
}

export default Heading
