import React from 'react'

import { Alert, Icon, Text } from 'stardust'

import { PopupLink } from '~/components/NotificationPopup'
import { articleContentState } from '~/utils'

interface Props {
  article: Playground.Article
  path: string
}

const ArticleStatusBanner = ({ article, path }: Props) => {
  switch (articleContentState(article)) {
    case 'DRAFT':
      return (
        <Alert icon={<Icon name="draft" color="primary" mr={2} width={20} />}>
          <Text color="primary" fontSize={0} lineHeight={1.4}>
            <strong>Draft:</strong> Parents cannot see this. Press{' '}
            <PopupLink to={`/${path}/${article.id}`}>Edit</PopupLink> and then publish to allow parents to see
            the document.
          </Text>
        </Alert>
      )

    case 'PUBLISHED':
      return (
        <Alert icon={<Icon name="checkCircle" color="primary" mr={2} width={20} />}>
          <Text color="primary" fontSize={0} lineHeight={1.4}>
            <strong>Published:</strong> Parents can see this. Any{' '}
            <PopupLink to={`/${path}/${article.id}`}>changes</PopupLink> you make will not be visible to
            parents until the document is published again.
          </Text>
        </Alert>
      )

    case 'UNPUBLISHED_CHANGES':
      return (
        <Alert icon={<Icon name="editOutlined" color="primary" mr={2} width={20} />}>
          <Text color="primary" fontSize={0} lineHeight={1.4}>
            <strong>Unpublished Changes:</strong> This is a published document that parents can see. There are
            unpublished changes to this document which cannot be seen by parent. Press{' '}
            <PopupLink to={`/${path}/${article.id}`}>Edit</PopupLink> to see the unpublished changes.
          </Text>
        </Alert>
      )

    default:
      return null
  }
}

ArticleStatusBanner.displayName = 'ArticleStatusBanner'

export default React.memo(ArticleStatusBanner)
