import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { createPopper } from '@popperjs/core'
import ReactDOM from 'react-dom'
import { Absolute, Box } from 'stardust'
import styled from 'styled-components'

import useClickedOutside from '~/hooks/useClickedOutside'
import { LAYERS } from '~/theme'

const StyledPath = styled.path`
  fill: #121212;
`
const PointSouth = () => (
  <svg width="28" height="24" viewBox="0 0 27 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <StyledPath d="M0 0H27C24.7709 0 22.6451 0.878124 21.1456 2.4184L17.1997 6.47168C15.2159 8.50944 11.7841 8.50944 9.8003 6.47168L5.85436 2.4184C4.35487 0.878124 2.22915 0 0 0Z" />
  </svg>
)

const PointWest = () => (
  <svg width="24" height="28" viewBox="0 0 8 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <StyledPath d="M8 0V27C8 24.7709 7.12188 22.6451 5.5816 21.1456L1.52832 17.1997C-0.509441 15.2159 -0.509441 11.7841 1.52832 9.8003L5.5816 5.85436C7.12188 4.35487 8 2.22915 8 0Z" />
  </svg>
)

const PointEast = () => (
  <svg width="24" height="28" viewBox="0 0 8 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <StyledPath d="M0 0V27C0 24.7709 0.878125 22.6451 2.4184 21.1456L6.47168 17.1997C8.50944 15.2159 8.50944 11.7841 6.47168 9.8003L2.4184 5.85436C0.878125 4.35487 0 2.22915 0 0Z" />
  </svg>
)

const PointNorth = () => (
  <svg width="28" height="24" viewBox="0 0 27 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <StyledPath d="M27 8H0C2.22915 8 4.35487 7.12188 5.85436 5.5816L9.8003 1.52832C11.7841 -0.509442 15.2159 -0.509439 17.1997 1.52832L21.1456 5.5816C22.6451 7.12188 24.7709 8 27 8Z" />
  </svg>
)

const Pointer = ({ placement }: { placement: string }) => {
  if (placement.startsWith('top')) return <PointSouth />
  if (placement.startsWith('right')) return <PointWest />
  if (placement.startsWith('left')) return <PointEast />
  return <PointNorth />
}

const pointerOffset = ({ placement, showArrow }: { placement: string; showArrow: boolean }) => {
  if (!showArrow) return undefined
  if (placement.startsWith('left')) return 'margin-right: 16px'
  if (placement.startsWith('right')) return 'margin-left: 16px'
  if (placement.startsWith('top')) return 'margin-bottom: 16px'
  return 'margin-top: 16px'
}

const Container = styled(Box)<{ show: boolean }>`
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px);
  opacity: ${(props) => (props.show ? 1 : 0)};
  position: fixed;
  pointer-events: ${(props) => (props.show ? 'auto' : 'none')};
  transition: opacity 0.2s;
`

const Content = styled(Box)<{ placement: string; showArrow: boolean }>`
  position: relative;
  height: 72px;
  width: 239px;
  ${pointerOffset};
`

interface Props {
  anchor: React.RefObject<HTMLDivElement>
  children: ReactNode
  placement: 'bottom' | 'bottom-start' | 'left' | 'right' | 'right-start' | 'top'
  portal?: boolean
  show: boolean
  showArrow?: boolean
  onToggle(show: boolean): void
}

const ToolTip = ({
  anchor,
  children,
  placement: initialPlacement,
  portal = false,
  show,
  showArrow = true,
  onToggle,
}: Props) => {
  const arrowRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const [placement, setPlacement] = useState(initialPlacement)

  const onFirstUpdate = useCallback((popperData) => {
    setPlacement(popperData.placement)
    return popperData
  }, [])

  useClickedOutside(() => show && onToggle(false), anchor, containerRef)

  useEffect(() => {
    if (anchor.current && containerRef.current) {
      const modifiers = []

      if (showArrow && arrowRef.current) {
        modifiers.push({
          name: 'arrow',
          options: {
            element: arrowRef.current,
          },
        })
      }

      const popper = createPopper(anchor.current, containerRef.current, {
        placement,
        modifiers,
        onFirstUpdate,
      })

      return () => popper.destroy()
    }
  }, [anchor, placement, showArrow, onFirstUpdate])

  const popoverContent = (
    <Container ref={containerRef} show={show} zIndex={LAYERS.Popover}>
      <Absolute
        ref={arrowRef}
        left={placement.startsWith('right') ? 0 : undefined}
        right={placement.startsWith('left') ? 0 : undefined}>
        {showArrow && <Pointer placement={placement} />}
      </Absolute>
      <Content
        bg="black"
        borderRadius={1}
        placement={placement}
        showArrow={showArrow}
        zIndex={LAYERS.Popover}>
        {children}
      </Content>
    </Container>
  )

  return portal ? ReactDOM.createPortal(popoverContent, document.getElementById('root')!) : popoverContent
}

ToolTip.displayName = 'ToolTip'

export default React.memo(ToolTip)
