import React, { useReducer } from 'react'

import { FetchResult } from '@apollo/client'
import { ErrorMessage } from 'stardust'
import styled from 'styled-components'

import BlockComment from './BlockComment'
import InlineComment from './InlineComment'
import InlineV2Comment from './InlineV2Comment'
import InlineV3Comment from './InlineV3Comment'

const Form = styled.form`
  margin: 0;
`

const initialState = {
  commentValue: '',
  data: null,
  error: false,
  errorMessage: null,
  invalid: false,
  disabled: true,
  loading: false,
}

const reducer = (state = initialState, { payload, type }: { payload?: any; type: string }) => {
  switch (type) {
    case 'loading':
      return {
        ...state,
        error: false,
        errorMessage: null,
        disabled: true,
        loading: true,
      }
    case 'error':
      return {
        ...state,
        error: true,
        errorMessage: payload,
        disabled: true,
        loading: false,
      }
    case 'change': {
      return {
        ...state,
        error: false,
        errorMessage: null,
        disabled: !payload?.trim(),
        commentValue: payload,
      }
    }
    case 'success':
      return {
        ...state,
        error: false,
        errorMessage: null,
        disabled: true,
        commentValue: '',
        loading: false,
      }
    default: {
      return state
    }
  }
}

interface CommentInputProps {
  addComment: (comment: string) => Promise<FetchResult>
  placeholder: string
  buttonText?: string
  inline?: boolean
  inlineV2?: boolean
  inlineV3?: boolean
  autoFocus?: boolean
  disableInput?: boolean
}

const CommentInput = ({
  addComment,
  placeholder,
  buttonText = 'Send',
  inline = false,
  inlineV2 = false,
  inlineV3 = false,
  autoFocus = false,
  disableInput = false,
}: CommentInputProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    ;(event.target as HTMLFormElement).comment.focus()

    if (state.commentValue.length == 0) {
      dispatch({ type: 'error', payload: 'Comment is required' })
      return
    }

    dispatch({ type: 'loading' })
    try {
      await addComment(state.commentValue)
      dispatch({ type: 'success' })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'error', payload: 'Something went wrong' })
    }
  }

  return (
    <Form onSubmit={handleOnSubmit}>
      {state.error && <ErrorMessage>{state.errorMessage}</ErrorMessage>}

      {inline ? (
        <InlineComment
          onChange={(value) => dispatch({ type: 'change', payload: value })}
          buttonText={buttonText}
          commentValue={state.commentValue}
          placeholder={placeholder}
          disabled={state.disabled}
          error={state.error}
          autoFocus={autoFocus}
        />
      ) : inlineV2 ? (
        <InlineV2Comment
          onChange={(value) => dispatch({ type: 'change', payload: value })}
          commentValue={state.commentValue}
          placeholder={placeholder}
          disabled={state.disabled}
          error={state.error}
          autoFocus={autoFocus}
        />
      ) : inlineV3 ? (
        <InlineV3Comment
          onChange={(value) => dispatch({ type: 'change', payload: value })}
          commentValue={state.commentValue}
          placeholder={placeholder}
          disableSend={state.disabled || state.loading}
          disableInput={disableInput}
          error={state.error}
          autoFocus={autoFocus}
          loading={state.loading}
        />
      ) : (
        <BlockComment
          onChange={(value) => dispatch({ type: 'change', payload: value })}
          buttonText={buttonText}
          commentValue={state.commentValue}
          placeholder={placeholder}
          disabled={state.disabled}
          error={state.error}
          autoFocus={autoFocus}
        />
      )}
    </Form>
  )
}

CommentInput.displayName = 'CommentInput'
export default CommentInput
