import React from 'react'

import { Flex, Icon, Text } from 'stardust'
import styled from 'styled-components'

import colors from '~/ui-components/tokens/colors'

interface HeaderProps {
  closeAssistModal: () => void
}

const CloseIcon = styled(Icon)`
  border-radius: 50%;
  padding: 10px;
  background-color: ${colors.cosmicShade4};
  &:hover {
    background-color: ${colors.cosmicShade6};
    transition: background-color 0.2s;
  }
`

const Header = ({ closeAssistModal }: HeaderProps) => (
  <Flex alignItems="center">
    <CloseIcon width={40} onClick={closeAssistModal} name="close" data-test="close_assist_modal" />
    <Text fontSize="20px" fontWeight={600} lineHeight="32px" ml={3} color={colors.cosmicShade10}>
      Learning Assistant
    </Text>
  </Flex>
)

Header.displayName = 'Header'
export default Header
